import { SVGProps } from 'react'

export const ShareIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#ECE8E1"
        d="M9 7h8v8l-1.547 1.547V9.698L8.15 17 7 15.85l7.302-7.303h-6.85L9 7Z"
      />
    </svg>
  )
}
