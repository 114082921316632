import { forwardRef } from 'react'

import {
  WithHideOnClickOutside,
  withHideOnClickOutside,
} from 'hoc/withHideOnClickOutside'
import { useWalletContext } from 'context/Wallet'
import { trimAddress } from 'utils/trimAddress'

import { Btn, ConnectWalletBtn } from 'components/Btn'

const ConnectWalletWidgetComponent = forwardRef<
  HTMLDivElement,
  WithHideOnClickOutside
>(({ isOpen, onToggleOpen }, ref) => {
  const { userAddress } = useWalletContext()

  return (
    <div className="relative flex" ref={ref}>
      <ConnectWalletBtn
        altBtn={
          <Btn
            size="small"
            type="quaternary"
            onPress={onToggleOpen}
            label={userAddress ? trimAddress(userAddress) : ''}
          />
        }
      />
    </div>
  )
})

ConnectWalletWidgetComponent.displayName = 'ConnectWalletWidget'

export const ConnectWalletWidget = withHideOnClickOutside(
  ConnectWalletWidgetComponent
)
