import { Toast, ToastAction } from './types'

export const reducer = (state: Toast[], action: ToastAction) => {
  switch (action.type) {
    case 'add_toast': {
      return [
        ...state,
        {
          createdAt: Date.now(),
          ...action.payload,
        },
      ]
    }
    case 'remove_toast': {
      const { id } = action.payload
      return state.filter((t) => t.id !== id)
    }
    default:
      return state
  }
}
