import { NftReward } from 'types/prizes'

export function areArraysOfNftRewardsIdentical(
  arr1: NftReward[],
  arr2: NftReward[]
): boolean {
  // Step 1: Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Helper function to compare NftReward objects
  const compareNftRewards = (a: NftReward, b: NftReward): number => {
    return a.id - b.id // Comparison based on 'id'
  }

  // Step 2: Sort both arrays
  const sortedArr1 = [...arr1].sort(compareNftRewards)
  const sortedArr2 = [...arr2].sort(compareNftRewards)

  // Step 3: Iterate and compare objects
  for (let i = 0; i < sortedArr1.length; i++) {
    const obj1 = sortedArr1[i]
    const obj2 = sortedArr2[i]

    // Comparing each property. Consider deep comparison for objects and arrays if needed.
    if (
      obj1.value !== obj2.value ||
      obj1.id !== obj2.id ||
      obj1.collectionAddress !== obj2.collectionAddress ||
      obj1.tokenId !== obj2.tokenId ||
      obj1.contractAddress !== obj2.contractAddress ||
      obj1.vaultAddress !== obj2.vaultAddress ||
      obj1.vaultNetwork !== obj2.vaultNetwork ||
      obj1.claimedTx !== obj2.claimedTx ||
      obj1.withdrawalTx !== obj2.withdrawalTx
    ) {
      return false // Found a difference
    }
  }

  return true // All checks passed
}
