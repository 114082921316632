import { FC } from 'react'
import { Btn } from './Btn'

type CallToActionProps = {
  title: string
  description: string
  callToActionText: string
  onCallToAction: () => void
}
export const CallToAction: FC<CallToActionProps> = ({
  title,
  description,
  callToActionText,
  onCallToAction,
}) => {
  return (
    <div className="flex flex-col items-center justify-center flex-1 flex-0 py-10 md:py-20 px-10 bg-gemBg bg-center bg-cover md:bg-auto bg-black border-[1px] border-white/20 relative">
      <div className="absolute w-full inset-0 bg-black/50 z-0" />
      <p className="uppercase text-[40px] md:text-[48px] text-white z-[1] leading-none text-center">
        {title}
      </p>
      <p className="my-6 font-mont text-white/80 text-[18px] z-[1] md:leading-none text-center">
        {description}
      </p>
      <div className="w-fit z-[1]">
        <Btn type="primary" label={callToActionText} onPress={onCallToAction} />
      </div>
    </div>
  )
}
