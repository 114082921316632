import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { NetworkSpeedContextScheme } from './types'

export const NetworkSpeedContext = createContext<
  NetworkSpeedContextScheme | undefined
>(undefined)

NetworkSpeedContext.displayName = 'NetworkSpeedContext'

export const useNetworkSpeedContext = createCustomContext(NetworkSpeedContext)
