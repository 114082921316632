import { FC } from 'react'

type SpinnerIconProps = {
  extendedClass: string
}
export const SpinnerIcon: FC<SpinnerIconProps> = ({ extendedClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`animate-spin ${extendedClass}`}
    viewBox="0 0 90 89"
    fill="none"
  >
    <path
      fill="#D9D9D9"
      d="M32.095 78.108A36 36 0 1 0 9.001 44.16l5.931.056a30.069 30.069 0 1 1 19.29 28.355l-2.127 5.537Z"
      opacity={0.2}
    />
    <path
      fill="#0092CD"
      d="M15.877 23.337a36 36 0 1 0 64.27 13.372L74.4 37.982a30.116 30.116 0 1 1-53.763-11.185l-4.76-3.46Z"
    />
  </svg>
)
