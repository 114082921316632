import { EmptyTicket, GoldenTicket } from 'assets/images'
import { FC } from 'react'

type TicketType = {
  isEmpty?: boolean
  size?: 'xs' | 'small' | 'big'
}

export const Ticket: FC<TicketType> = ({ isEmpty = true, size = 'small' }) => {
  return (
    <div className="relative">
      <img
        src={isEmpty ? EmptyTicket : GoldenTicket}
        alt={isEmpty ? 'Empty ticket' : 'Golden Ticket'}
        className={`${
          size === 'small'
            ? 'w-[75px] h-[75px]'
            : size === 'big'
            ? 'w-[150px] h-[150px]'
            : 'w-[47px] h-[47px]'
        } border-[0.5px] ${
          !isEmpty
            ? 'border-pacificBlue/60'
            : 'border-[0.25px] border-pampas/20'
        } `}
      />
      {!isEmpty ? (
        <p
          className={`absolute top-1 ${
            size === 'big' ? 'right-1.5' : 'right-1'
          } font-anton text-[7px] text-pacificBlue`}
        >
          ✓
        </p>
      ) : null}
    </div>
  )
}
