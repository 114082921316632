import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { ToastContextScheme } from './types'

export const ToastContext = createContext<ToastContextScheme | undefined>(
  undefined
)

ToastContext.displayName = 'ToastContext'

export const useToastContext = createCustomContext(ToastContext)
