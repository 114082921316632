import { FC, useCallback, useEffect } from 'react'

const count = 200,
  defaults = {
    origin: { y: 0.7 },
    shapes: 'square',
    colors: ['#0092CD'],
  }
function fire(
  particleRatio: number,
  opts: Partial<Record<'spread' | 'decay' | 'scalar' | 'startVelocity', number>>
) {
  // @ts-expect-error included into index.html
  confetti(
    Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
    })
  )
}

type ConfettiProps = {
  hideConfetti: () => void
}

export const Confetti: FC<ConfettiProps> = ({ hideConfetti }) => {
  const showConfetti = useCallback(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    })

    fire(0.2, {
      spread: 60,
    })

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  }, [])

  useEffect(() => {
    showConfetti()
    hideConfetti()
  }, [showConfetti, hideConfetti])

  return null
}
