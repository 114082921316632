import { EventLog, Log } from 'ethers'

// Define a type for our specific args structure
type PrizeClaimedEventArgs = [
  bigint,
  string,
  bigint,
  string,
  bigint,
  bigint,
  bigint
]

// Type guard function to check if the args match the MyContractEventArgs type
export function isPrizeClaimedEventArgs(
  args: any[]
): args is PrizeClaimedEventArgs {
  // Check if args has exactly 7 elements
  if (args.length !== 7) return false

  // Check the type of each element
  return (
    typeof args[0] === 'bigint' && // lootboxId
    typeof args[1] === 'string' && // user
    typeof args[2] === 'bigint' && // prizeType
    typeof args[3] === 'string' && // tokenContract
    typeof args[4] === 'bigint' && // tokenId
    typeof args[5] === 'bigint' && // amount
    typeof args[6] === 'bigint' // chainId
  )
}

type UnlockEventArgs = [bigint]

export function isUnlockEventArgs(args: any[]): args is UnlockEventArgs {
  // Check if there is exactly one element in args and it's of type 'bigint'
  return args.length === 1 && typeof args[0] === 'bigint'
}

// Define a type for the expected structure of args
type LootBoxRevealedEventArgs = [string, bigint, [bigint, bigint, bigint]]

// Type guard to check if args matches MySpecificEventArgs type
export function isLootBoxRevealedEventArgs(
  args: any[]
): args is LootBoxRevealedEventArgs {
  // Check if args has exactly 3 elements
  if (args.length !== 3) return false
  // Check if the first element is a string
  if (typeof args[0] !== 'string') return false
  // Check if the second element is a number
  if (typeof args[1] !== 'bigint') return false
  // Check if the third element is an array of numbers with exactly 3 elements
  if (!Array.isArray(args[2]) || args[2].length !== 3) return false
  if (args[2].some((element) => typeof element !== 'bigint')) return false

  return true
}

// Type guard function to check if an event is an EventLog
export function isEventLog(log: EventLog | Log): log is EventLog {
  return (log as EventLog).args !== undefined
}
