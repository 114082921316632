import { FC, useEffect, useState } from 'react'
import { timeAgo } from 'utils/timeAgo'

type TimeAgoProps = {
  createdAt: string
}

export const TimeAgo: FC<TimeAgoProps> = ({ createdAt }) => {
  const [timeAgoText, setTimeAgoText] = useState('')

  useEffect(() => {
    // Function to update the timeAgo text
    const updateTimeAgo = () => {
      setTimeAgoText(timeAgo(Date.parse(createdAt)))
    }

    // Update immediately on component mount
    updateTimeAgo()

    // Set an interval to update the timeAgo text periodically
    // For example, update every minute
    const intervalId = setInterval(updateTimeAgo, 60000)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [createdAt]) // Depend on timestamp so that interval resets if timestamp changes

  return <p className="font-mont text-[12px] text-white/70">{timeAgoText}</p>
}
