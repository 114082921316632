import { SVGProps } from 'react'

export const SoundOnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6282 3.49321C10.4413 3.39979 10.2321 3.36027 10.024 3.37908C9.81589 3.39789 9.61713 3.47429 9.45 3.59971L4.5 6.74971H2.25C1.95163 6.74971 1.66548 6.86824 1.4545 7.07922C1.24353 7.2902 1.125 7.57635 1.125 7.87471V10.1247C1.125 10.4231 1.24353 10.7092 1.4545 10.9202C1.66548 11.1312 1.95163 11.2497 2.25 11.2497H4.5L9.45 14.3997C9.61714 14.5251 9.81589 14.6014 10.024 14.6202C10.2321 14.6389 10.4412 14.5994 10.6281 14.5059C10.815 14.4125 10.9721 14.2689 11.082 14.0912C11.1918 13.9134 11.25 13.7086 11.25 13.4997V4.49971C11.2501 4.29076 11.1919 4.08591 11.0821 3.90814C10.9723 3.73036 10.8151 3.58669 10.6282 3.49321Z"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0973 11.8123C16.6047 11.017 16.8743 10.0932 16.8743 9.1498C16.8743 8.20639 16.6047 7.28262 16.0973 6.4873"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9215 10.8311C14.3009 10.3641 14.5079 9.78085 14.5079 9.17922C14.5079 8.57759 14.3009 7.9943 13.9215 7.52734"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 6.75V11.25"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
