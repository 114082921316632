import { SVGProps } from 'react'

export const Minus1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={4}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#ECE8E1"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={2.473}
        d="M1.833 2h13.334"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5.5h16v3H.5z" />
      </clipPath>
    </defs>
  </svg>
)
