export function convertToBigInt(value: string) {
  // Check if the value is in scientific notation
  if (/e\+/.test(value)) {
    let [base, exponent] = value.toLowerCase().split('e+').map(Number)
    let zerosToAdd = exponent - (base.toString().split('.')[1] || '').length
    return BigInt(base.toString().replace('.', '') + '0'.repeat(zerosToAdd))
  } else {
    // Directly convert to BigInt for standard numeric format
    return BigInt(value)
  }
}
