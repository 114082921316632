import { useCallback, useEffect, useState } from 'react'
import { EventLog } from 'ethers'

import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { LootboxEvents } from 'types/events'
import { isEventLog } from 'types/guard'

export const useRevealedEventBackup = () => {
  const [isWaitingForEvent, setIsWaitingForEvent] = useState(false)
  const [startBlock, setStartBlock] = useState<number | undefined>(undefined)
  const [pendingEvent, setPendingEvent] = useState<EventLog | null>(null)

  const { appLootboxContract } = useWalletContext()

  // Setting tokenId will spin up the whole process of polling for PrizeClaimed event
  const startWaitingForRevealedEvent = useCallback(() => {
    setIsWaitingForEvent(true)
  }, [])

  // Resetting tokenId will reset the whole process of polling for PrizeClaimed event
  const stopWaitingForRevealedEvent = useCallback(() => {
    setIsWaitingForEvent(false)
  }, [])

  // once isWaitingForEvent is true get the latest block;
  // LootBoxRevealed event lookup will be started from this block
  useEffect(() => {
    const getLatestBlock = async () => {
      const provider = PROJECTS[appLootboxContract].provider
      if (provider) {
        const lastBlock = await provider.getBlockNumber()
        setStartBlock(lastBlock)
      } else {
        // TODO: come up with solution if the povider is null
      }
    }

    if (isWaitingForEvent) {
      getLatestBlock()
    } else {
      setStartBlock(undefined)
    }
  }, [isWaitingForEvent, appLootboxContract])

  // getMissedEvents is called every 5 sec to get LootBoxRevealed for currently connected user,
  // and known tokenId (received after burnForRandomPrize fn call)
  const getMissedEvents = useCallback(async () => {
    if (startBlock) {
      try {
        const logs = await PROJECTS[appLootboxContract].queryRevealedEvent(
          LootboxEvents.revealed,
          startBlock,
          startBlock + 1024
        )
        return logs
      } catch (e) {
        // TODO: what to show to the user
        console.error('Error getting missed events', e)
      }
    }
  }, [appLootboxContract, startBlock])

  // Once a user clamied a gem, tokenId is known, at this moment
  // starting polling PrizeClaimed event
  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined = undefined
    if (isWaitingForEvent) {
      intervalId = setInterval(() => {
        getMissedEvents().then((events) => {
          if (events) {
            console.log('missed events', events)
            if (events.length && isEventLog(events[0])) {
              setPendingEvent(events[0])
            }
          }
        })
      }, 5000)
    }
    return () => clearInterval(intervalId)
  }, [isWaitingForEvent, getMissedEvents])

  useEffect(() => {
    if (!isWaitingForEvent) {
      setStartBlock(undefined)
      setPendingEvent(null)
    }
  }, [isWaitingForEvent])

  return {
    startWaitingForRevealedEvent, // will be called once tokenId is known, it will spinned up: getting Latest block, and start polling for RevealedEvent once startBlock is known
    stopWaitingForRevealedEvent, // this will reset the above
    pendingEvent, // this is PrizeClaimed event from logs
  }
}
