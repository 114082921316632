import { useEffect } from 'react'

import { useWalletContext } from 'context/Wallet'
import { useDataContext } from 'context/Data'
import { CallToAction } from 'components/CallToAction'
import { HistoryElement } from 'components/HistoryElement'
import { StorageKeys } from 'types/storage'
import { ReloadDataBtn } from 'components/ReloadDataBtn'
import { useNavigate } from 'react-router-dom'
import { LootboxRoutes } from 'types/routes'
import { SpinnerIcon } from 'assets/icons'

export const History = () => {
  const { history, historyError, reloadHistory, isLoadingHistory } =
    useDataContext()
  const { userAddress, handleConnectWallet } = useWalletContext()
  const navigate = useNavigate()

  useEffect(() => {
    let id: NodeJS.Timer | undefined = undefined
    id = setTimeout(() => {
      localStorage.setItem(
        StorageKeys.historyLastViewed,
        JSON.stringify({
          userAddress,
          timestamp: Date.now().toString(),
        })
      )
    }, 5000)
    return () => clearTimeout(id)
  }, [userAddress])

  return (
    <div className="">
      <div className="flex items-center mb-4">
        <p className="uppercase text-white text-[40px] md:text-[48px]">
          History
        </p>
        <ReloadDataBtn reloadData={reloadHistory} />
      </div>
      {!userAddress ? (
        <div className="w-full">
          <CallToAction
            title="Wallet isn't connected"
            description="To see your gems connect the wallet"
            callToActionText="connect"
            onCallToAction={handleConnectWallet}
          />
        </div>
      ) : isLoadingHistory ? (
        <div className="w-full flex justify-center">
          <SpinnerIcon extendedClass="w-6 h-6" />
        </div>
      ) : historyError ? (
        <div className="w-full">
          <CallToAction
            title="Unable to Load Your History"
            description="Your history couldn't be loaded this time around. Let's try hitting the refresh button and get back on course."
            callToActionText="Reload"
            onCallToAction={reloadHistory}
          />
        </div>
      ) : history && !history.length ? (
        <CallToAction
          title="No history yet"
          description="Seems like you haven't claimed any gems yet. Buy and open lootboxes to collect and claim gems"
          callToActionText="go to box"
          onCallToAction={() => navigate(`/${LootboxRoutes.box}`)}
        />
      ) : history && history.length ? (
        <div className="border-[0.5px] border-pampas/20">
          {history.map((historyItem, index) => (
            <HistoryElement {...historyItem} key={index} />
          ))}
        </div>
      ) : null}
    </div>
  )
}
