import { useMemo, useState } from 'react'

import { Minus1Icon, Plus1Icon, SpinnerIcon } from 'assets/icons'
import { useWalletContext } from 'context/Wallet'
import { useDataContext, FreeTicketInfo } from 'context/Data'

import { useOverlayContext } from 'context/Overlay'
import { ToastType, useToastContext } from 'context/Toast'
import {
  isErrorDescription,
  isProviderRpcError,
  LootboxError,
} from 'types/errors'
import { PROJECTS } from 'utils/config/projects'
import { preciseMultiply } from 'utils/preciseMultiply'

const LOOTBOX_COUNT = 1
const BOOST = 1

export const LootboxWidget = () => {
  const [lootboxCount, setLootboxCount] = useState(LOOTBOX_COUNT)

  const {
    lootboxData,
    payingTokenSym,
    isLoadingLootboxData,
    lootboxDataError,
    reloadLootboxData,
    freeTickets,
    reloadUserAccount,
    startWaitingForRevealedEvent,
    payingTokenBalance,
    reloadPayingTokenBalance,
  } = useDataContext()

  const {
    userAddress,
    activeNetworkId,
    appLootboxContract,
    handleConnectWallet,
  } = useWalletContext()
  const { handleLoadingProcessWithPause } = useOverlayContext()
  const { addToast } = useToastContext()

  const handleUseFreeTicket = async (
    v: FreeTicketInfo['signature']['v'],
    r: FreeTicketInfo['signature']['r'],
    s: FreeTicketInfo['signature']['s'],
    externalId: FreeTicketInfo['ticketId'],
    expired: number
  ) => {
    try {
      await handleLoadingProcessWithPause(
        async () => {
          await PROJECTS[appLootboxContract].handleUseFreeTicket(
            parseInt(externalId),
            expired,
            [v, r, s]
          )
        },
        'Free ticket in action',
        "Using your ticket...Let's see where this free ride takes you",
        'Transaction submitted to blockchain. Please wait for processing'
      )
      startWaitingForRevealedEvent()
      // update account to remove used ticket
      await reloadUserAccount()
    } catch (e) {
      if (e && isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
        // ignore
      } else {
        console.error('Error in handleUseFreeTicket', e)
        if (
          isErrorDescription(e) &&
          (e.name === LootboxError.AlreadyUsed ||
            e.name === LootboxError.ExternalIdAlreadyUsed)
        ) {
          addToast(ToastType.failure, 'The ticket has been used already')
        } else {
          addToast(ToastType.failure, 'Error using a free ticket')
        }
      }
    }
  }

  const increaseTicketAmount = () => {
    setLootboxCount((prevState) => {
      if (prevState < 20) {
        return prevState + 1
      } else {
        return 20
      }
    })
  }

  const decreaseTicketAmount = () => {
    setLootboxCount((prevState) => {
      if (prevState === 1) {
        return 1
      } else {
        return prevState - 1
      }
    })
  }

  const buyLootbox = async () => {
    try {
      await handleLoadingProcessWithPause(
        async () => {
          if (lootboxData.payingToken && lootboxData.payingAmount) {
            await PROJECTS[appLootboxContract].handleBuyLootbox(
              lootboxData.payingToken,
              lootboxData.payingAmount,
              lootboxCount,
              BOOST
            )
          }
        },
        'Preparing Your Lucky Lootbox!',
        'Hold on, your adventure to uncover hidden treasures is about to start!',
        'Transaction submitted to blockchain. Please wait for processing'
      )
      startWaitingForRevealedEvent()

      await reloadPayingTokenBalance()
    } catch (e) {
      // TODO: extract the number code
      if (e && isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
        // ignore
      } else {
        console.error('Error in buyLootbox', e)
        addToast(ToastType.failure, 'Error buying a lootbox')
      }
    }
  }

  const finalSum = useMemo(() => {
    return lootboxData.payingAmount
      ? preciseMultiply(Number(lootboxData.payingAmount), BOOST * lootboxCount)
      : 0
  }, [lootboxData.payingAmount, lootboxCount])

  const balanceError = useMemo(() => {
    if (
      lootboxData.payingToken &&
      PROJECTS[appLootboxContract].chainId.toLowerCase() ===
        activeNetworkId?.toLowerCase()
    ) {
      if (Number(payingTokenBalance) < finalSum) {
        return 'Not enough balance'
      }
    } else return ''
  }, [
    lootboxData.payingToken,
    payingTokenBalance,
    finalSum,
    activeNetworkId,
    appLootboxContract,
  ])

  return (
    <div className="w-full">
      {balanceError ? (
        <>
          <div className="border-[1px] border-yourPink bg-pippin w-full h-[37px] flex items-center justify-center">
            <p className="font-mont text-[14px] text-guardsmanRed">
              {balanceError}
            </p>
          </div>
          <div className="h-4" />
        </>
      ) : null}
      <div className={`h-20 bg-pacificBlue flex items-center p-3`}>
        <div
          className={`flex items-center w-36 h-12 border border-pampas/50 ${
            !userAddress ? 'opacity-40 pointer-events-none' : ''
          }`}
        >
          <div className="flex justify-center w-1/3">
            <button type="button" onClick={decreaseTicketAmount}>
              <Minus1Icon />
            </button>
          </div>
          <div className="flex justify-center w-1/3 border-x border-x-pampas/50">
            <p className="font-anton uppercase text-[20px] md:text-[24px] text-pampas">
              {lootboxCount}
            </p>
          </div>
          <div className="flex justify-center w-1/3">
            <button type="button" onClick={increaseTicketAmount}>
              <Plus1Icon />
            </button>
          </div>
        </div>
        <button
          type="submit"
          onClick={
            lootboxDataError
              ? reloadLootboxData
              : !userAddress
              ? handleConnectWallet
              : buyLootbox
          }
          className="flex-1 flex justify-center disabled:opacity-40"
          disabled={Boolean(balanceError) || isLoadingLootboxData}
        >
          {lootboxDataError ? (
            <p className="font-anton uppercase text-pampas text-[20px] md:text-[24px] flex items-center">
              Error - reload
            </p>
          ) : !userAddress ? (
            <p className="font-anton uppercase text-pampas text-[20px] md:text-[24px] flex items-center">
              Connect wallet
            </p>
          ) : (
            <p className="font-anton uppercase text-pampas text-[20px] md:text-[24px] flex items-center">
              Buy for{' '}
              {isLoadingLootboxData ? (
                <SpinnerIcon extendedClass="w-6 h-6" />
              ) : (
                finalSum
              )}{' '}
              {payingTokenSym}
            </p>
          )}
        </button>
      </div>
      {freeTickets.length ? (
        <div className="flex text-pampas uppercase justify-center mt-5">
          <button
            className="underline underline-offset-4 uppercase"
            onClick={() =>
              handleUseFreeTicket(
                freeTickets[0].signature.v,
                freeTickets[0].signature.r,
                freeTickets[0].signature.s,
                freeTickets[0].ticketId,
                Math.ceil(Date.parse(freeTickets[0].expiredAt) / 1000)
              )
            }
          >
            use free ticket
          </button>
        </div>
      ) : null}
    </div>
  )
}
