import React, { useEffect, useState, useMemo, FC } from 'react'
import axios from 'axios'

import { Btn } from 'components/Btn'
import { FiltersSection } from 'components/FiltersSection'
import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { WITHDRAW_CONTRACTS } from 'utils/config/withdraw'
import { AgaTokensWidget } from 'components/AgaTokensWidget'
import { NftPrize } from 'components/NftPrize'
import { TokenPrize } from 'components/TokenPrize'
import { generateId } from 'utils/generateId'
import { CallToAction } from 'components/CallToAction'
import { LootboxRoutes } from 'types/routes'
import { useNavigate } from 'react-router-dom'
import {
  CloseIcon,
  EmptySelectIcon,
  SelectIcon,
  SpinnerIcon,
} from 'assets/icons'
import { useOverlayContext } from 'context/Overlay'
import {
  isErrorDescription,
  isProviderRpcError,
  LootboxError,
} from 'types/errors'
import { ToastType, useToastContext } from 'context/Toast'
import { useDrawerContext } from 'context/Drawer'
import { convertToBigInt } from 'utils/convertToBigInt'
import { usePrizesFlowContext } from 'context/PrizesFlow'
import { useDataContext } from 'context/Data'
import { apiUrl } from 'utils/api'
import { Erc20Reward, NftReward, PrizeStatus } from 'types/prizes'
import { areArraysOfNftRewardsIdentical } from 'utils/areArrayOfNftPrizesIdentical'
import { areErc20RewardsIdentical } from 'utils/areErc20RewardsIdentical'
import { ReloadDataBtn } from 'components/ReloadDataBtn'

type Erc20RewardWithId = Erc20Reward & {
  id: string
}

type WithdrawConfig = {
  accountProfile: {
    prizes: {
      nftRewards: Array<NftReward>
      erc20Rewards: Array<Erc20Reward>
    }
    lootBox: {
      lootBoxAddress: string
      lootBoxNetwork: number
      accountAddress: string
    }
  }
  vault: {
    network: number
    address: string
  }
  lootBoxIds: Array<number>
  signature: {
    vrs: string
    v: string
    s: string
    r: string
    signer: string
  }
}

type HandleSelectForWithdraw = {
  (type: 'erc20Rewards', prize: Erc20Reward): void
  (type: 'nftRewards', prize: NftReward): void
}

const CHAIN_NAMES: Record<string, string> = {
  '421614': 'Sepolia Arbitrum',
  '1': 'Ethereum',
  '42161': 'Arbitrum',
  '11155111': 'Sepolia',
}
const LOOT_TYPE_FILTER_OPTIONS = ['all', 'nft', 'tokens']

const filterCommited = (asset: NftReward | Erc20Reward) => {
  return asset.status === PrizeStatus.committed.toUpperCase()
}

type PrizeBtnProps = {
  status: PrizeStatus
  isSelected: boolean
}

const PrizeBtn: FC<PrizeBtnProps> = ({ status, isSelected }) => {
  return (
    <div className="flex items-center justify-center">
      {status !== PrizeStatus.committed && status !== undefined ? (
        <p className="uppercase text-pampas text-[16px]">not available yet</p>
      ) : isSelected ? (
        <>
          <SelectIcon />
          <div className="w-2" />
          <p className="uppercase text-pampas text-[16px]">selected</p>
        </>
      ) : (
        <>
          <EmptySelectIcon />
          <div className="w-2" />
          <p className="uppercase text-pampas text-[16px]">select</p>
        </>
      )}
    </div>
  )
}

export const Vault = () => {
  const [isConfirmation, setConfirmation] = useState(false)
  const [selectedChainFilter, setSelectedChainFilter] = useState<
    string | undefined
  >(undefined)
  const [selectedErc20, setSelectedErc20] = useState<Array<Erc20RewardWithId>>(
    []
  )
  const [selectedNft, setSelectedNft] = useState<Array<NftReward>>([])

  const { appLootboxContract, userAddress, handleConnectWallet } =
    useWalletContext()

  const [lootTypeFilter, setLootTypeFilter] = useState(
    LOOT_TYPE_FILTER_OPTIONS[0]
  )

  const navigate = useNavigate()
  const { handleLoadingProcess, showConfetti } = useOverlayContext()
  const { addToast } = useToastContext()
  const { showDrawer, hideDrawer } = useDrawerContext()
  const { removeNewBadge } = usePrizesFlowContext()
  const {
    prizesInfo,
    reloadUserAccount,
    userAccountError,
    isLoadingUserAccount,
    reloadHistory,
  } = useDataContext()

  const erc20Rewards = useMemo(() => {
    if (prizesInfo) {
      return prizesInfo.erc20Rewards.map((p) => ({ ...p, id: generateId() }))
    } else return []
  }, [prizesInfo])

  const nftRewards = useMemo(() => {
    if (prizesInfo) {
      return prizesInfo.nftRewards
    } else return []
  }, [prizesInfo])

  useEffect(() => {
    removeNewBadge(4)
  }, [removeNewBadge])

  useEffect(() => {
    setSelectedNft([])
    setSelectedErc20([])
  }, [lootTypeFilter, selectedChainFilter])

  const chainFilters = useMemo(() => {
    const uniqueVaultNetworks = [
      ...new Set([
        ...erc20Rewards.map((reward) => reward.vaultNetwork),
        ...nftRewards.map((reward) => reward.vaultNetwork),
      ]),
    ].map((v) => v.toString())
    return uniqueVaultNetworks
  }, [erc20Rewards, nftRewards])

  const chainFilterNames = useMemo(() => {
    return chainFilters.map((f) => CHAIN_NAMES[f])
  }, [chainFilters])

  useEffect(() => {
    setSelectedChainFilter(chainFilters[0])
  }, [chainFilters])

  useEffect(() => {
    setLootTypeFilter('all')
  }, [selectedChainFilter])

  const handleSelectAll = async () => {
    const tokens = erc20Rewards.filter(filterCommited)
    const nfts = nftRewards.filter(filterCommited)
    if (lootTypeFilter === 'all') {
      setSelectedErc20(tokens)
      setSelectedNft(nfts)
    } else if (lootTypeFilter === 'nft') {
      setSelectedNft(nfts)
      setSelectedErc20([])
    } else {
      // lootTypeFilter === 'tokens'
      setSelectedErc20(erc20Rewards)
      setSelectedNft([])
    }
  }

  const handleWithdraw = async () => {
    try {
      await handleLoadingProcess(
        async () => {
          const allSelected = [...selectedNft, ...selectedErc20]
          if (prizesInfo === null) {
            return
          }

          const { lootBoxAddress, lootBoxNetwork, accountAddress } = prizesInfo

          const chainId = allSelected.length && allSelected[0].vaultNetwork
          const reponse = await axios.post<WithdrawConfig>(
            `${apiUrl}/withdraw?chainId=${chainId}`,
            {
              lootBox: {
                lootBoxAddress,
                lootBoxNetwork,
                accountAddress,
              },
              prizes: {
                erc20Rewards: selectedErc20.map((sp) => {
                  const { id, ...rest } = sp
                  return rest
                }),
                nftRewards: selectedNft,
              },
            }
          )

          const withdrawData = reponse.data

          if (
            !areArraysOfNftRewardsIdentical(
              selectedNft,
              withdrawData.accountProfile.prizes.nftRewards
            ) ||
            !areErc20RewardsIdentical(
              selectedErc20,
              withdrawData.accountProfile.prizes.erc20Rewards
            )
          ) {
            console.error(
              "error to withdraw prizes, some prizes aren't available",
              userAddress
            )
            addToast(
              ToastType.failure,
              'Apologies, but prizes cannot be withdrawn at this time.'
            )
            return
          } else {
            const network =
              WITHDRAW_CONTRACTS[withdrawData.vault.network.toString()][
                PROJECTS[appLootboxContract].lootboxContractAddress
              ]
            if (!network) {
              console.error(
                `No withdraw contract for: ${withdrawData.vault.network.toString()}, lootbox address: ${
                  PROJECTS[appLootboxContract].lootboxContractAddress
                }`
              )
              addToast(
                ToastType.failure,
                "We couldn't find withdraw contract instance, a message has been sent to the dev team"
              )
            }

            await network.switchToNetwork()
            await network.release(
              userAddress,
              withdrawData.lootBoxIds,
              withdrawData.accountProfile.prizes.nftRewards.map((r) => [
                r.collectionAddress,
                r.tokenId,
              ]),
              withdrawData.accountProfile.prizes.erc20Rewards.map((r) => [
                r.tokenAddress,
                convertToBigInt('' + r.amount),
              ]),
              [
                [
                  withdrawData.signature.v,
                  withdrawData.signature.r,
                  withdrawData.signature.s,
                ],
              ]
            )

            setConfirmation(true)
            // update list of prizes
            await reloadUserAccount()
            await reloadHistory()
            showConfetti()
          }
        },
        'Cross-chain transfer',
        'Initiating withdrawal...Your prizes are moving to your wallet',
        'Transaction submitted to blockchain. Please wait for processing'
      )
    } catch (e) {
      if (isProviderRpcError(e) && e.code === 4001) {
        // ignore
      } else {
        console.error('Error occured in handleWithdraw', e)
        if (isErrorDescription(e) && e.name === LootboxError.AlreadyUsed) {
          addToast(
            ToastType.failure,
            'Some of the prizes have been withdrawn already'
          )
        } else {
          addToast(ToastType.failure, 'Error withdrawing prizes')
        }
      }
    } finally {
      setSelectedNft([])
      setSelectedErc20([])
    }
  }

  const handleSelectForWithdraw: HandleSelectForWithdraw = (
    type: 'erc20Rewards' | 'nftRewards',
    prize: any
  ) => {
    if (type === 'erc20Rewards') {
      setSelectedErc20((prevState) => {
        if (prevState.find((p) => p.id === prize.id)) {
          // remove
          return prevState.filter((p) => p.id !== prize.id)
        } else {
          //add
          return [...prevState, prize]
        }
      })
    } else {
      setSelectedNft((prevState) => {
        if (prevState.find((p) => p.id === prize.id)) {
          // remove
          return prevState.filter((p) => p.id !== prize.id)
        } else {
          //add
          return [...prevState, prize]
        }
      })
    }
  }

  const handleNavigateToBox = () => {
    navigate(`/${LootboxRoutes.box}`)
    setConfirmation(false)
  }

  const handleWithdrawAgain = () => {
    setConfirmation(false)
  }

  const handleOpenFilters = () => {
    showDrawer(
      <div className="flex flex-col w-full h-full bg-black p-5">
        <div className="flex justify-between">
          <p className="uppercase text-[24px] text-pampas">Filters</p>
          <button className="-translate-y-1" type="button" onClick={hideDrawer}>
            <CloseIcon />
          </button>
        </div>
        <div className="">
          <FiltersSection
            title="chain"
            options={chainFilters}
            optionNames={chainFilterNames}
            selected={selectedChainFilter || ''}
            onSelect={(o) => {}}
          />
          <div className="h-[1px] w-full bg-white/30" />
          <FiltersSection
            title="loot type"
            options={LOOT_TYPE_FILTER_OPTIONS}
            optionNames={LOOT_TYPE_FILTER_OPTIONS}
            selected={lootTypeFilter}
            onSelect={(o) => setLootTypeFilter(o)}
          />
        </div>
        <div className="fixed bottom-5 right-5 z-[100] bg-black">
          <Btn type="primary" label="apply" onPress={hideDrawer} size="small" />
        </div>
      </div>,
      {
        direction: 'right',
        className: 'navigation-md:hidden w-full',
        size: 'w-full',
      }
    )
  }

  return (
    <div className="flex flex-col md:flex-row">
      {/* Adjusted for screen layout */}
      <aside className="text-pampas flex flex-col md:mr-16">
        <div className="flex md:-translate-y-4 mb-4 items-center">
          <p className="text-[40px] md:text-[48px] uppercase text-white">
            Vault
          </p>
          <ReloadDataBtn reloadData={reloadUserAccount} />
        </div>
        <AgaTokensWidget />
        <div className="hidden md:flex flex-col">
          <FiltersSection
            title="chain"
            options={chainFilters}
            optionNames={chainFilterNames}
            selected={selectedChainFilter || ''}
            onSelect={(o) => setSelectedChainFilter(o)}
          />
          <div className="h-[1px] w-full bg-white/30" />
          <FiltersSection
            title="loot type"
            options={LOOT_TYPE_FILTER_OPTIONS}
            optionNames={LOOT_TYPE_FILTER_OPTIONS}
            selected={lootTypeFilter}
            onSelect={(o) => setLootTypeFilter(o)}
          />
        </div>
        {erc20Rewards.length || nftRewards.length ? (
          <div className="md:hidden my-6">
            <Btn
              onPress={handleOpenFilters}
              label="filters"
              type="primary"
              size="small"
            />
          </div>
        ) : (
          <div className="h-6" />
        )}
      </aside>
      {isConfirmation ? (
        <div className="flex flex-col justify-center items-center w-full">
          <p className="uppercase text-[40px] md:text-[72px] text-white text-center leading-none">
            Thanks
          </p>
          <div className="h-5" />
          <p className="font-mont text-[18px] text-white/80 text-center">
            Your prizes have been added to your wallet
          </p>
          <div className="h-6 md:h-16" />
          <div className="flex-0">
            <Btn
              label="back to box"
              type="primary"
              onPress={handleNavigateToBox}
            />
            <div className="h-6" />
            {erc20Rewards.length || nftRewards.length ? (
              <Btn
                label="withdraw"
                type="primary"
                onPress={handleWithdrawAgain}
              />
            ) : null}
          </div>
        </div>
      ) : !userAddress ? (
        <div className="w-full">
          <CallToAction
            title="Wallet isn't connected"
            description="To see prizes to withdraw connect the wallet"
            callToActionText="connect"
            onCallToAction={handleConnectWallet}
          />
        </div>
      ) : userAccountError ? (
        <div className="w-full">
          <CallToAction
            title="Error loading vault"
            description="Your vault couldn't be loaded this time around. Let's try hitting the refresh button and get back on course."
            callToActionText="reload"
            onCallToAction={reloadUserAccount}
          />
        </div>
      ) : isLoadingUserAccount ? (
        <div className="w-full flex justify-center">
          <SpinnerIcon extendedClass="w-6 h-6" />
        </div>
      ) : !erc20Rewards.length && !nftRewards.length ? (
        <div className="w-full">
          <CallToAction
            title="Nothing here for the moment"
            description="Buy and open lootbox to be able to claim gems and get some loot to your vault"
            callToActionText="go to box"
            onCallToAction={() => navigate(`/${LootboxRoutes.box}`)}
          />
        </div>
      ) : (
        <div className="">
          {/* Main content */}
          {/* Token info section */}
          <section className="p-4 md:p-10 shadow-md flex items-end justify-between border border-[1px] border-white/30 bg-box-buy bg-cover bg-no-repeat bg-bottom">
            <div>
              <p className="uppercase text-[24px] text-white">
                want to pay less fees ?
              </p>
              <div className="h-4" />
              <p className="font-mont text-pampas">
                Vault page allows you to select multiple prizes at once and
                withdraw them in a single transaction. By doing this, you
                significantly reduce the transaction fees that would accrue if
                each prize were withdrawn individually
              </p>
            </div>
          </section>
          <div className="fixed md:static bottom-0 left-0 right-0 w-full md:w-auto flex text-white justify-between p-4 md:p-0 md:my-6 items-center z-40 bg-black">
            <p className="text-[24px] uppercase hidden md:inline">
              withdraw loot
            </p>
            <div className="flex justify-between w-full md:w-auto">
              <Btn
                label="select all"
                type="secondary"
                onPress={handleSelectAll}
                size="small"
              />
              <div className="w-8" />
              <Btn
                label="withdraw"
                type="primary"
                onPress={handleWithdraw}
                size="small"
                disabled={!selectedNft.length && !selectedErc20.length}
              />
            </div>
          </div>
          <section className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6 md:mt-0 mb-24">
            {(lootTypeFilter === 'all' || lootTypeFilter === 'nft') &&
              nftRewards.map((p) => (
                <React.Fragment key={p.id}>
                  {p.vaultNetwork.toString() === selectedChainFilter ? (
                    <NftPrize
                      contractAddress={p.contractAddress}
                      tokenId={p.tokenId}
                      chainId={p.vaultNetwork}
                      onClick={
                        p.status === PrizeStatus.committed ||
                        p.status === undefined
                          ? () => handleSelectForWithdraw('nftRewards', p)
                          : () => {}
                      }
                      mainBtnType="secondary"
                      mainBtnComponent={() => (
                        <PrizeBtn
                          status={p.status}
                          isSelected={Boolean(
                            selectedNft.find((sp) => sp.id === p.id)?.id
                          )}
                        />
                      )}
                    />
                  ) : null}
                </React.Fragment>
              ))}
            {(lootTypeFilter === 'all' || lootTypeFilter === 'tokens') &&
              erc20Rewards.map((p, idx) => (
                <React.Fragment key={p.contractAddress + idx}>
                  {p.vaultNetwork.toString() === selectedChainFilter ? (
                    <TokenPrize
                      contractAddress={p.contractAddress}
                      amount={p.amount}
                      chainId={p.vaultNetwork}
                      onClick={
                        p.status === PrizeStatus.committed ||
                        p.status === undefined
                          ? () => handleSelectForWithdraw('erc20Rewards', p)
                          : () => {}
                      }
                      mainBtnType="secondary"
                      mainBtnComponent={() => (
                        <PrizeBtn
                          status={p.status}
                          isSelected={Boolean(
                            selectedErc20.find((sp) => sp.id === p.id)?.id
                          )}
                        />
                      )}
                    />
                  ) : null}
                </React.Fragment>
              ))}
          </section>
        </div>
      )}
    </div>
  )
}
