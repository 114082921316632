import { FC, useCallback, useMemo, useReducer } from 'react'
import { ChildrenProp } from 'types/common'
import { generateId } from 'utils/generateId'
import { ToastContext } from './context'
import { reducer } from './reducer'
import { Toast } from './Toast'
import { ToastType } from './types'

export const ToastProvider: FC<ChildrenProp> = ({ children }) => {
  const [toasts, dispatch] = useReducer(reducer, [])

  const addToast = useCallback((type: ToastType, message: string) => {
    const id = generateId()
    dispatch({
      type: 'add_toast',
      payload: {
        id,
        type,
        message,
      },
    })
  }, [])

  const removeToast = (id: string) => {
    dispatch({
      type: 'remove_toast',
      payload: {
        id,
      },
    })
  }

  const context = useMemo(
    () => ({
      addToast,
    }),
    [addToast]
  )

  return (
    <ToastContext.Provider value={context}>
      <div className="fixed top-14 right-5 z-[100]">
        <div className="flex flex-col-reverse max-w-[300px]">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              id={toast.id}
              type={toast.type}
              message={toast.message}
              remove={removeToast}
            />
          ))}
        </div>
      </div>

      {children}
    </ToastContext.Provider>
  )
}
