import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { toNumber, formatUnits, formatEther } from 'ethers'
import axios from 'axios'

import { PROJECTS } from 'utils/config/projects'
import { useWalletContext } from 'context/Wallet'
import { DataContext } from './context'
import { ChildrenProp } from 'types/common'

import { GemsInfo, HistoryItem, LootboxStatus, UserProfile } from './types'
import { isBuyPeriod } from 'utils/isBuyPeriod'
import { apiUrl } from 'utils/api'
import { useDataLoader } from 'hooks/useDataLoader'
import { getErc20Metadata } from 'utils/getMetadata'
import { useRevealedEventBackup } from 'hooks/useRevealedEventBackup'
import { convertToBigInt } from 'utils/convertToBigInt'

export const DataProvider: FC<ChildrenProp> = ({ children }) => {
  const [canClaim, setUserCanClaim] = useState(true)
  const [prizesInfo, setPrizesInfo] = useState<
    (UserProfile['lootBox'] & UserProfile['prizes']) | null
  >(null)
  const [freeTickets, setFreeTickets] = useState<UserProfile['freeTickets']>([])
  const [payingTokenSym, setPayingTokenSym] = useState<string | undefined>(
    undefined
  )

  const { appLootboxContract, userAddress } = useWalletContext()
  const {
    startWaitingForRevealedEvent,
    stopWaitingForRevealedEvent,
    pendingEvent,
  } = useRevealedEventBackup()

  const loadHistory = useCallback(async () => {
    const response = await axios<HistoryItem[]>(
      `${apiUrl}/account-history/${userAddress}?lootBoxAddress=${PROJECTS[appLootboxContract].lootboxContractAddress}&chainId=${PROJECTS[appLootboxContract].chainId}`
    )
    return response.data
  }, [userAddress, appLootboxContract])

  const {
    data: history,
    isLoading: isLoadingHistory,
    error: historyError,
    reload: reloadHistory,
  } = useDataLoader([], loadHistory, 'Failed to load history')

  const loadGems = useCallback(async () => {
    const gems: GemsInfo = await PROJECTS[appLootboxContract].handleGetGems()
    return [
      toNumber(gems[4][0]),
      toNumber(gems[4][1]),
      toNumber(gems[4][2]),
      toNumber(gems[1]),
    ] as [
      legendaryGem: number,
      rareGem: number,
      magicGem: number,
      emptyGem: number
    ]
  }, [appLootboxContract])

  const {
    data: gems,
    isLoading: isLoadingGems,
    error: gemsError,
    reload: reloadGems,
    setData: setGems,
  } = useDataLoader<
    [legendaryGem: number, rareGem: number, magicGem: number, emptyGem: number]
  >([0, 0, 0, 0], loadGems, 'Failed to load gems')

  const loadUserAccount = useCallback(async () => {
    const response = await axios<UserProfile>(
      `${apiUrl}/accounts/${userAddress}?lootBoxAddress=${PROJECTS[appLootboxContract].lootboxContractAddress}&chainId=${PROJECTS[appLootboxContract].chainId}`
    )
    return response.data
  }, [appLootboxContract, userAddress])

  const {
    data: userAccount,
    isLoading: isLoadingUserAccount,
    error: userAccountError,
    reload: reloadUserAccount,
  } = useDataLoader<UserProfile | null>(
    null,
    loadUserAccount,
    'Failed to load user account'
  )

  useEffect(() => {
    if (userAccount) {
      const { lootBox, prizes, freeTickets } = userAccount
      setPrizesInfo({ ...prizes, ...lootBox })
      setFreeTickets(freeTickets.filter((t) => !t.used))
    }
  }, [userAccount])

  const loadAgaTokenBalance = useCallback(async () => {
    if (process.env.NODE_ENV === 'production') {
      let sum = 0
      for (const url of ['/api/get-arbitrum-aga', '/api/get-polygon-aga']) {
        const response = await axios.get<string>(url, {
          params: {
            userAddress,
          },
        })
        sum = sum + Number(formatEther(convertToBigInt('' + response.data)))
      }
      return sum.toString()
    } else {
      const response = await axios.get(
        `https://api-sepolia.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=${process.env.REACT_APP_AGA_ARBITRUM_CONTRACT_DEV}&address=${userAddress}&tag=latest&apikey=${process.env.REACT_APP_ARBISCAN_AUTH_KEY}`
      )
      return formatEther(response.data.result)
    }
  }, [userAddress])

  const {
    data: agaTokenBalance,
    isLoading: isLoadingAgaTokenBalance,
    error: agaTokenBalanceError,
    reload: reloadAgaTokenBalance,
    setData: setAgaTokenBalance,
  } = useDataLoader(
    '0',
    loadAgaTokenBalance,
    'Failed to load aga token balance'
  )

  const loadLootboxData = useCallback(async () => {
    const data = await PROJECTS[appLootboxContract].handleGetLootboxData()
    if (data.payingToken !== undefined) {
      const meta = getErc20Metadata(
        data.payingToken,
        parseInt(PROJECTS[appLootboxContract].chainId)
      )
      setPayingTokenSym(meta?.sym)
    }
    return data
  }, [appLootboxContract])

  const {
    data: lootboxData,
    isLoading: isLoadingLootboxData,
    error: lootboxDataError,
    reload: reloadLootboxData,
    setData: setLootboxData,
  } = useDataLoader(
    {
      payingToken: undefined,
      payingAmount: undefined,
      jackpotAmount: undefined,
    },
    loadLootboxData,
    'Error loading lootbox data'
  )

  const loadPayingTokenBalance = useCallback(async () => {
    const balance = await PROJECTS[appLootboxContract].handleGetBalance(
      PROJECTS[appLootboxContract].payingTokenAddress
    )
    return balance
  }, [appLootboxContract])

  const {
    data: payingTokenBalance,
    isLoading: isLoadingPayingTokenBalance,
    error: payingTokenBalanceError,
    reload: reloadPayingTokenBalance,
  } = useDataLoader(
    '0',
    loadPayingTokenBalance,
    'Failed to load paying token balance'
  )

  /**
   * Loading:
   * - updated jackpot amount
   * - if a user can still buy a ticket
   * - total amount of tickets sold
   * can buy = lootbox.state.scope
   * nr tickets sold = lootbox.state.scope.totalSupply
   * jackpot amount = lootbox.state.jackpotPools[0].amount
   */
  const loadLootboxStatus = useCallback(async () => {
    const { data } = await axios.get<Array<LootboxStatus>>(
      `${apiUrl}/lootboxes`
    )

    const lootbox = data.find(
      (l) =>
        l.address.toLowerCase() ===
        PROJECTS[appLootboxContract].lootboxContractAddress.toLowerCase()
    )

    if (lootbox) {
      const canBuy = isBuyPeriod(lootbox.state.scope)
      setUserCanClaim(canBuy)

      const { payingTokenAddress, chainId } = PROJECTS[appLootboxContract]
      const decimal =
        getErc20Metadata(payingTokenAddress, parseInt(chainId))?.decimals || 18
      const jackpot = formatUnits(
        convertToBigInt('' + lootbox.state.jackpotPools[0].amount).toString(),
        decimal
      )
      setLootboxData((prevState) => ({
        ...prevState,
        jackpotAmount: jackpot,
      }))
      return lootbox.state.scope.totalSupply
    } else {
      return 0
    }
  }, [appLootboxContract, setLootboxData])

  const {
    data: nrTicketsSold,
    isLoading: isLoadingLootboxStatus,
    error: lootboxStatusError,
    reload: reloadLootboxStatus,
  } = useDataLoader(0, loadLootboxStatus, 'Failed to load lootbox status')

  /**
   * Polling lootbox status every 5 sec to:
   * - get updated jackpot status
   * - get updated value canBuy
   */
  useEffect(() => {
    let interval: NodeJS.Timer | undefined

    if (userAddress) {
      interval = setInterval(() => {
        if (!lootboxStatusError) {
          reloadLootboxStatus()
        }
      }, 5000)
    }
    return () => clearInterval(interval) // Cleanup interval on component unmount or when appLootboxContract changes
  }, [reloadLootboxStatus, userAddress, appLootboxContract, lootboxStatusError])

  const context = useMemo(
    () => ({
      canClaim,
      freeTickets,
      prizesInfo,

      history,
      isLoadingHistory,
      historyError,
      reloadHistory,

      gems,
      isLoadingGems,
      gemsError,
      reloadGems,
      setGems,

      isLoadingUserAccount,
      userAccountError,
      reloadUserAccount,

      agaTokenBalance,
      isLoadingAgaTokenBalance,
      agaTokenBalanceError,
      reloadAgaTokenBalance,
      setAgaTokenBalance,

      payingTokenBalance,
      isLoadingPayingTokenBalance,
      payingTokenBalanceError,
      reloadPayingTokenBalance,

      startWaitingForRevealedEvent,
      stopWaitingForRevealedEvent,
      pendingEvent,

      lootboxData,
      isLoadingLootboxData,
      lootboxDataError,
      reloadLootboxData,
      payingTokenSym,

      nrTicketsSold,
      isLoadingLootboxStatus,
      lootboxStatusError,
      reloadLootboxStatus,
    }),
    [
      canClaim,
      freeTickets,
      prizesInfo,

      history,
      isLoadingHistory,
      historyError,
      reloadHistory,

      gems,
      isLoadingGems,
      gemsError,
      reloadGems,
      setGems,

      isLoadingUserAccount,
      userAccountError,
      reloadUserAccount,

      agaTokenBalance,
      isLoadingAgaTokenBalance,
      agaTokenBalanceError,
      reloadAgaTokenBalance,
      setAgaTokenBalance,

      payingTokenBalance,
      isLoadingPayingTokenBalance,
      payingTokenBalanceError,
      reloadPayingTokenBalance,

      startWaitingForRevealedEvent,
      stopWaitingForRevealedEvent,
      pendingEvent,

      lootboxData,
      isLoadingLootboxData,
      lootboxDataError,
      reloadLootboxData,
      payingTokenSym,

      nrTicketsSold,
      isLoadingLootboxStatus,
      lootboxStatusError,
      reloadLootboxStatus,
    ]
  )

  return <DataContext.Provider value={context}>{children}</DataContext.Provider>
}
