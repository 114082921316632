export function preciseMultiply(floatingNumber: number, multiplier: number) {
  // Calculate the scale factor based on the number of digits after the decimal point in the floatingNumber
  const scaleFactor = Math.pow(
    10,
    (floatingNumber.toString().split('.')[1] || '').length
  )

  // Convert the floating number to an integer based on the scale factor
  const scaledNumber = floatingNumber * scaleFactor

  // Perform the multiplication using the scaled number and the original multiplier
  const rawResult = scaledNumber * multiplier

  // Correct the scaling of the result to return to a floating-point number
  const result = rawResult / scaleFactor

  return result
}
