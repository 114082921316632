import { ArbitrumImg, BinanceImg } from 'assets/images'
import { ZeroAddress } from 'ethers'
import { NetworkClass } from 'utils/blockchainNetworks'
import { ArbitrumNetworkClass } from 'utils/blockchainNetworks/ArbitrumNetworkClass'
import { ArbitrumSepoliaNetworkClass } from 'utils/blockchainNetworks/ArbitrumSepoliaNetworkClass'
import { BinanceNetworkClass } from 'utils/blockchainNetworks/BinanceNetworkClass'
import { BinanceTestnetNetworkClass } from 'utils/blockchainNetworks/BinanceTestnetNetworkClass'
import { MumbaiNetworkClass } from 'utils/blockchainNetworks/MumbaiNetoworkClass'
import { PolygonNetworkClass } from 'utils/blockchainNetworks/PolygonNetworkClass'

export type ProjectScheme = {
  name: string
  lootboxContractAddress: string
  openseaUrl: string
  openseaNetwork: string
  rpc: string
  payingTokenAddress: string
  projectName: string
  projectDescription: string
  imgSquare: string
  chainLogo: string
  gemFilterNames: [string, string, string, string]
  isSoldOut: boolean
  soldOutMessage: string
  isUpcoming: boolean
}

const Binance: ProjectScheme = {
  name: 'BNB Smart Chain Mainnet',
  lootboxContractAddress: '0x7fdFa0b9152c0C0606b77F71fC7EFbDFE731C792',
  openseaUrl: 'https://opensea.io',
  openseaNetwork: 'bsc',
  rpc: 'wss://bsc.publicnode.com',
  payingTokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  projectName: 'OXYA ORIGIN & ALPHAVERSE LOOT BOX',
  projectDescription:
    "Unleashing a gaming revolution! Oxya Origin's powerhouse of shooter strategy and exploration games, incubated at Ubisoft, joins forces with Football at AlphaVerse's AI-Powered 3D Interactive Entertainment Platform. Get ready for a cross Decentralised Loot box System on Agora, where you can unlock Agora KEYS from Oxya Origin and score FAV tokens or Magic balls NFTs. Buckle up!",
  imgSquare: 'bg-oxyo',
  chainLogo: BinanceImg,
  gemFilterNames: ['all', 'ALPHAVERSE', 'OXYA ORIGIN', 'legendary'],
  isSoldOut: true,
  soldOutMessage: 'this loot box is completed',
  isUpcoming: false,
}

const BinanceTestnet: ProjectScheme = {
  name: 'BNB Smart Chain Testnet',
  lootboxContractAddress: '0x329cF0853470294b64d9FAC27b55c2901666140F',
  openseaUrl: 'https://testnets.opensea.io',
  openseaNetwork: 'bsc-testnet',
  rpc: 'wss://bsc-testnet-rpc.publicnode.com',
  payingTokenAddress: '0x0e603bCD1A60b816fBc84036c462704e87B3cfD6',
  projectName: 'Binance Testnet',
  projectDescription:
    "Unleashing a gaming revolution! Oxya Origin's powerhouse of shooter strategy and exploration games, incubated at Ubisoft, joins forces with Football at AlphaVerse's AI-Powered 3D Interactive Entertainment Platform. Get ready for a cross Decentralised Loot box System on Agora, where you can unlock Agora KEYS from Oxya Origin and score FAV tokens or Magic balls NFTs. Buckle up!",
  imgSquare: 'bg-oxyo',
  chainLogo: BinanceImg,
  gemFilterNames: ['all', 'OXYA ORIGIN', 'ALPHAVERSE', 'legendary'],
  isSoldOut: false,
  soldOutMessage: 'this loot box is completed',
  isUpcoming: false,
}

const ArbitrumV1: ProjectScheme = {
  name: 'Arbitrum One Mainnet',
  lootboxContractAddress: '0xaDB5f6Ef85A88cDAbcb2DE3e795F97A0C7d4b000',
  openseaUrl: 'https://opensea.io',
  openseaNetwork: 'arbitrum',
  rpc: `wss://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_PROD}`,
  payingTokenAddress: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  projectName: 'SWISSBORG LOOT BOX',
  projectDescription:
    'Get ready for an incredible party with juicy prizes. SwissBorg is bringing to you special loot boxes with outstanding NFTs and the newly forged BORG token! SwissBorg and Agora together aim to democratize investment opportunities, while making them fun, fair, and community centric!',
  imgSquare: 'bg-swissborgloot',
  chainLogo: ArbitrumImg,
  gemFilterNames: ['all', 'magic', 'rare', 'legendary'],
  isSoldOut: true,
  soldOutMessage: 'the lootbox is completed',
  isUpcoming: false,
}

const Arbitrum: ProjectScheme = {
  name: 'Arbitrum One Mainnet',
  lootboxContractAddress: '0xE933b6612A585B76E723FF52BecA5622c38a8176',
  openseaUrl: 'https://opensea.io',
  openseaNetwork: 'arbitrum',
  rpc: `wss://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_PROD}`,
  payingTokenAddress: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  projectName: 'VALENTINES EDITION BY SWISSBORG',
  projectDescription:
    'Get ready for a special Valentine’s Day treat from SwissBorg! Dive into our limited edition loot boxes packed with delightful NFTs and tokens such as Mocaverse NFT, APE coins or Borg tokens. Join the celebration and enjoy our Valentine’s Day edition loot boxes for a touch of love and excitement!',
  imgSquare: 'bg-swissborgvalentine',
  chainLogo: ArbitrumImg,
  gemFilterNames: ['all', 'magic', 'rare', 'legendary'],
  isSoldOut: true,
  soldOutMessage: 'the lootbox is completed',
  isUpcoming: false,
}

const ArbitrumSepolia: ProjectScheme = {
  name: 'Arbitrum Sepolia',
  lootboxContractAddress: '0xa69837bf9596dB254b91C80615dcf174083f91B1',
  openseaUrl: 'https://testnets.opensea.io',
  openseaNetwork: 'arbitrum-sepolia',
  rpc: `wss://arb-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_DEV}`,
  payingTokenAddress: ZeroAddress,
  projectName: 'Test lootbox',
  projectDescription:
    'Get ready for an incredible party with juicy prizes. SwissBorg is bringing to you special loot boxes with outstanding NFTs and the newly forged BORG token! SwissBorg and Agora together aim to democratize investment opportunities, while making them fun, fair, and community centric!',
  imgSquare: 'bg-swissborgloot',
  chainLogo: ArbitrumImg,
  gemFilterNames: ['all', 'magic', 'rare', 'legendary'],
  isSoldOut: true,
  soldOutMessage: 'this loot box is completed',
  isUpcoming: false,
}

const MumbaiPolygon: ProjectScheme = {
  name: 'Mumbai Polygon ',
  lootboxContractAddress: '0x587202899C17505c4b2C189d0F17Dd17a09d8AdB',
  openseaUrl: 'https://testnets.opensea.io',
  openseaNetwork: 'mumbai',
  rpc: 'wss://polygon-mumbai.infura.io/ws/v3/15eea2382c27429886d68fde4a6bfa3f',
  payingTokenAddress: ZeroAddress,
  projectName: 'SWISSBORG AIRDROP SEASON NFTS',
  projectDescription:
    "Hunt the NFT with the most promising aidrop potential ! To secure your spot for the next Swissborg Alpha deal, dive into a treasure trove of exclusive NFTs waiting to be claimed. Join the Golden Era of Airdrops and unlock access to promising opportunities with collections like Yogapetz, Pixelmon Trainers, and Genesis Bakers. Don't miss out on the chance to elevate your investment game and reap high returns!",
  imgSquare: 'bg-swissborgairdrop',
  chainLogo: 'https://polygonscan.com/token/images/polygonmatic_new_32.png',
  gemFilterNames: ['all', 'magic', 'rare', 'legendary'],
  isSoldOut: false,
  soldOutMessage: 'this loot box is completed',
  isUpcoming: true,
}

export const Polygon: ProjectScheme = {
  name: 'Polygon ',
  lootboxContractAddress: '0x65CA21a83aF05776D42F34d9d518e161E65dd293',
  openseaUrl: 'https://opensea.io',
  openseaNetwork: 'matic',
  rpc: 'wss://polygon-mainnet.infura.io/ws/v3/d79df6abcf024e3b8aa005269ca65b0c',
  payingTokenAddress: ZeroAddress,
  projectName: 'SWISSBORG AIRDROP SEASON NFTS',
  projectDescription:
    "Hunt the NFT with the most promising aidrop potential ! To secure your spot for the next Swissborg Alpha deal, dive into a treasure trove of exclusive NFTs waiting to be claimed. Join the Golden Era of Airdrops and unlock access to promising opportunities with collections like Yogapetz, Pixelmon Trainers, and Genesis Bakers. Don't miss out on the chance to elevate your investment game and reap high returns!",
  imgSquare: 'bg-swissborgairdrop',
  chainLogo: 'https://polygonscan.com/token/images/polygonmatic_new_32.png',
  gemFilterNames: ['all', 'magic', 'rare', 'legendary'],
  isSoldOut: true,
  soldOutMessage: 'sold out',
  isUpcoming: false,
}

const DEV_PROJECTS: { [lootboxContract: string]: NetworkClass } = {
  [MumbaiPolygon.lootboxContractAddress]: new MumbaiNetworkClass(
    '0x13881',
    MumbaiPolygon.name,
    MumbaiPolygon.lootboxContractAddress,
    MumbaiPolygon.openseaUrl,
    MumbaiPolygon.openseaNetwork,
    MumbaiPolygon.rpc,
    MumbaiPolygon.payingTokenAddress,
    MumbaiPolygon.projectName,
    MumbaiPolygon.projectDescription,
    MumbaiPolygon.imgSquare,
    MumbaiPolygon.chainLogo,
    MumbaiPolygon.gemFilterNames,
    MumbaiPolygon.isSoldOut,
    MumbaiPolygon.soldOutMessage,
    MumbaiPolygon.isUpcoming
  ),
  [ArbitrumSepolia.lootboxContractAddress]: new ArbitrumSepoliaNetworkClass(
    '0x66eee',
    ArbitrumSepolia.name,
    ArbitrumSepolia.lootboxContractAddress,
    ArbitrumSepolia.openseaUrl,
    ArbitrumSepolia.openseaNetwork,
    ArbitrumSepolia.rpc,
    ArbitrumSepolia.payingTokenAddress,
    ArbitrumSepolia.projectName,
    ArbitrumSepolia.projectDescription,
    ArbitrumSepolia.imgSquare,
    ArbitrumSepolia.chainLogo,
    ArbitrumSepolia.gemFilterNames,
    ArbitrumSepolia.isSoldOut,
    ArbitrumSepolia.soldOutMessage,
    ArbitrumSepolia.isUpcoming
  ),
  [BinanceTestnet.lootboxContractAddress]: new BinanceTestnetNetworkClass(
    '0x61',
    BinanceTestnet.name,
    BinanceTestnet.lootboxContractAddress,
    BinanceTestnet.openseaUrl,
    BinanceTestnet.openseaNetwork,
    BinanceTestnet.rpc,
    BinanceTestnet.payingTokenAddress,
    BinanceTestnet.projectName,
    BinanceTestnet.projectDescription,
    BinanceTestnet.imgSquare,
    BinanceTestnet.chainLogo,
    BinanceTestnet.gemFilterNames,
    BinanceTestnet.isSoldOut,
    BinanceTestnet.soldOutMessage,
    BinanceTestnet.isUpcoming
  ),
}

const PROD_PROJECTS: { [lootboxContract: string]: NetworkClass } = {
  // swissborg v4
  [Polygon.lootboxContractAddress]: new PolygonNetworkClass(
    '0x89',
    Polygon.name,
    Polygon.lootboxContractAddress,
    Polygon.openseaUrl,
    Polygon.openseaNetwork,
    Polygon.rpc,
    Polygon.payingTokenAddress,
    Polygon.projectName,
    Polygon.projectDescription,
    Polygon.imgSquare,
    Polygon.chainLogo,
    Polygon.gemFilterNames,
    Polygon.isSoldOut,
    Polygon.soldOutMessage,
    Polygon.isUpcoming
  ),

  // swissborg lootbox v2
  [Arbitrum.lootboxContractAddress]: new ArbitrumNetworkClass(
    '0xa4b1',
    Arbitrum.name,
    Arbitrum.lootboxContractAddress,
    Arbitrum.openseaUrl,
    Arbitrum.openseaNetwork,
    Arbitrum.rpc,
    Arbitrum.payingTokenAddress,
    Arbitrum.projectName,
    Arbitrum.projectDescription,
    Arbitrum.imgSquare,
    Arbitrum.chainLogo,
    Arbitrum.gemFilterNames,
    Arbitrum.isSoldOut,
    Arbitrum.soldOutMessage,
    Arbitrum.isUpcoming
  ),
  // swissborg lootbox v1
  [ArbitrumV1.lootboxContractAddress]: new ArbitrumNetworkClass(
    '0xa4b1',
    ArbitrumV1.name,
    ArbitrumV1.lootboxContractAddress,
    ArbitrumV1.openseaUrl,
    ArbitrumV1.openseaNetwork,
    ArbitrumV1.rpc,
    ArbitrumV1.payingTokenAddress,
    ArbitrumV1.projectName,
    ArbitrumV1.projectDescription,
    ArbitrumV1.imgSquare,
    ArbitrumV1.chainLogo,
    ArbitrumV1.gemFilterNames,
    ArbitrumV1.isSoldOut,
    ArbitrumV1.soldOutMessage,
    ArbitrumV1.isUpcoming
  ),
  [Binance.lootboxContractAddress]: new BinanceNetworkClass(
    '0x38',
    Binance.name,
    Binance.lootboxContractAddress,
    Binance.openseaUrl,
    Binance.openseaNetwork,
    Binance.rpc,
    Binance.payingTokenAddress,
    Binance.projectName,
    Binance.projectDescription,
    Binance.imgSquare,
    Binance.chainLogo,
    Binance.gemFilterNames,
    Binance.isSoldOut,
    Binance.soldOutMessage,
    Binance.isUpcoming
  ),
}

export const PROJECTS =
  process.env.NODE_ENV === 'production' ? PROD_PROJECTS : DEV_PROJECTS
