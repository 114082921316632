import { useWalletContext } from 'context/Wallet'
import { useState, useEffect, useCallback } from 'react'
import { PROJECTS } from 'utils/config/projects'

/**
 * Custom hook for loading data with a callback function.
 * @param loadDataCallback A callback function that defines how to load the data.
 * @param errorMessage Error message used to record an error in Sentry
 */
export function useDataLoader<T>(
  initData: T,
  loadDataCallback: () => Promise<T>,
  errorMessage: string
) {
  // State for storing the data.
  const [data, setData] = useState<T>(initData)
  // State for tracking whether the data is being loaded.
  const [isLoading, setIsLoading] = useState(false)
  // State for tracking errors.
  const [error, setError] = useState<string | null>(null)

  const { userAddress, appLootboxContract, activeNetworkId } =
    useWalletContext()

  // Fetch data using the provided callback function. This function is memoized with useCallback.
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const data = await loadDataCallback()
      setData(data)
    } catch (error) {
      console.error(`${errorMessage}`, error)
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError(JSON.stringify(error))
      }
    } finally {
      setIsLoading(false)
    }
  }, [loadDataCallback, errorMessage])

  // useEffect to re-fetch data when dependencies change.
  useEffect(() => {
    if (
      PROJECTS[appLootboxContract].chainId.toLowerCase() ===
        activeNetworkId?.toLowerCase() &&
      userAddress
    ) {
      fetchData()
    }
  }, [fetchData, userAddress, appLootboxContract, activeNetworkId])

  // Return the data, isLoading flag, error message, and a function to manually trigger a reload of the data.
  return { data, isLoading, error, reload: fetchData, setData }
}
