import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { formatEther, toNumber } from 'ethers'

import { FacebookIcon, TwitterIcon } from 'assets/icons'
import { PROJECTS } from 'utils/config/projects'
import { useWalletContext } from 'context/Wallet'
import { Lootcard } from 'components/Lootcard'
import { ChildrenProp } from 'types/common'
import { PrizesFlowContext } from './context'
import { useOverlayContext } from 'context/Overlay'
import { GemsTuple } from './types'
import { useDataContext } from 'context/Data'
import { ToastType, useToastContext } from 'context/Toast'
import { getGemImg } from 'utils/getGemImg'
import { ResellBtn } from 'components/ResellBtn'
import { isProviderRpcError } from 'types/errors'
import { getRarityName } from 'utils/getRarityName'
import { TokenPrize } from 'components/TokenPrize'
import { NftPrize } from 'components/NftPrize'
import { Btn } from 'components/Btn'
import { useDrawerContext } from 'context/Drawer'
import { WhatIsVault } from 'components/WhatIsVault'
import { DiscoverGemsBg, DiscoverPrizeBg } from 'assets/images'
import {
  isLootBoxRevealedEventArgs,
  isPrizeClaimedEventArgs,
  isUnlockEventArgs,
} from 'types/guard'
import { EventHandler, LootboxEvents } from 'types/events'
import { usePrizeClaimedEventBackup } from 'hooks/usePrizeClaimedEventBackup'

type LooboxState = 'none' | 'revealed' | 'final'

const PROD_URL = window.location.hostname

const defaultGems: GemsTuple = [0, 0, 0, 0]
const defaultTokenInfo = {
  amount: 0,
  tokenContract: '',
  chainId: 0,
}
const defaultNftInfo = {
  tokenContract: '',
  tokenId: 0,
  chainId: 0,
}

export const PrizesFlowProvider: FC<ChildrenProp> = ({ children }) => {
  // every steps has a different UI
  const [lootboxState, setLootboxState] = useState<LooboxState>('none')
  // to show NFT or a token at the final step
  const [receivedNft, setReceivedNft] = useState(true)
  // a list of gems in a lootbox(-es)
  const [gems, setGems] = useState<GemsTuple>(defaultGems)
  const [newBadges, setNewBadges] = useState<
    [
      legendaryNewBadge: boolean,
      rareNewBadge: boolean,
      magicNewBadge: boolean,
      jackpotNewBadge: boolean,
      vaultNewBadge: boolean
    ]
  >([false, false, false, false, false])
  const [erc20Events, setErc20Events] = useState<
    Array<{
      lootboxId: bigint
      amount: bigint
      tokenContract: string
      user: string
      chainId: bigint
    }>
  >([])
  const [nftEvents, setNftEvents] = useState<
    Array<{
      lootboxId: bigint
      tokenId: bigint
      collection: string
      user: string
      chainId: bigint
    }>
  >([])
  const [tokenId, setTokenId] = useState<string | undefined>(undefined)
  const [unlockId, setUnlockId] = useState<string | undefined>(undefined)

  const [tokenInfo, setTokenInfo] = useState<{
    amount: number
    tokenContract: string
    chainId: number
  }>(defaultTokenInfo)
  const [nftInfo, setNftInfo] = useState<{
    tokenContract: string
    tokenId: number
    chainId: number
  }>(defaultNftInfo)

  const [sentToVault, setSentToVault] = useState(false)

  // a raity of a gems being claimed
  const currentlyClaimingRarityRef = useRef<number | undefined>(undefined)

  const { showDrawer } = useDrawerContext()
  const { appLootboxContract, userAddress } = useWalletContext()
  const { startAnimation, handleLoadingProcessWithPause, finishLoading } =
    useOverlayContext()
  const {
    setGems: setGlobalGems,
    reloadUserAccount,
    reloadHistory,
    setAgaTokenBalance,
    stopWaitingForRevealedEvent,
    pendingEvent: pendingRevealedEvent,
  } = useDataContext()
  const { addToast } = useToastContext()

  const {
    startWaitingForPrizeClaimedEvent,
    stopWaitingForPrizeClaimedEvent,
    pendingEvent,
  } = usePrizeClaimedEventBackup()

  const facebookShareLink = useMemo(() => {
    let firstNonZeroIndex = gems.findIndex((gem) => Number(gem) !== 0)
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      PROD_URL + '/share?id=' + firstNonZeroIndex
    )}`
  }, [gems])

  const twitterShareLink = useMemo(() => {
    let firstNonZeroIndex = gems.findIndex((gem) => Number(gem) !== 0)
    return `https://www.twitter.com/intent/tweet?url=${encodeURIComponent(
      PROD_URL + '/share?id=' + firstNonZeroIndex
    )}`
  }, [gems])

  // 'LootBoxRevealed' event handler to show gems
  const handleRevealed: EventHandler = useCallback(
    (response) => {
      if ('args' in response && isLootBoxRevealedEventArgs(response.args)) {
        const [owner, emptyCount, rarityCounts] = response.args
        console.log('REVEAL event', owner, emptyCount, rarityCounts)

        setGems([
          Number(rarityCounts[0]),
          Number(rarityCounts[1]),
          Number(rarityCounts[2]),
          Number(emptyCount),
        ])
        setGlobalGems((prevState) => [
          prevState[0] + Number(rarityCounts[0]),
          prevState[1] + Number(rarityCounts[1]),
          prevState[2] + Number(rarityCounts[2]),
          prevState[3] + Number(emptyCount),
        ])

        stopWaitingForRevealedEvent()

        startAnimation('lootbox')
        setLootboxState('revealed')
        finishLoading()
      } else {
        console.error(
          "response doesn't have args or args aren't a type of PrizeClaimed event",
          response
        )
      }
    },
    [startAnimation, finishLoading, setGlobalGems, stopWaitingForRevealedEvent]
  )

  const handleBackToLoots = useCallback(() => {
    if (currentlyClaimingRarityRef.current !== undefined) {
      removeGemFromList(currentlyClaimingRarityRef.current)
      currentlyClaimingRarityRef.current = undefined
      setTokenInfo(defaultTokenInfo)
      setNftInfo(defaultNftInfo)
      setSentToVault(false)
      setLootboxState('revealed')
    }
  }, [])

  /**
   * This became a separate hook, to make handleUnlock pure fn to avoid unnecessary run of `setupEventListeners` fn
   */
  useEffect(() => {
    if (
      tokenId &&
      unlockId &&
      tokenId?.toLowerCase() === unlockId?.toLowerCase()
    ) {
      addToast(ToastType.failure, 'The treasury is empty')
      finishLoading()
      handleBackToLoots()
      setUnlockId(undefined)
      setTokenId(undefined)
    }
  }, [tokenId, unlockId, addToast, finishLoading, handleBackToLoots])

  // 'Unlocked' event handler
  const handleUnlocked: EventHandler = useCallback((response) => {
    if ('args' in response && isUnlockEventArgs(response.args)) {
      const [id] = response.args
      console.log('UNLOCKED event', id)

      setUnlockId(id?.toString())
    } else {
      console.error(
        "response doesn't have args or args aren't a type of Unlocked event",
        response
      )
    }
  }, [])

  // used from v2
  // prizeType PRIZE_ERC20 = 1, PRIZE_ERC721 = 2,  PRIZE_ERC1155 = 3
  const handlePrizeClaimed: EventHandler = useCallback((response) => {
    if ('args' in response && isPrizeClaimedEventArgs(response.args)) {
      const [
        lootboxId,
        user,
        prizeType,
        tokenContract,
        tokenId,
        amount,
        chainId,
      ] = response.args

      console.log(
        'PRIZE CLAIMED event',
        lootboxId,
        user,
        prizeType,
        tokenContract,
        tokenId,
        amount,
        chainId
      )

      if (Number(prizeType) === 1) {
        setErc20Events((prevState) => {
          if (
            prevState.find(
              (ps) => ps.lootboxId.toString() === lootboxId.toString()
            )
          ) {
            return prevState
          } else {
            return [
              ...prevState,
              {
                lootboxId,
                amount,
                tokenContract,
                user,
                chainId,
              },
            ]
          }
        })
        // erc20
      } else {
        // nft
        setNftEvents((prevState) => {
          if (
            prevState.find(
              (ps) => ps.lootboxId.toString() === lootboxId.toString()
            )
          ) {
            return prevState
          } else {
            return [
              ...prevState,
              {
                lootboxId,
                tokenId,
                collection: tokenContract,
                user,
                chainId,
              },
            ]
          }
        })
      }
    } else {
      console.error(
        "response doesn't have args or args aren't a type of PrizeClaimed event",
        response
      )
    }
  }, [])

  /**
   * In case event from logs comes earlier use it to tigger PrizeClaimed event handler
   */
  useEffect(() => {
    if (pendingEvent) {
      handlePrizeClaimed(pendingEvent)
    }
  }, [pendingEvent, handlePrizeClaimed])

  /**
   * In case event from logs comes earlier use it to tigger LootBoxRevealed event handler
   */
  useEffect(() => {
    if (pendingRevealedEvent) {
      handleRevealed(pendingRevealedEvent)
    }
  }, [pendingRevealedEvent, handleRevealed])

  // If a prize was sent to a vault show "new" indicator in the navigation
  useEffect(() => {
    if (sentToVault) {
      setNewBadges((prevState) => {
        const state = prevState.slice() as [
          boolean,
          boolean,
          boolean,
          boolean,
          boolean
        ]
        state[4] = true
        return state
      })
      reloadUserAccount()
    }
  }, [sentToVault, reloadUserAccount])

  // it is a loop that checks tokenId agains stored erc20 nft events
  // once an event with the same id was found show 'final' stage and a prize
  useEffect(() => {
    if (tokenId) {
      const erc20Event = erc20Events.find(
        (e) => e.lootboxId.toString() === tokenId
      )
      const nftEvent = nftEvents.find((e) => e.lootboxId.toString() === tokenId)
      if (erc20Event) {
        console.log('found an erc20 event', erc20Event)
        const { amount, tokenContract, chainId } = erc20Event
        // requesting an update of aga tokens
        if (
          tokenContract.toLowerCase() ===
          (process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_AGA_ARBITRUM_CONTRACT_PROD?.toLowerCase() ||
              process.env.REACT_APP_AGA_POLYGON_CONTRACT_PROD?.toLowerCase()
            : process.env.REACT_APP_AGA_ARBITRUM_CONTRACT_DEV?.toLowerCase())
        ) {
          setAgaTokenBalance((prevState) =>
            (Number(prevState) + Number(formatEther(amount))).toString()
          )
        }
        setTokenInfo({
          amount: Number(amount),
          tokenContract,
          chainId: Number(chainId),
        })
        setSentToVault(
          Number(chainId) !== parseInt(PROJECTS[appLootboxContract].chainId)
        )
        setLootboxState('final')
        startAnimation(
          currentlyClaimingRarityRef.current === 0
            ? 'legendary'
            : currentlyClaimingRarityRef.current === 1
            ? 'rare'
            : 'magic'
        )
        setReceivedNft(false)
        setTokenId(undefined)

        reloadHistory()
      } else if (nftEvent) {
        console.log('found an nft event', nftEvent)
        const { chainId, collection, tokenId } = nftEvent
        setNftInfo({
          tokenContract: collection,
          tokenId: Number(tokenId),
          chainId: Number(chainId),
        })
        setLootboxState('final')
        setSentToVault(
          Number(chainId) !== parseInt(PROJECTS[appLootboxContract].chainId)
        )
        startAnimation(
          currentlyClaimingRarityRef.current === 0
            ? 'legendary'
            : currentlyClaimingRarityRef.current === 1
            ? 'rare'
            : 'magic'
        )
        setReceivedNft(true)
        setTokenId(undefined)

        reloadHistory()
      }
    }
  }, [
    tokenId,
    erc20Events,
    nftEvents,
    appLootboxContract,
    startAnimation,
    setAgaTokenBalance,
    reloadHistory,
  ])

  useEffect(() => {
    if (lootboxState === 'final') {
      finishLoading()
      if (currentlyClaimingRarityRef.current !== undefined) {
        const rarity = currentlyClaimingRarityRef.current
        setGlobalGems((prevState) => {
          const state = prevState.slice() as [
            legendaryGem: number,
            rareGem: number,
            magicGem: number,
            empty: number
          ]
          if (state[rarity] > 0) {
            --state[rarity]
          } else {
            state[rarity] = 0
          }
          return state
        })
      }
    }
  }, [lootboxState, finishLoading, setGlobalGems])

  // register event listeners
  useEffect(() => {
    const subscibeToAllEvents = async () => {
      try {
        await PROJECTS[appLootboxContract].removeEventListeners()
        await PROJECTS[appLootboxContract].addEventListeners({
          [LootboxEvents.revealed]: handleRevealed,
          [LootboxEvents.unlocked]: handleUnlocked,
          [LootboxEvents.prizeClaimed]: handlePrizeClaimed,
        })
      } catch (e) {
        addToast(
          ToastType.failure,
          'Something went wrong. Please reload the page to continue'
        )
        // TODO: show error toast to a user
        console.error(
          `Couldn't subscribe to events for user ${userAddress}, contractAddress: ${appLootboxContract}`,
          e
        )
      }
    }
    if (appLootboxContract && userAddress) {
      subscibeToAllEvents()
    }
  }, [
    appLootboxContract,
    handleRevealed,
    handleUnlocked,
    handlePrizeClaimed,
    userAddress,
    addToast,
  ])

  // reducing the gems count
  const removeGemFromList = (rarity: number, removeAll: boolean = false) => {
    setGems((prevState) => {
      const state = prevState.slice() as [
        legendaryGem: number,
        rareGem: number,
        magicGem: number,
        empty: number
      ]
      if (removeAll) {
        state[rarity] = 0
      } else {
        if (state[rarity] > 0) {
          --state[rarity]
        } else {
          state[rarity] = 0
        }
      }
      return state
    })
  }

  const handleOpenLater = (rarity: number, removeAll: boolean = false) => {
    // show "new" indicator in navigation
    setNewBadges((prevState) => {
      const state = prevState.slice() as [
        boolean,
        boolean,
        boolean,
        boolean,
        boolean
      ]
      state[rarity] = true
      return state
    })
    removeGemFromList(rarity, removeAll)
  }

  // hide all screens
  useEffect(() => {
    if (!gems.some((g) => Boolean(g)) && userAddress) {
      setLootboxState('none')
      setGems(defaultGems)
      currentlyClaimingRarityRef.current = undefined
      setErc20Events([])
      setNftEvents([])
    }
  }, [gems, lootboxState, userAddress])

  const handleClaim = useCallback(
    async (rarity: number) => {
      try {
        await handleLoadingProcessWithPause(
          async () => {
            //reseting unlock ids before claiming to avoid hitting "Treasury empty condition
            setUnlockId(undefined)

            // Register rarity to remove it from displayed "Your loots" later
            currentlyClaimingRarityRef.current = rarity

            const txnHash = await PROJECTS[
              appLootboxContract
            ].handleBurnForRandomPrize(rarity)

            const response = await PROJECTS[
              appLootboxContract
            ].handleGetTokenIdorClaimedEvent(txnHash)

            console.log('response', response)
            if (typeof response === 'bigint') {
              setTokenId(response.toString())
            } else if (response.prize) {
              handlePrizeClaimed(response.prize)
              setTokenId(response.prize.args[0].toString())
            } else {
              console.error(
                'handleGetTokenIdorClaimedEvent response error',
                response
              )
            }
          },
          'Matching Your Prize!',
          "Aligning your gem's rarity with the perfect prize. Almost there, get ready to discover your special reward!",
          'Transaction submitted to blockchain. Please wait for processing'
        )
      } catch (e) {
        setTokenId(undefined)
        if (isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
          // ignore
        } else {
          console.error('Error in handleClaim', e)
          addToast(ToastType.failure, `Error claiming token ${rarity} ${e}`)
        }
      }
    },
    [
      addToast,
      appLootboxContract,
      handleLoadingProcessWithPause,
      handlePrizeClaimed,
    ]
  )

  // once topicId is known (it is known after successful publish of burnForRandomPrize txn, which is called in handleClaim fn) startWaitingForPrizeClaimedEvent
  useEffect(() => {
    if (tokenId) {
      // this will start polling user account up until a timestamp will get updated with freshest claim event
      startWaitingForPrizeClaimedEvent(tokenId)
    } else {
      stopWaitingForPrizeClaimedEvent()
    }
  }, [
    tokenId,
    startWaitingForPrizeClaimedEvent,
    stopWaitingForPrizeClaimedEvent,
  ])

  const removeNewBadge = useCallback((rarity: number) => {
    setNewBadges((prevState) => {
      const state = prevState.slice() as [
        boolean,
        boolean,
        boolean,
        boolean,
        boolean
      ]
      state[rarity] = false
      return state
    })
  }, [])

  const handleOpenInfoAboutTheVault = () => {
    showDrawer(<WhatIsVault />, {
      direction: 'right',
      size: '',
      className: 'w-full md:w-5/12',
    })
  }

  const context = useMemo(
    () => ({
      newBadges,
      removeNewBadge,
      handleClaim,
      setGems,
      currentlyClaimingRarityRef,
    }),
    [handleClaim, newBadges, removeNewBadge]
  )

  return (
    <PrizesFlowContext.Provider value={context}>
      {children}
      {lootboxState === 'none' ? null : (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black z-50 overflow-x-hidden overflow-y-scroll">
          <img
            src={lootboxState === 'revealed' ? DiscoverGemsBg : DiscoverPrizeBg}
            alt=""
            className="hidden md:block absolute z-[-1] opacity-20 max-w-screen top-0 left-1/2 -translate-x-1/2 max-h-screen"
          />
          <div
            className={`flex flex-col min-h-full md:h-full items-center ${
              lootboxState === 'revealed' ? 'justify-between' : 'justify-center'
            } py-16 md:py-8`}
          >
            {lootboxState === 'revealed' ? (
              <>
                <p className="text-[48px] text-white uppercase leading-none mb-10 md:mb-0">
                  Your loot
                </p>
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-6 md:mt-0">
                  {Boolean(gems[0]) ? (
                    <Lootcard
                      imgSrc={getGemImg(0)}
                      counter={toNumber(gems[0])}
                      cardTitle={PROJECTS[appLootboxContract].gemFilterNames[3]}
                      secondaryBtns={() => (
                        <div className="flex">
                          <ResellBtn gemRarity={0} />
                          <div className="w-2.5" />
                          <button
                            type="button"
                            onClick={() => handleOpenLater(0)}
                            className="w-full px-6 h-[35px] border border-pampas/20 flex items-center justify-center"
                          >
                            <p className="text-white uppercase">open later</p>
                          </button>
                        </div>
                      )}
                      mainBtnLabel="claim"
                      onMainBtnClick={() => handleClaim(0)}
                      mainBtnType="primary"
                    />
                  ) : null}
                  {Boolean(gems[1]) ? (
                    <Lootcard
                      imgSrc={getGemImg(1)}
                      counter={toNumber(gems[1])}
                      cardTitle={PROJECTS[appLootboxContract].gemFilterNames[2]}
                      secondaryBtns={() => (
                        <div className="flex">
                          <ResellBtn gemRarity={1} />
                          <div className="w-2.5" />
                          <button
                            type="button"
                            onClick={() => handleOpenLater(1)}
                            className="w-full px-6 h-[35px] border border-pampas/20 flex items-center justify-center"
                          >
                            <p className="text-white uppercase">open later</p>
                          </button>
                        </div>
                      )}
                      mainBtnLabel="claim"
                      onMainBtnClick={() => handleClaim(1)}
                      mainBtnType="primary"
                    />
                  ) : null}
                  {Boolean(gems[2]) ? (
                    <Lootcard
                      imgSrc={getGemImg(2)}
                      counter={toNumber(gems[2])}
                      cardTitle={PROJECTS[appLootboxContract].gemFilterNames[1]}
                      secondaryBtns={() => (
                        <div className="flex">
                          <ResellBtn gemRarity={1} />
                          <div className="w-2.5" />
                          <button
                            type="button"
                            onClick={() => handleOpenLater(2)}
                            className="w-full px-6 h-[35px] border border-pampas/20 flex items-center justify-center"
                          >
                            <p className="text-white uppercase">open later</p>
                          </button>
                        </div>
                      )}
                      mainBtnLabel="claim"
                      onMainBtnClick={() => handleClaim(2)}
                      mainBtnType="primary"
                    />
                  ) : null}
                  {Boolean(gems[3]) ? (
                    <Lootcard
                      imgSrc={getGemImg(3)}
                      counter={toNumber(gems[3])}
                      cardTitle={getRarityName(3)}
                      mainBtnLabel="add to jackpot"
                      onMainBtnClick={() => handleOpenLater(3, true)}
                      mainBtnType="primary"
                      secondaryBtns={() => <ResellBtn gemRarity={3} />}
                    />
                  ) : null}
                </div>
                <div className="flex my-10 md:my-0">
                  <p className="uppercase text-white">Share</p>
                  <div className="w-5" />
                  <a
                    className="w-6 h-6"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={facebookShareLink}
                  >
                    <FacebookIcon />
                  </a>
                  <div className="w-5" />
                  <a
                    className="w-6 h-6"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={twitterShareLink}
                  >
                    <TwitterIcon />
                  </a>
                </div>
              </>
            ) : lootboxState === 'final' ? (
              <>
                {sentToVault ? (
                  <div className="absolute bottom-5 right-5">
                    <Btn
                      type="secondary"
                      label="what is my vault ?"
                      onPress={handleOpenInfoAboutTheVault}
                      size="small"
                    />
                  </div>
                ) : null}
                <p className="text-[36px] md:text-[48px] text-white uppercase text-center leading-none">
                  Added to your {sentToVault ? 'vault' : 'wallet'}
                </p>
                <div className="h-6 md:h-16" />
                <div className="">
                  {receivedNft ? (
                    <NftPrize
                      contractAddress={nftInfo.tokenContract}
                      tokenId={nftInfo.tokenId}
                      chainId={nftInfo.chainId}
                      onClick={handleBackToLoots}
                      mainBtnLabel="back to loots"
                      mainBtnType="primary"
                    />
                  ) : (
                    <TokenPrize
                      contractAddress={tokenInfo.tokenContract}
                      amount={tokenInfo.amount}
                      chainId={tokenInfo.chainId}
                      onClick={handleBackToLoots}
                      mainBtnLabel="back to loots"
                      mainBtnType="primary"
                    />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </PrizesFlowContext.Provider>
  )
}
