import { SVGProps } from 'react'

export const SoundOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.11206 12.5513L10.5751 14.1188C10.7422 14.2441 10.9409 14.3204 11.149 14.3392C11.3571 14.358 11.5663 14.3184 11.7532 14.225C11.94 14.1315 12.0972 13.9879 12.207 13.8102C12.3169 13.6325 12.3751 13.4277 12.3751 13.2188V9.28125"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.375 4.21875C12.375 4.00982 12.3168 3.80503 12.207 3.6273C12.0971 3.44958 11.94 3.30595 11.7531 3.21252C11.5662 3.11909 11.3571 3.07953 11.149 3.0983C10.9409 3.11706 10.7421 3.1934 10.575 3.31875L5.625 6.46875H3.375C3.07663 6.46875 2.79048 6.58728 2.5795 6.79826C2.36853 7.00923 2.25 7.29538 2.25 7.59375V9.84375C2.25 10.1421 2.36853 10.4283 2.5795 10.6392C2.79048 10.8502 3.07663 10.9688 3.375 10.9688H3.84375"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 14.9062L15.75 3.65625"
      stroke="#ECE8E1"
      strokeWidth={1.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
