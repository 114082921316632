import { Btn } from './Btn'
import { useNavigate } from 'react-router-dom'
import { LootboxRoutes } from 'types/routes'
import { useDataContext } from 'context/Data'
import { ReloadIcon, SpinnerIcon } from 'assets/icons'
import { Ticket } from './Ticket'
import { truncateNumber } from 'utils/truncateNumber'
import { useEnoughForJackpot } from 'hooks/useEnoughForJackpot'

export const JackpotMini = () => {
  const {
    lootboxData,
    payingTokenSym,
    isLoadingLootboxData,
    lootboxDataError,
    reloadLootboxData,
    lootboxStatusError,
    reloadLootboxStatus,
    gems,
  } = useDataContext()
  const navigate = useNavigate()
  const { isEnoughForJackpot } = useEnoughForJackpot()

  const handleNavigateToJackpot = () => {
    navigate(`/${LootboxRoutes.jackpot}`)
  }

  return (
    <div className="flex justify-center w-full p-4 lootbox-md:p-6 border border-pampas/20 bg-jackpot bg-cover lootbox-md:bg-contain">
      <div className="w-full hidden lootbox-md:flex flex-col items-center justify-between">
        <p className="uppercase text-[14px] text-pacificBlue">
          Current jackpot
        </p>
        {lootboxDataError || lootboxStatusError ? (
          <button
            type="button"
            onClick={lootboxDataError ? reloadLootboxData : reloadLootboxStatus}
            className="flex items-center"
          >
            <p className="uppercase text-[24px] text-white flex items-center text-center m-2">
              Error
            </p>
            <ReloadIcon />
          </button>
        ) : (
          <p className="uppercase text-[24px] text-white flex items-center text-center">
            {isLoadingLootboxData ? (
              <SpinnerIcon extendedClass="w-6 h-6" />
            ) : lootboxData.jackpotAmount ? (
              truncateNumber(lootboxData.jackpotAmount, 6)
            ) : (
              0
            )}{' '}
            {payingTokenSym}
          </p>
        )}
        <p className="uppercase text-[14px] text-pampas leading-none">
          {gems[3] && gems[3] >= 3 ? gems[3] : gems[3] + '/3'} tickets
        </p>
        <div className="h-3.5" />
        {!isEnoughForJackpot ? (
          <div className="flex">
            <div className="z-20">
              <Ticket isEmpty={!(gems[3] >= 1)} />
            </div>
            <div className="z-10 -ml-[30px]">
              <Ticket isEmpty={!(gems[3] >= 2)} />
            </div>
            <div className="z-0 -ml-[30px]">
              <Ticket isEmpty={!(gems[3] >= 3)} />
            </div>
          </div>
        ) : (
          <div className="relative">
            <div className="absolute scale-[0.75] -translate-y-[17px] z-[-1] opacity-40">
              <Ticket isEmpty={false} />
            </div>
            <div className="absolute scale-[0.9] -translate-y-2 z-0 opacity-50">
              <Ticket isEmpty={false} />
            </div>
            <div className="z-10">
              <Ticket isEmpty={false} />
            </div>
          </div>
        )}
        <div className="h-3.5" />
        <div className="flex-1 w-full">
          <Btn
            label="Jackpot"
            onPress={handleNavigateToJackpot}
            type="primary"
          />
        </div>
      </div>
      <div
        className={`flex lootbox-md:hidden items-center w-full ${
          !isEnoughForJackpot ? 'flex-col justify-between' : 'justify-between'
        }`}
      >
        {isEnoughForJackpot ? (
          <>
            <Ticket isEmpty={false} size="xs" />
            <div className="flex flex-col ml-3.5 flex-1">
              <p className="uppercase text-pacificBlue text-[14px] translate-y-1">
                Current jackpot
              </p>
              {lootboxDataError ? (
                <button
                  type="button"
                  onClick={reloadLootboxData}
                  className="flex items-center"
                >
                  <p className="uppercase text-[24px] text-white flex items-center text-center m-2">
                    Error
                  </p>
                  <ReloadIcon />
                </button>
              ) : (
                <p className="uppercase text-white text-[24px]">
                  {isLoadingLootboxData ? (
                    <SpinnerIcon extendedClass="w-6 h-6" />
                  ) : lootboxData.jackpotAmount ? (
                    truncateNumber(lootboxData.jackpotAmount, 6)
                  ) : (
                    0
                  )}{' '}
                  {payingTokenSym}
                </p>
              )}
            </div>
            <div className="flex flex-col items-center ml-3.5">
              <p className="uppercase text-pampas text-[14px]">
                {gems[3]} tickets
              </p>
              <div className="h-1" />
              <Btn
                label="Jackpot"
                onPress={handleNavigateToJackpot}
                type="primary"
                size="xs"
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center justify-between w-full">
              <div className="flex items-center">
                <p className="uppercase text-pacificBlue text-[14px] mr-2.5">
                  Current jackpot
                </p>
                <p className="uppercase text-pampas text-[14px]">
                  {gems[3]}/3 tickets
                </p>
              </div>
              {lootboxDataError ? (
                <button
                  type="button"
                  onClick={reloadLootboxData}
                  className="flex items-center"
                >
                  <p className="uppercase text-[24px] text-white flex items-center text-center mr-2">
                    Error
                  </p>
                  <ReloadIcon />
                </button>
              ) : (
                <p className="uppercase text-white text-[24px]">
                  {isLoadingLootboxData ? (
                    <SpinnerIcon extendedClass="w-6 h-6" />
                  ) : lootboxData.jackpotAmount ? (
                    truncateNumber(lootboxData.jackpotAmount, 6)
                  ) : (
                    0
                  )}{' '}
                  {payingTokenSym}
                </p>
              )}
            </div>
            <div className="h-2" />
            <div className="flex w-full items-center justify-center">
              <div className="flex flex-0 mr-3.5">
                <div className="z-20">
                  <Ticket isEmpty={!(gems[3] >= 1)} />
                </div>
                <div className="z-10 -ml-[30px]">
                  <Ticket isEmpty={!(gems[3] >= 2)} />
                </div>
                <div className="z-0 -ml-[30px]">
                  <Ticket isEmpty={!(gems[3] >= 3)} />
                </div>
              </div>
              <div className="flex-0">
                <Btn
                  label="Jackpot"
                  onPress={handleNavigateToJackpot}
                  type="primary"
                  size="xs"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
