import { CloseIcon } from 'assets/icons'
import { FC, useState } from 'react'
import { ToastType } from './types'

type ToastProps = {
  message: string
  id: string
  type: ToastType
  remove: (id: string) => void
}

export const Toast: FC<ToastProps> = ({ message, id, type, remove }) => {
  const [exit, setExit] = useState(false)

  const hideToast = () => {
    setExit(true)
    setTimeout(() => remove(id), 300)
  }

  return (
    <div
      className={`transform ease-in-out duration-300 ${
        exit ? 'translate-x-full' : 'translate-x-0'
      } ${
        type === ToastType.failure
          ? 'bg-pippin border-yourPink text-guardsmanRed'
          : 'bg-grannyApple border-yourRomance text-seaGreen'
      } border p-2 pr-8 m-1`}
    >
      <p className="font-mont text-[14px]">{message}</p>
      <button
        className="absolute right-1 top-[1px] origin-top-right scale-75"
        type="button"
        onClick={hideToast}
      >
        <CloseIcon color={type === ToastType.failure ? '#D60000' : '#31A361'} />
      </button>
    </div>
  )
}
