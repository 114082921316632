import {
  WithHideOnClickOutside,
  withHideOnClickOutside,
} from 'hoc/withHideOnClickOutside'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { LootboxRoutes } from 'types/routes'
import { HistoryElement } from './HistoryElement'
import { CallToAction } from './CallToAction'
import { useDataContext } from 'context/Data'
import { HistoryIcon, SpinnerIcon } from 'assets/icons'
import { useWalletContext } from 'context/Wallet'
import { ReloadDataBtn } from './ReloadDataBtn'

const NotificationIcon = () => {
  const hasNotification = true
  return (
    <div className="relative">
      {hasNotification ? (
        <div className="absolute -top-0.5 -right-0.5 w-2.5 h-2.5 rounded-full bg-dodgerBlue border-2 border-black animate-pulse" />
      ) : null}
      <HistoryIcon />
    </div>
  )
}

const HistoryBtnComponent = forwardRef<HTMLDivElement, WithHideOnClickOutside>(
  ({ isOpen, onToggleOpen }, ref) => {
    const { history, isLoadingHistory, reloadHistory, historyError } =
      useDataContext()
    const { userAddress, handleConnectWallet } = useWalletContext()
    const navigate = useNavigate()

    const navigateToHistory = () => {
      navigate(`/${LootboxRoutes.history}`)
      onToggleOpen()
    }

    const handleHistoryClick = () => {
      if (window.innerWidth > 1125) {
        onToggleOpen()
      } else {
        navigate(`/${LootboxRoutes.history}`)
      }
    }

    return (
      <div ref={ref} className="relative">
        <button
          type="button"
          onClick={handleHistoryClick}
          className="flex items-center border border-pampas/30 py-[3px] px-3"
        >
          <NotificationIcon />
          <p className="uppercase text-pampas text-[18px] ml-2.5">History</p>
        </button>
        {isOpen ? (
          <div aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
            {/* Modal body */}
            <div
              className="absolute top-10 right-0 border-[0.5px] border-white/10 flex flex-col w-80 bg-black"
              ref={ref}
            >
              {/* Header */}
              <div className="flex justify-between p-4">
                <div className="flex">
                  <p className="uppercase text-white ">Latest history</p>
                  <ReloadDataBtn reloadData={reloadHistory} />
                </div>
                <button type="button" onClick={navigateToHistory}>
                  <p className="uppercase text-white underline underline-offset-4 decoration-pampas">
                    See all
                  </p>
                </button>
              </div>

              {/* History list */}
              {!userAddress ? (
                <div className="w-full">
                  <CallToAction
                    title="Wallet isn't connected"
                    description="To see your gems connect the wallet"
                    callToActionText="connect"
                    onCallToAction={handleConnectWallet}
                  />
                </div>
              ) : isLoadingHistory ? (
                <div className="w-full flex justify-center">
                  <SpinnerIcon extendedClass="w-6 h-6" />
                </div>
              ) : historyError ? (
                <div className="w-full">
                  <CallToAction
                    title="Unable to Load Your History"
                    description="Your history couldn't be loaded this time around. Let's try hitting the refresh button and get back on course."
                    callToActionText="Reload"
                    onCallToAction={reloadHistory}
                  />
                </div>
              ) : history && !history.length ? (
                <CallToAction
                  title="No history yet"
                  description="Seems like you haven't claimed any gems yet. Buy and open lootboxes to collect and claim gems"
                  callToActionText="go to box"
                  onCallToAction={() => navigate(`/${LootboxRoutes.box}`)}
                />
              ) : history && history.length ? (
                history
                  .slice(0, 4)
                  .map((historyItem, index) => (
                    <HistoryElement {...historyItem} key={index} isReduced />
                  ))
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
)

export const HistoryFloating = withHideOnClickOutside(HistoryBtnComponent)
