import { SVGProps } from "react";

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="#C9C9C9"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333Z" />
      <path d="M3.333 10h-.666a1.333 1.333 0 0 1-1.334-1.334v-6a1.333 1.333 0 0 1 1.334-1.333h6A1.333 1.333 0 0 1 10 2.666v.667" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
