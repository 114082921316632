import { ReloadIcon } from 'assets/icons'
import { useWalletContext } from 'context/Wallet'
import { FC } from 'react'

export type ReloadData = {
  reloadData: () => Promise<void>
}

export const ReloadDataBtn: FC<ReloadData> = ({ reloadData }) => {
  const { userAddress } = useWalletContext()
  return userAddress ? (
    <button
      type="button"
      className="ml-4 disabled:opacity-30"
      disabled={!userAddress}
      onClick={reloadData}
    >
      <ReloadIcon />
    </button>
  ) : null
}
