export enum PrizeStatus {
  committed = 'COMMITTED',
  pending = 'PENDING',
}

export type NftReward = {
  value: number
  id: number
  collectionAddress: string
  tokenId: number
  contractAddress: string
  vaultAddress: string
  vaultNetwork: number
  claimedTx: string
  withdrawalTx: null
  status: PrizeStatus
}

export type Erc20Reward = {
  value: number
  tokenAddress: string
  contractAddress: string
  amount: number
  vaultAddress: string
  vaultNetwork: number
  withdrawalTx: null
  status: PrizeStatus
}
