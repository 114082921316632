import { ErrorDescription } from 'ethers'

export interface ProviderRpcError {
  message: string
  code: number | string
  data?: unknown
}

export function isProviderRpcError(error: unknown): error is ProviderRpcError {
  return (
    typeof (error as ProviderRpcError).message === 'string' &&
    (typeof (error as ProviderRpcError).code === 'number' ||
      typeof (error as ProviderRpcError).code === 'string')
  )
}

export function isErrorDescription(error: any): error is ErrorDescription {
  return (
    error &&
    typeof error === 'object' &&
    'args' in error &&
    'fragment' in error &&
    'name' in error &&
    typeof error.name === 'string' &&
    'selector' in error &&
    typeof error.selector === 'string' &&
    'signature' in error &&
    typeof error.signature === 'string'
  )
}

export enum WalletErrors {
  MetamaskNotInstalled = 'Metamask is not installed',
}

// TODO: change name
export enum ProviderError {
  UnableToGetReceipt = 'Unable to get txn receipt',
}

export enum LootboxError {
  ExternalIdAlreadyUsed = 'ExternalIdAlreadyUsed',
  AlreadyUsed = 'AlreadyUsed',
}
