import { Action } from 'types/action'

export type ToastContextScheme = {
  addToast: (type: ToastType, message: string) => void
}

export enum ToastType {
  success = 'success',
  failure = 'failure',
}

export type AddActionPayload = Omit<Toast, 'createdAt'>

export type RemoveActionPayload = Pick<Toast, 'id'>

export type ToastAction =
  | Action<'add_toast', AddActionPayload>
  | Action<'remove_toast', RemoveActionPayload>

export type Toast = {
  id: string
  type: ToastType
  createdAt: number
  message: string
}
