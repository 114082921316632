import { SVGProps } from "react";

export const AgoraTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={120}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.685 7.758h-8.261v2.077h5.523c-.496 1.896-2.337 3.227-5.334 3.227-3.706 0-6.113-2.198-6.113-5.042 0-2.884 2.454-5.083 5.948-5.083 2.171 0 4.012.928 4.862 2.24l2.336-1.15C41.254 2.03 38.586.678 35.448.678 30.49.678 26.786 3.946 26.786 8s3.658 7.321 8.803 7.321c4.839 0 8.096-2.743 8.096-6.595v-.968Zm19.883 7.563c4.79 0 8.567-3.227 8.567-7.321 0-4.094-3.776-7.322-8.568-7.322C58.8.678 55 3.906 55 8c0 4.094 3.8 7.321 8.568 7.321Zm0-2.259c-3.305 0-5.854-2.158-5.854-5.062 0-2.904 2.55-5.063 5.854-5.063 3.304 0 5.853 2.159 5.853 5.063s-2.55 5.062-5.854 5.062Zm29.68 2.092h-2.974l-3.493-5.143h-3.21V7.933h3.895c1.44 0 2.596-1.048 2.596-2.36 0-1.33-1.157-2.36-2.596-2.36H83.57V1.037h3.895c2.95 0 5.31 2.017 5.31 4.538 0 1.815-1.345 3.428-3.28 4.094l3.752 5.486Zm-78.025 0h-2.926L11.14 12.31 7.651 3.758 4.107 12.31 2.95 15.154H0L5.995 1.036h3.257l5.971 14.118Zm101.716 0h2.927l-5.971-14.118h-3.257l-5.995 14.118h2.95l1.157-2.844 3.544-8.552 3.489 8.552 1.156 2.844Z"
      fill="#fff"
    />
  </svg>
);
