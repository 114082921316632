import { useMemo, useState, FC, useEffect, useCallback } from 'react'

import { WalletContext } from './context'
import { PROJECTS } from 'utils/config/projects'
import { isProviderRpcError, WalletErrors } from 'types/errors'
import { ChildrenProp } from 'types/common'
import { useToastContext, ToastType } from 'context/Toast'
import { containsPendingRequest } from 'utils/containsPendingRequest'

export const WalletProvider: FC<ChildrenProp> = ({ children }) => {
  // the network where the main functionality of the app is
  const [appLootboxContract, setAppLootboxContract] = useState(
    Object.keys(PROJECTS)[0]
  )
  const [userAddress, setUserAddress] = useState('')

  // the metamask network where is on right now
  const [activeNetworkId, setActiveNetworkId] = useState<string | undefined>(
    undefined
  )

  const { addToast } = useToastContext()

  // get current chainId the wallet is pointing at
  useEffect(() => {
    const getActiveChainId = async () => {
      try {
        const chainId = await PROJECTS[appLootboxContract].getCurrentNetwork()
        setActiveNetworkId(chainId)
      } catch (e) {}
    }
    getActiveChainId()
  }, [appLootboxContract])

  const handleInitError = useCallback(
    (e: unknown) => {
      if (isProviderRpcError(e) && e.code === 4001) {
        // ignore user rejected
      } else {
        console.error('Error', e)
        if (
          isProviderRpcError(e) &&
          e.code === -32002 &&
          containsPendingRequest(e.message)
        ) {
          addToast(
            ToastType.failure,
            'Please review your pending request in MetaMask to take appropriate action.'
          )
        } else if (
          e instanceof Error &&
          e.message === WalletErrors.MetamaskNotInstalled
        ) {
          addToast(
            ToastType.failure,
            'To continue using the app you should have the Metamask browser extension installed'
          )
        } else if (e instanceof Error) {
          addToast(ToastType.failure, e.message)
        } else {
          addToast(ToastType.failure, JSON.stringify(e))
        }
      }
    },
    [addToast]
  )

  useEffect(() => {
    const appInit = async () => {
      try {
        await PROJECTS[appLootboxContract].init(
          (account: string) => {
            setUserAddress(account)
          },
          (chainId: string) => {
            setActiveNetworkId(chainId)
          }
        )
      } catch (e: unknown) {
        handleInitError(e)
      }
    }
    appInit()
  }, [appLootboxContract, handleInitError])

  const handleConnectWallet = useCallback(async () => {
    const targetNetwork = PROJECTS[appLootboxContract]
    if (targetNetwork) {
      try {
        await targetNetwork.handleConnect()
        setUserAddress(PROJECTS[appLootboxContract].address)
        setActiveNetworkId(PROJECTS[appLootboxContract].chainId)
      } catch (e) {
        handleInitError(e)
      }
    }
  }, [handleInitError, appLootboxContract])

  const switchNetwork = useCallback(
    (contract: string) => {
      if (PROJECTS[contract]) {
        if (
          PROJECTS[appLootboxContract].chainId !== PROJECTS[contract].chainId
        ) {
          // disonnect wallet only if a new project has a different chain id
          setUserAddress('')
        } else {
          PROJECTS[contract].address = userAddress
        }
        setAppLootboxContract(contract)
      }
    },
    [appLootboxContract, userAddress]
  )

  const context = useMemo(
    () => ({
      userAddress,
      appLootboxContract,
      activeNetworkId,
      switchNetwork,
      handleConnectWallet,
    }),
    [
      userAddress,
      appLootboxContract,
      activeNetworkId,
      switchNetwork,
      handleConnectWallet,
    ]
  )

  return (
    <WalletContext.Provider value={context}>{children}</WalletContext.Provider>
  )
}
