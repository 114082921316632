import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { WalletContextScheme } from './types'

export const WalletContext = createContext<WalletContextScheme | undefined>(
  undefined
)

WalletContext.displayName = 'WalletContext'

export const useWalletContext = createCustomContext(WalletContext)
