import { useDataContext } from 'context/Data'
import { useMemo } from 'react'

export const useEnoughForJackpot = () => {
  const { gems } = useDataContext()

  const isEnoughForJackpot = useMemo(() => {
    if (gems[3] && gems[3] >= 3) {
      return true
    }
    return false
  }, [gems])

  return {
    isEnoughForJackpot,
  }
}
