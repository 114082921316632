export const timeAgo = (timestamp: number) => {
  // Calculate the difference in milliseconds
  const now = new Date()
  const past = new Date(timestamp)
  const diff = now.getTime() - past.getTime()

  // Convert milliseconds to minutes, hours, and days
  const minutes = Math.floor(diff / 60000)
  const hours = Math.floor(diff / 3600000)
  const days = Math.floor(diff / (3600000 * 24))

  // Determine the appropriate format
  if (minutes < 60) {
    return minutes === 1 ? `1 min ago` : `${minutes} mins ago`
  } else if (hours < 24) {
    return hours === 1 ? `1 hour ago` : `${hours} hours ago`
  } else {
    return days === 1 ? `1 day ago` : `${days} days ago`
  }
}
