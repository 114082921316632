import { SVGProps } from 'react'

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#ECE8E1"
      d="m3.825 3.163 6.95 9.293L3.78 20.01h1.574l6.123-6.615 4.947 6.615h5.356l-7.34-9.815 6.51-7.033h-1.575l-5.639 6.092-4.556-6.092H3.825Zm2.315 1.16H8.6L19.466 18.85h-2.46L6.14 4.322Z"
    />
  </svg>
)
