import { ContractEventPayload, EventLog, LogDescription } from 'ethers'

export enum LootboxEvents {
  unlocked = 'Unlocked',
  revealed = 'LootBoxRevealed',
  prizeClaimed = 'PrizeClaimed',
}

export type EventHandler = (
  response: ContractEventPayload | LogDescription | EventLog
) => void
