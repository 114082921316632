import { Contract, JsonRpcSigner, Interface } from 'ethers'
import { abi } from 'utils/abi'

type ContractMethod<
  M extends
    | 'approve'
    | 'buy'
    | 'buyNative'
    | 'getBalanceInfo'
    | 'burnForRandomPrize'
    | 'burnForJackpot'
    | 'burnForAga'
    | 'acquireFree'
    | 'release',
  P
> = {
  method: M
  params: P
}

type LootboxMethods =
  | ContractMethod<'approve', { spender: string; amount: bigint }>
  | ContractMethod<'getBalanceInfo', { owner: string }>
  | ContractMethod<
      'buy',
      {
        token: string
        count: number // uint256 bigger than max JS number
        boost: number // uint16
      }
    >
  | ContractMethod<
      'buyNative',
      {
        buyNative: { value: bigint }
        count: number // uint256 bigger than max JS number
        boost: number // uint16
      }
    >
  | ContractMethod<
      'burnForRandomPrize',
      {
        rarity: number // TODO: pass bigint
      }
    >
  | ContractMethod<'burnForJackpot', {}>
  | ContractMethod<
      'burnForAga',
      {
        count: number
      }
    >
  | ContractMethod<
      'acquireFree',
      {
        user: string
        externalId: number
        expiredAt: number
        signature: [v: string, r: string, s: string]
      }
    >
  | ContractMethod<
      'release',
      {
        userAccount: string
        lootBoxIds: number[]
        nftPrizes: Array<[collectinAddress: string, tokenId: number]>
        erc20Prizes: Array<[tokenAddress: string, amount: bigint]>
        signatures: Array<[v: string, r: string, s: string]>
      }
    >

export const createContract = (
  contractAddress: string,
  signer: JsonRpcSigner
) => {
  const contractAbi = abi[contractAddress]
  if (!contractAbi) {
    throw new Error('Contract ABI is not found locally')
  }
  return {
    contract: new ExtendedContract(contractAddress, contractAbi, signer),
    abi: contractAbi,
  }
}

class ExtendedContract extends Contract {
  //@ts-expect-error call contract doesn't follow the standart
  call(body: BridgeMethod) {
    const contractMethod = this.getFunction(body.method)
    return contractMethod(...Object.values(body.params))
  }
}

export const callContractMethod = async (
  contractAddress: string,
  signer: JsonRpcSigner,
  method: LootboxMethods,
  cb?: (hash: string) => void
) => {
  const { contract, abi } = createContract(contractAddress, signer)
  try {
    const tx = await contract.call(method)
    cb && cb(tx.hash)
    await tx.wait()
    console.log(
      `Transaction ${method.method.toUpperCase()} processed succesfully`,
      tx.hash
    )
    return tx.hash
  } catch (error) {
    const abiInteract = new Interface(abi)
    console.error(`error in ${method.method}`, error)
    //@ts-expect-error data is unknown
    throw error.data ? abiInteract.parseError(error.data) : error
  }
}
