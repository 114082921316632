import { SVGProps } from 'react'

export const EmptySelectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={19} height={19} x={1} y={0.5} stroke="#ECE8E1" rx={9.5} />
  </svg>
)
