import { SVGProps } from 'react'

export const Plus1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5136_15623)">
      <path
        d="M1.83325 9H15.1666"
        stroke="#ECE8E1"
        strokeWidth={2.47273}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 15.667L8.5 2.33366"
        stroke="#ECE8E1"
        strokeWidth={2.47273}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5136_15623">
        <rect
          width={16}
          height={17}
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
