import { BrowserProvider, Eip1193Provider } from 'ethers'
import { isProviderRpcError, WalletErrors } from 'types/errors'
import { callContractMethod } from './evmOperations'
import { WithdrawNetworkClass } from './WithdrawNetworkClass'

export class ArbitrumWithdrawNetworkClass extends WithdrawNetworkClass {
  provider: BrowserProvider | undefined = undefined

  constructor(public chainId: string, public withdrawContractAddress: string) {
    super(chainId, withdrawContractAddress)
  }

  checkMetamaskExtensionInstalled<T>(
    cb: (extension: Eip1193Provider) => Promise<T>
  ) {
    if (typeof window.ethereum !== 'undefined') {
      if (!this.provider) {
        this.provider = new BrowserProvider(window.ethereum)
      }
      return cb(window.ethereum)
    } else {
      throw new Error(WalletErrors.MetamaskNotInstalled)
    }
  }

  addNetwok(extension: Eip1193Provider) {
    return extension.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0xa4b1',
          rpcUrls: ['https://arbitrum.blockpi.network/v1/rpc/public'],
          chainName: 'Arbitrum One Mainnet',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
          },
          blockExplorerUrls: ['https://arbiscan.io/'],
        },
      ],
    })
  }

  async switchToNetwork() {
    await this.checkMetamaskExtensionInstalled(async (extension) => {
      try {
        const selectedChainId = await extension.request({
          method: 'eth_chainId',
          params: [],
        })
        if (selectedChainId !== this.chainId) {
          await extension.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: this.chainId,
              },
            ],
          })
        }
      } catch (e) {
        if (isProviderRpcError(e)) {
          if (e.code === 4902) {
            await this.addNetwok(extension)
            await this.switchToNetwork()
          } else {
            throw e
          }
        } else {
          throw e
        }
      }
    })
  }

  async release(
    userAddress: string,
    lootBoxIds: number[],
    nftPrizes: Array<[collectionAddress: string, tokenId: number]>,
    erc20Prizes: Array<[tokenAddress: string, amount: bigint]>,
    signatures: Array<[v: string, r: string, s: string]>
  ) {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error('Cannot buy lootbox, because the provider is not defined')
    }
    await callContractMethod(
      this.withdrawContractAddress,
      await this.provider.getSigner(),
      {
        method: 'release',
        params: {
          userAccount: userAddress,
          lootBoxIds,
          nftPrizes,
          erc20Prizes,
          signatures,
        },
      }
    )
  }
}
