import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { DrawerContextScheme } from './types'

export const DrawerContext = createContext<DrawerContextScheme | undefined>(
  undefined
)

DrawerContext.displayName = 'DrawerContext'

export const useDrawerContext = createCustomContext(DrawerContext)
