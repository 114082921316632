import { useEffect, useMemo, useState } from 'react'

import { Lootcard } from 'components/Lootcard'
import { useDataContext } from 'context/Data'
import { CallToAction } from 'components/CallToAction'
import { useNavigate } from 'react-router-dom'
import { LootboxRoutes } from 'types/routes'
import { usePrizesFlowContext } from 'context/PrizesFlow'
import { GemsTuple } from 'context/PrizesFlow/types'
import { ToastType, useToastContext } from 'context/Toast'
import { isProviderRpcError } from 'types/errors'
import { getGemImg } from 'utils/getGemImg'
import { ResellBtn } from 'components/ResellBtn'
import { AgaTokensWidget } from 'components/AgaTokensWidget'
import { FiltersSection } from 'components/FiltersSection'
import { Btn } from 'components/Btn'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { useDrawerContext } from 'context/Drawer'
import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { ReloadDataBtn } from 'components/ReloadDataBtn'
import { SpinnerIcon } from 'assets/icons'

const GEM_TYPE_FILTER = ['all', 'magic', 'rare', 'legendary']

export const Gems = () => {
  const [gemTypeFilter, setGemTypeFilter] = useState(GEM_TYPE_FILTER[0])

  const { gems, reloadGems, isLoadingGems, gemsError } = useDataContext()
  const navigate = useNavigate()
  const { handleClaim, setGems, currentlyClaimingRarityRef, removeNewBadge } =
    usePrizesFlowContext()
  const { addToast } = useToastContext()
  const { showDrawer, hideDrawer } = useDrawerContext()
  const { userAddress, handleConnectWallet, appLootboxContract } =
    useWalletContext()

  useEffect(() => {
    removeNewBadge(0)
    removeNewBadge(1)
    removeNewBadge(2)
  }, [removeNewBadge])

  const claimPrize = async (rarity: number) => {
    setGems((prevState) => {
      const state = prevState.slice() as GemsTuple
      state[rarity] = 1
      return state
    })
    currentlyClaimingRarityRef.current = rarity
    try {
      await handleClaim(rarity)
    } catch (e) {
      if (isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
        // ignore
      } else {
        console.error('Error in claimPrize on Gems page', e)
        addToast(
          ToastType.failure,
          `Gems route, cannot claim gem prize: ${JSON.stringify(e)}`
        )
      }
    }
  }

  const hasGems = useMemo(() => {
    switch (gemTypeFilter) {
      case 'legendary': {
        return Boolean(gems[0])
      }
      case 'rare': {
        return Boolean(gems[1])
      }
      case 'magic': {
        return Boolean(gems[2])
      }
      default: {
        return Boolean(gems[0] + gems[1] + gems[2])
      }
    }
  }, [gemTypeFilter, gems])

  const handleOpenFilters = () => {
    showDrawer(
      <div className="flex flex-col w-full h-full bg-black p-5">
        <div className="flex justify-between">
          <p className="uppercase text-[24px] text-pampas">Filters</p>
          <button className="-translate-y-1" type="button" onClick={hideDrawer}>
            <CloseIcon />
          </button>
        </div>
        <div className="">
          <FiltersSection
            title="gem type"
            options={GEM_TYPE_FILTER}
            optionNames={PROJECTS[appLootboxContract].gemFilterNames}
            selected={gemTypeFilter}
            onSelect={(o) => setGemTypeFilter(o)}
          />
        </div>
        <div className="fixed bottom-5 right-5 z-[100] bg-black">
          <Btn type="primary" label="apply" onPress={hideDrawer} size="small" />
        </div>
      </div>,
      {
        direction: 'right',
        className: 'navigation-md:hidden w-full',
        size: 'w-full',
      }
    )
  }

  return (
    <div className="flex flex-col md:flex-row">
      {/* Adjusted for screen layout */}
      <aside className="text-pampas flex flex-col md:mr-16">
        <div className="flex md:-translate-y-4 mb-4 items-center">
          <p className="text-[40px] md:text-[48px] uppercase text-white">
            gems
          </p>
          <ReloadDataBtn reloadData={reloadGems} />
        </div>
        <AgaTokensWidget />
        <div className="hidden md:flex">
          <FiltersSection
            title="gem type"
            options={GEM_TYPE_FILTER}
            optionNames={PROJECTS[appLootboxContract].gemFilterNames}
            selected={gemTypeFilter}
            onSelect={(o) => setGemTypeFilter(o)}
          />
        </div>
        <div className="md:hidden my-6">
          <Btn
            onPress={handleOpenFilters}
            label="filters"
            type="primary"
            size="small"
          />
        </div>
      </aside>
      {!userAddress ? (
        <div className="w-full">
          <CallToAction
            title="Wallet isn't connected"
            description="To see your gems connect the wallet"
            callToActionText="connect"
            onCallToAction={handleConnectWallet}
          />
        </div>
      ) : gemsError ? (
        <div className="w-full">
          <CallToAction
            title="Error loading gems"
            description="Your gems couldn't be loaded this time around. Let's try hitting the refresh button and get back on course."
            callToActionText="reload"
            onCallToAction={reloadGems}
          />
        </div>
      ) : isLoadingGems ? (
        <div className="w-full flex justify-center">
          <SpinnerIcon extendedClass="w-6 h-6" />
        </div>
      ) : !hasGems ? (
        <div className="w-full">
          <CallToAction
            title={`${
              gemTypeFilter === GEM_TYPE_FILTER[0]
                ? 'you don’t have any gems'
                : `you don’t have any '${gemTypeFilter}' gems`
            } `}
            description="Buy and open lootbox to collect Gems"
            callToActionText="go to box"
            onCallToAction={() => navigate(`/${LootboxRoutes.box}`)}
          />
        </div>
      ) : (
        <section className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-6 md:mt-0 mb-24">
          {gemTypeFilter === 'all' || gemTypeFilter === 'legendary'
            ? Array(gems[0])
                .fill(0)
                .map((r, idx) => (
                  <Lootcard
                    key={idx}
                    imgSrc={getGemImg(0)}
                    cardTitle={PROJECTS[appLootboxContract].gemFilterNames[3]}
                    secondaryBtns={() => <ResellBtn gemRarity={0} />}
                    mainBtnLabel="Claim"
                    onMainBtnClick={() => claimPrize(0)}
                    mainBtnType="primary"
                  />
                ))
            : null}
          {gemTypeFilter === 'all' || gemTypeFilter === 'rare'
            ? Array(gems[1])
                .fill(1)
                .map((r, idx) => (
                  <Lootcard
                    key={idx}
                    imgSrc={getGemImg(1)}
                    cardTitle={PROJECTS[appLootboxContract].gemFilterNames[2]}
                    secondaryBtns={() => <ResellBtn gemRarity={1} />}
                    mainBtnLabel="Claim"
                    onMainBtnClick={() => claimPrize(1)}
                    mainBtnType="primary"
                  />
                ))
            : null}
          {gemTypeFilter === 'all' || gemTypeFilter === 'magic'
            ? Array(gems[2])
                .fill(2)
                .map((r, idx) => (
                  <Lootcard
                    key={idx}
                    imgSrc={getGemImg(2)}
                    cardTitle={PROJECTS[appLootboxContract].gemFilterNames[1]}
                    secondaryBtns={() => <ResellBtn gemRarity={2} />}
                    mainBtnLabel="Claim"
                    onMainBtnClick={() => claimPrize(2)}
                    mainBtnType="primary"
                  />
                ))
            : null}
        </section>
      )}
    </div>
  )
}
