import { AgoraTextIcon, CloseIcon } from 'assets/icons'
import { useDrawerContext } from 'context/Drawer'

export const Footer = () => {
  const { showDrawer, hideDrawer } = useDrawerContext()

  const handleOpenPrivacyPolicy = () => {
    showDrawer(
      <div className="h-full bg-black p-16 overflow-y-scroll">
        <div className="flex justify-between mb-10">
          <AgoraTextIcon />
          <button className="-translate-y-1" type="button" onClick={hideDrawer}>
            <CloseIcon />
          </button>
        </div>
        <div>
          <p className="uppercase text-[32px] text-pampas leading-9">
            RESPONSIBLE GAMING
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Last updated: November 22th, 2023
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            Please carefully read this information for your own benefit. Agora
            DEX is operated by Agora Ltd has its office registered at Hansa Bank
            Building, 1st Floor, Landsome Road, The Valley, Anguilla.
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Interpretation and Definitions
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            <span className="underline">Interpretation </span>
            <br /> Words with an initial capital letter have meanings defined
            under the following conditions. The following definitions have the
            same meaning whether they appear in the singular or plural. <br />{' '}
            <br />
            <span className="underline">Definitions </span>
            <br />
            For the purposes of these Terms and Conditions:
            <br /> <span className="font-anton">Account</span> means a wallet
            address created on your Metamask by your care to access our Service
            or parts of our Service.
            <br />
            <span className="font-anton">Company</span> (referred to as either
            "the Company," "We," "Us," or "Our" in this Agreement) refers to
            Agora Ltd.
            <br />
            <span className="font-anton">Service</span> refers to the Loot box
            application.
            <br />
            <span className="font-anton">Application</span> refers to
            lootboox.agoradex.io
            <br />
            <span className="font-anton">You</span> means the individual
            accessing or using the Service, or the company, or other legal
            entity on behalf of which such an individual is accessing or using
            the Service, as applicable.
          </p>
          <div className="h-6" />
          <p className="uppercase text-[32px] text-pampas leading-9">
            Responsible Gambling and Self-Exclusion
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Responsible Gambling
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            Gambling is intended as entertainment, fun, and excitement for the
            majority of our users. However, we recognize that for some users,
            gambling may have negative side effects. In medical science,
            pathological gambling has been recognized for many years as a
            serious sickness.
            <br />
            Since our first day, we have considered this problem and have
            endeavored to help where we can. Under “Responsible Gambling,” we
            understand multiple measures through which a gambling provider can
            help reduce the possibility of negative side effects. In case they
            already appear, we also try to take active steps against them.
            <br />
            <br />
            The most important tools against negative side effects from gambling
            are knowledge and education about the risks of gambling to support
            our users’ self-control.
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Information and Contact
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            Our Support will assist you via email at all times with no
            additional costs for you:
            <br />
            email:
            <span className="uppercase font-anton"> contact@agoradex.io</span>
            <br />
            Our Support will not give out any information about you without your
            consent to anyone else.
            <br />
            <br />
            Additionally, you can take a{' '}
            <a
              href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              self-assessment{' '}
            </a>{' '}
            if you are already addicted to gambling <br />
            <br />
            You can also find
            <a
              href="https://www.begambleaware.org/safer-gambling"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              additional information
            </a>{' '}
            about gambling addictions
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Helpful Hints for Responsible Gambling at lootbox.agoradex.io
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            We recommend that you consider the following hints before gambling
            to ensure gambling stays fun for you and with no negative side
            effects:
            <br />
            <br />
            Set yourself a deposit limit:
            <br />
            Before you start gambling, think about how much you can afford to
            gamble according to your financial situation. Play with amounts that
            are for fun and for your entertainment.
            <br />
            <br />
            Do not try to win back a loss at every cost:
            <br /> Avoid taking too huge risks to win back what you lost before
            at any cost. Play for entertainment and not to earn money.
            <br />
            <br />
            Set yourself a time limit:
            <br /> Set a time limit and do not break it. Keep in mind that
            gambling should stay in balance with your other hobbies and not be
            your only hobby.
            <br />
            <br />
            Play smart: It is smarter not to play when you are extremely
            stressed, depressed, or under too much pressure. Also, do not play
            when you are under the influence of medications, drugs, or alcohol.
            <br />
            <br />
            Take breaks:
            <br /> Take breaks when you become tired or cannot concentrate
            anymore.
            <br />
            <br />
            Only one account:
            <br /> To make it easier to have an overview of how much time and
            money you spend on gambling, it is highly advised not to create more
            than one account per person.
            <br />
            <br />
          </p>
          <p className="uppercase text-[20px] text-pampas leading-6">
            Minor Protection
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            To use our Service, you have to be 18 years or older. To avoid
            abuse, keep your login data safe from any minors near you. We
            recommend a filter program to prevent minors, especially children,
            from accessing any content on the internet that is not healthy for
            them.
            <br />
            <br />
            For parents, we can recommend a list of
            <a
              href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {' '}
              internet filters{' '}
            </a>
            to support them in keeping their children from any content that is
            not appropriate for them
          </p>
          <div className="h-6" />
          <p className="uppercase text-[20px] text-pampas leading-6">
            Self-Exclusion
          </p>
          <div className="h-6" />
          <p className="font-mont text-[16px] text-pampas">
            If you are diagnosed with a gambling addiction or trying to stay
            away from gambling for a different reason, we want to assist you in
            staying away from anything that does nothing good for you.
            “Self-Exclusion” means that you exclude yourself, out of your own
            choice, from all gambling services. This exclusion cannot be undone
            for a set amount of time. If you wish to self-exclude yourself from
            gambling, please message our support and give them a time span of 6
            months and 5 years. They will also explain all future steps and what
            we need from you. - email: contact@agoradex.io
          </p>
        </div>
      </div>,
      {
        direction: 'right',
        className: 'w-full',
        size: 'w-full',
      }
    )
  }

  return (
    <div className="mt-auto flex p-5 items-center justify-center w-full">
      <button type="button" onClick={handleOpenPrivacyPolicy}>
        <p className="text-[12px] text-white uppercase">terms & privacy</p>
      </button>
    </div>
  )
}
