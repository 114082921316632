import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Btn } from 'components/Btn'
import { useDataContext } from 'context/Data'
import { ReloadIcon, SpinnerIcon } from 'assets/icons'
import { Ticket } from 'components/Ticket'
import { Polygon, PROJECTS } from 'utils/config/projects'
import { useWalletContext } from 'context/Wallet'
import { useOverlayContext } from 'context/Overlay'
import { LootboxRoutes } from 'types/routes'
import { truncateNumber } from 'utils/truncateNumber'
import { usePrizesFlowContext } from 'context/PrizesFlow'
import { ToastType, useToastContext } from 'context/Toast'
import { isProviderRpcError } from 'types/errors'
import { useEnoughForJackpot } from 'hooks/useEnoughForJackpot'

export const Jackpot = () => {
  const [isConfirmation, setConfirmation] = useState(false)
  const {
    lootboxData,
    payingTokenSym,
    isLoadingLootboxData,
    lootboxDataError,
    reloadLootboxData,
    lootboxStatusError,
    reloadLootboxStatus,
    gems,
    setGems,
    isLoadingGems,
    reloadGems,
  } = useDataContext()
  const { appLootboxContract, userAddress, handleConnectWallet } =
    useWalletContext()
  const { handleLoadingProcess, startAnimation } = useOverlayContext()
  const navigate = useNavigate()
  const { removeNewBadge } = usePrizesFlowContext()
  const { addToast } = useToastContext()
  const { isEnoughForJackpot } = useEnoughForJackpot()

  useEffect(() => {
    removeNewBadge(3)
  }, [removeNewBadge])

  const isSoldOut = useMemo(
    () => PROJECTS[appLootboxContract].isSoldOut,
    [appLootboxContract]
  )

  const handleJackpotPlay = async () => {
    try {
      await handleLoadingProcess(
        () => PROJECTS[appLootboxContract].handleBurnForJackpot(),
        'Entering the Jackpot!',
        'Exchanging your golden tickets for a chance at the jackpot. Hold on as we seal your entry... Fortune could be just a moment away!',
        'Transaction submitted to blockchain. Please wait for processing'
      )
      startAnimation('golden_ticket')
      setConfirmation(true)
      await reloadGems()
    } catch (e) {
      if (isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
        // ignore
      } else {
        console.error('Error occured in handleJackpotPlay', e)
        addToast(ToastType.failure, 'Error burning tickets for a jackpot')
      }
    }
  }

  const handleNavigateToBox = () => {
    navigate(`/${LootboxRoutes.box}`)
    setConfirmation(false)
  }

  const handlePlayAgain = () => {
    setConfirmation(false)
  }

  const handleGetAgaForGolden = async () => {
    try {
      await handleLoadingProcess(
        () => PROJECTS[appLootboxContract].handleBurnForAga(gems[3]),
        'Exchanging for AGA',
        'Exchanging your golden tickets for AGA tokens',
        'Transaction submitted to blockchain. Please wait for processing'
      )
      startAnimation('golden_ticket')
      setConfirmation(true)
      setGems((prevState) => {
        const state = prevState.slice() as [
          legendaryGem: number,
          rareGem: number,
          magicGem: number,
          empty: number
        ]
        state[3] = 0
        return state
      })
    } catch (e) {
      if (isProviderRpcError(e) && e.code === 'ACTION_REJECTED') {
        // ignore
      } else {
        console.error('Error occured in handleGetAgaForGolden', e)
        addToast(ToastType.failure, 'Error exchanging golden tickets for AGA')
      }
    }
  }

  if (isConfirmation) {
    return (
      <div className="flex flex-col items-center">
        <p className="uppercase text-[72px] text-white text-center leading-none">
          Thanks
        </p>
        <div className="h-5" />
        <p className="font-mont text-[18px] text-white/80 text-center">
          {appLootboxContract.toLowerCase()
            ? 'AGA tokens have been added to your wallet'
            : 'Your jackpot has been added to your wallet'}
        </p>
        <div className="h-16" />
        <div className="flex-0">
          <Btn
            label="back to box"
            type="primary"
            onPress={handleNavigateToBox}
          />
          <div className="h-6" />
          {gems[3] >= 3 ? (
            <Btn label="play again" type="primary" onPress={handlePlayAgain} />
          ) : null}
        </div>
      </div>
    )
  }

  if (
    appLootboxContract.toLowerCase() ===
    Polygon.lootboxContractAddress.toLowerCase()
  ) {
    return (
      <div className="flex flex-col items-center">
        <p className="uppercase text-[36px] md:text-[48px] text-center text-white leading-none">
          Receive 250 AGA for each golden ticket
        </p>
        <>
          <div className="h-16" />
          {!isEnoughForJackpot ? (
            <div className="flex z-0">
              <div className="z-20">
                <Ticket isEmpty={!(gems[3] >= 1)} size="big" />
              </div>
              <div className="z-10 -ml-[30px] md:ml-6">
                <Ticket isEmpty={!(gems[3] >= 2)} size="big" />
              </div>
              <div className="z-0 -ml-[30px] md:ml-6">
                <Ticket isEmpty={!(gems[3] >= 3)} size="big" />
              </div>
            </div>
          ) : (
            <div className="relative">
              <div className="absolute top-0 scale-[0.75] -translate-y-9 z-[-1] opacity-40">
                <Ticket isEmpty={false} size="big" />
              </div>
              <div className="absolute top-0 scale-[0.9] -translate-y-4 z-0 opacity-50">
                <Ticket isEmpty={false} size="big" />
              </div>
              <div className="z-20">
                <Ticket isEmpty={false} size="big" />
              </div>
            </div>
          )}
          <div className="h-6" />
          {!userAddress ? (
            <>
              <p className="font-anton uppercase text-[16px] text-white text-center leading-none">
                Connect your wallet to see your tickets
              </p>
            </>
          ) : (
            <p className="font-anton uppercase text-white text-[16px] leading-none">
              You have {gems[3]} {gems[3] === 1 ? 'ticket' : 'tickets'}. You
              will receive {gems[3] * 250} AGA
            </p>
          )}
          <div className="h-10 md:h-16" />
          <div className="md:w-auto">
            {!userAddress ? (
              <Btn
                label="Connect"
                onPress={handleConnectWallet}
                type="primary"
              />
            ) : (
              <Btn
                label="Collect"
                onPress={handleGetAgaForGolden}
                type="primary"
                disabled={isLoadingGems || gems[3] === 0}
              />
            )}
          </div>
        </>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-center">
        <>
          <div className="relative">
            <div className="bg-gem bg-cover absolute left-0 w-8 h-8 bg-lightgray/50 z-[-1] -translate-x-10 -translate-y-1 bg-blend-screen" />

            {lootboxDataError || lootboxStatusError ? (
              <button
                type="button"
                onClick={
                  lootboxDataError ? reloadLootboxData : reloadLootboxStatus
                }
                className="flex items-center"
              >
                <p className="uppercase text-[24px] text-white flex items-center text-center mr-2">
                  Error
                </p>
                <ReloadIcon />
              </button>
            ) : (
              <p className="uppercase text-[24px] text-pacificBlue flex items-center leading-none">
                Jackpot{' '}
                {isLoadingLootboxData ? (
                  <SpinnerIcon extendedClass="w-6 h-6" />
                ) : lootboxData.jackpotAmount ? (
                  truncateNumber(lootboxData.jackpotAmount)
                ) : (
                  0
                )}{' '}
                {payingTokenSym}
              </p>
            )}
            <div className="bg-gem bg-cover absolute right-0 top-0 w-8 h-8 bg-lightgray/50 z-[-1] -translate-y-1 translate-x-10 bg-blend-screen" />
          </div>
          <div className="h-5" />
          <p className="uppercase text-[36px] md:text-[48px] text-center text-white leading-none">
            Your share{' '}
            {isLoadingLootboxData ? (
              <SpinnerIcon extendedClass="w-6 h-6" />
            ) : lootboxData.jackpotAmount ? (
              truncateNumber(parseFloat(lootboxData.jackpotAmount) * 0.2, 6)
            ) : (
              0
            )}{' '}
            {payingTokenSym}
          </p>
        </>

        <>
          <div className="h-16" />
          {!isEnoughForJackpot ? (
            <div className="flex z-0">
              <div className="z-20">
                <Ticket isEmpty={!(gems[3] >= 1)} size="big" />
              </div>
              <div className="z-10 -ml-[30px] md:ml-6">
                <Ticket isEmpty={!(gems[3] >= 2)} size="big" />
              </div>
              <div className="z-0 -ml-[30px] md:ml-6">
                <Ticket isEmpty={!(gems[3] >= 3)} size="big" />
              </div>
            </div>
          ) : (
            <div className="relative">
              <div className="absolute top-0 scale-[0.75] -translate-y-9 z-[-1] opacity-40">
                <Ticket isEmpty={false} size="big" />
              </div>
              <div className="absolute top-0 scale-[0.9] -translate-y-4 z-0 opacity-50">
                <Ticket isEmpty={false} size="big" />
              </div>
              <div className="z-20">
                <Ticket isEmpty={false} size="big" />
              </div>
            </div>
          )}
          <div className="h-6" />
          {!userAddress ? (
            <>
              <p className="font-anton uppercase text-[16px] text-white text-center leading-none">
                Connect your wallet to see your tickets
              </p>
            </>
          ) : isEnoughForJackpot ? (
            <p className="font-anton uppercase text-white text-[16px] leading-none">
              You have {gems[3]}/3 tickets
            </p>
          ) : (
            <>
              <p className="font-anton uppercase text-white text-[16px] leading-none">
                {gems[3]}/3 ticket to unlock
              </p>
              <div className="h-16" />
              <p className="font-mont text-[18px] text-white/80 max-w-[380px] text-center">
                You need at least three golden tickets to claim jackpot
              </p>
            </>
          )}
          <div className="h-10 md:h-16" />
          <div className="md:w-auto">
            {!userAddress ? (
              <Btn
                label="Connect"
                onPress={handleConnectWallet}
                type="primary"
              />
            ) : (
              <Btn
                label="Collect"
                onPress={handleJackpotPlay}
                type="primary"
                disabled={gems[3] < 3 || isLoadingGems || !!lootboxDataError}
              />
            )}
          </div>
          <div className="h-8" />
          <p className="font-mont text-[12px] text-pampas w-full md:w-1/2 text-center">
            * The displayed share value in the jackpot may not be guaranteed, as
            it is subject to change due to the dynamic nature of blockchain
            technology.
          </p>
        </>
      </div>
    )
  }
}
