import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { DataContextScheme } from './types'

export const DataContext = createContext<DataContextScheme | undefined>(
  undefined
)

DataContext.displayName = 'DataContext'

export const useDataContext = createCustomContext(DataContext)
