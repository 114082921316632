import { CloseIcon, SpinnerIcon } from 'assets/icons'
import { Medusa, AgaGif } from 'assets/images'
import { useDrawerContext } from 'context/Drawer'
import { useDataContext } from 'context/Data'
import { removeDecimal } from 'utils/removeDecimals'
import { truncateNumber } from 'utils/truncateNumber'
import { ReloadDataBtn } from './ReloadDataBtn'

const Utilities = () => {
  const { showDrawer, hideDrawer } = useDrawerContext()

  const onShowUtilities = () => {
    showDrawer(
      <div className="bg-pacificBlue h-full p-20 overflow-y-scroll">
        <button
          className="absolute top-5 right-5 -translate-y-1"
          type="button"
          onClick={hideDrawer}
        >
          <CloseIcon />
        </button>
        <p className="uppercase text-[48px] text-pampas leading-none">
          AGA token utilities
        </p>
        <div className="h-6" />
        <p className="font-mont text-[16px] text-pampas font-medium">
          Become part of an exclusive community with AGA tokens and discover the
          power of true digital ownership. As an AGA token holder, you're
          entitled to a wealth of privileges in the Agora ecosystem:
          <br />
          <br />
          <span className="font-bold">Staking for Yield:</span> Lock in your AGA
          tokens and watch your digital wealth grow. Benefit from a share of the
          loot box revenues as a reward for your commitment.
          <br />
          <br />
          <span className="font-bold">Staking for Exclusivity:</span> Obtain
          access to limited edition loot boxes with premium, rare collections
          not available to the average user.
          <br />
          <br />
          <span className="font-bold">Discount on Purchases:</span> Enjoy
          exclusive discounts on loot box purchases, making each transaction
          more rewarding.
          <br />
          <br />
          <span className="font-bold">Buy-Back & Burn:</span> The Agora team
          periodically uses a portion of its profits or treasury to purchase AGA
          tokens from the open market. After the buy-back, the acquired tokens
          are 'burned', which means they are permanently removed from
          circulation.
          <br />
          <br />
          <span className="font-bold">Governance Rights:</span> Have a voice in
          the future of Agora. Your stake in AGA tokens means a stake in
          decision-making processes.
          <br />
          <br />
          Embrace the future with AGA tokens and its limited supply – where fun,
          fairness, and community lead the way to prosperity. More information:
          article link (TBD)
        </p>
        <div className="h-6" />
        <button
          type="button"
          onClick={hideDrawer}
          className="p-[13px] border border-white/30"
        >
          <p className="uppercase text-pampas leading-none">close</p>
        </button>
      </div>,
      {
        direction: 'right',
        size: '',
        className: 'w-full md:w-6/12',
      }
    )
  }

  return (
    <button type="button" onClick={onShowUtilities}>
      <p className="uppercase text-pampas text-[14px] underline underline-offset-4">
        utilities
      </p>
    </button>
  )
}
export const AgaTokensWidget = () => {
  const {
    agaTokenBalance,
    agaTokenBalanceError,
    reloadAgaTokenBalance,
    isLoadingAgaTokenBalance,
  } = useDataContext()

  return (
    <>
      <div className="hidden text-white p-5 md:flex flex-col justify-between border border-[1px] border-white/20 mb-2 w-72 h-[152px] relative">
        <img
          src={Medusa}
          alt="medusa"
          className="absolute top-0 left-0 w-full h-full z-[-1]"
        />
        <div className="flex justify-between">
          <img
            src={AgaGif}
            alt="Token Logo"
            className="w-14 h-14 -translate-x-2 -translate-y-2"
          />
          <div className="flex items-start">
            <p className="uppercase text-pacificBlue text-[14px]">aga tokens</p>
            <div className="w-2.5" />
            <Utilities />
          </div>
        </div>
        <p className="font-mont text-[12px]">Auto added to your wallet</p>
        {isLoadingAgaTokenBalance ? (
          <SpinnerIcon extendedClass="w-6 h-6" />
        ) : (
          <div className="flex">
            {agaTokenBalanceError ? (
              <p className="uppercase text-[14px] text-pampas">
                Error loading AGA tokens
              </p>
            ) : (
              <p className="text-[20px]">
                {removeDecimal(agaTokenBalance)} AGA
                {agaTokenBalance !== '0' ? (
                  <span className="text-pacificBlue ml-2.5">
                    ${truncateNumber(Number(agaTokenBalance) * 0.08)}
                  </span>
                ) : null}
              </p>
            )}
            <ReloadDataBtn reloadData={reloadAgaTokenBalance} />
          </div>
        )}
      </div>
      <div className="flex md:hidden text-white border border-[1px] border-white/30 p-4 justify-between relative">
        <img
          src={Medusa}
          alt="medusa"
          className="absolute top-0 right-4 w-1/2 h-full z-[-1]"
        />
        <div className="flex">
          <img src={AgaGif} alt="Token Logo" className="w-[40px] h-[40px]" />
          <div className="flex flex-col ml-3">
            <p className="font-mont text-[12px]">Auto added to your wallet</p>
            {isLoadingAgaTokenBalance ? (
              <SpinnerIcon extendedClass="w-6 h-6" />
            ) : (
              <div className="flex">
                {agaTokenBalanceError ? (
                  <p className="uppercase text-[14px] text-pampas">
                    Error loading AGA tokens
                  </p>
                ) : (
                  <p className="uppercase">
                    {removeDecimal(agaTokenBalance)} AGA
                    {agaTokenBalance !== '0' ? (
                      <span className="text-pacificBlue ml-1.5">
                        ${truncateNumber(Number(agaTokenBalance) * 0.08)}
                      </span>
                    ) : null}
                  </p>
                )}
                <ReloadDataBtn reloadData={reloadAgaTokenBalance} />
              </div>
            )}
          </div>
        </div>

        <div className="self-end">
          <Utilities />
        </div>
      </div>
    </>
  )
}
