import { FC, useEffect, useMemo, useState } from 'react'
import { NetworkSpeedContext } from './context'

type NetworkSpeedProviderProps = {
  children: JSX.Element | JSX.Element[]
}

export const NetworkSpeedProvider: FC<NetworkSpeedProviderProps> = ({
  children,
}) => {
  const [networkSpeed, setNetworkSpeed] = useState('4g')

  useEffect(() => {
    // Check if the API is available
    // @ts-expect-error: connection property is't typed
    if (navigator.connection) {
      // @ts-expect-error: connection property is't typed
      setNetworkSpeed(navigator.connection.effectiveType)

      // Add an event listener for changes in the connection
      const handleNetworkChange = () => {
        // @ts-expect-error: connection property is't typed
        setNetworkSpeed(navigator.connection.effectiveType)
      }

      // @ts-expect-error: connection property is't typed
      navigator.connection.addEventListener('change', handleNetworkChange)

      // Cleanup the listener on unmount
      return () => {
        // @ts-expect-error: connection property is't typed
        navigator.connection.removeEventListener('change', handleNetworkChange)
      }
    }
  }, [])

  const context = useMemo(
    () => ({
      networkSpeed,
    }),
    [networkSpeed]
  )

  return (
    <NetworkSpeedContext.Provider value={context}>
      {children}
    </NetworkSpeedContext.Provider>
  )
}
