import {
  AgaTilted,
  AgoraTokenSmall,
  ApeToken,
  ApeTokenSmall,
  Everai,
  FavToken,
  Minotaur,
  Nft,
  SwissborgToken,
  Trainer1222,
  Trainer296,
  Trainer2975,
  Trainer4541,
  Trainer46,
  Trainer671,
  Trainer6866,
  Trainer988,
  UsdcToken,
} from 'assets/images'
import { ZeroAddress } from 'ethers'

export type NftInfo = {
  imgSrc: string | undefined
  name: string | undefined
  type: string | undefined
}

export type Erc20Info = {
  sym: string
  decimals: number
  icon: string
  imgSrc: string
}

const UsdcTokenSmall = 'https://bscscan.com/token/images/centre-usdc_28.png'

const nftRecord: {
  [key: number]: { [key: string]: Record<number, NftInfo> }
} = {
  80001: {
    ['0x509356d5e3a58302dE6FD1D97ebE88Fe3069bd5B'.toLowerCase()]: {
      0: {
        imgSrc:
          'https://i.pinimg.com/564x/56/27/ea/5627ea13f97055cd12d555339c4dd726.jpg',
        name: 'Test col',
        type: 'ERC721',
      },
    },
  },
  56: {
    //oxyaorigen alpha keys
    ['0xb313f7a18b3aeca184e3c3d2349117173359472a'.toLowerCase()]: {
      6: {
        imgSrc:
          'https://altura-marketplace-1.s3.us-east-2.amazonaws.com/01acb1d7-598f-49ec-a77b-53322b828fa8.png',
        name: 'Agora Key',
        type: 'ERC1155',
      },
      7: {
        imgSrc:
          'https://altura-marketplace-1.s3.us-east-2.amazonaws.com/0e16b28f-af92-4b73-a2ce-502245d30a17.png',
        name: 'Super Agora Key',
        type: 'ERC1155',
      },
    },
    ['0xe35296f398b22b5487eca1fd74d5b57a9d2e406d'.toLowerCase()]: {
      0: {
        imgSrc:
          'https://ipfs.io/ipfs/QmRRcNpXM8uaRWhFf7qiArU6ZnsDvUKUhvbJRoDtqchHiW/Mystery_Ball.jpg',
        name: 'FAV Mystery Ball',
        type: 'ERC721',
      },
    },
  },
  1: {
    ['0xabcb4a84891afcce56fd428fd1fcff6c038e5781'.toLowerCase()]: {
      0: {
        name: 'Agora Minotaur',
        imgSrc: Minotaur,
        type: 'ERC721',
      },
    },
    ['0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'.toLowerCase()]: {
      54050: {
        imgSrc:
          'https://assets.otherside.xyz/otherdeeds/7509c010df1618d9bbbf5a4c1a929865364efdc0622da87cf3926ae53e9df11f.jpg',
        name: '54050',
        type: 'ERC721',
      },
    },
    ['0x59325733eb952a92e069c87f0a6168b29e80627f'.toLowerCase()]: {
      4304: {
        imgSrc:
          'https://ipfs.io/ipfs/QmaVbwW6CCNzjCjSTMyPnwU8Lurr8Qw385dkwqzAbusawo/1486',
        name: '8460',
        type: 'ERC721',
      },
    },
    ['0x9a38dec0590abc8c883d72e52391090e948ddf12'.toLowerCase()]: {
      1607: {
        imgSrc: Everai,
        name: 'Duo #1607',
        type: 'ERC721',
      },
    },
    ['0xd774557b647330c91bf44cfeab205095f7e6c367'.toLowerCase()]: {
      18042: {
        imgSrc:
          'https://i.seadn.io/s/raw/files/4f5402da834e008a974d15b1de32c2e9.png',
        name: 'Nakamigos #18042',
        type: 'ERC721',
      },
    },
    ['0xffc000000002607d22478fdd69a05c0d0effc57d'.toLowerCase()]: {
      354: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/354.png',
        name: 'Genesis Baker #354',
        type: 'ERC721',
      },
      150: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/150.png',
        name: 'Genesis Baker #150',
        type: 'ERC721',
      },
      246: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/246.png',
        name: 'Genesis Baker #246',
        type: 'ERC721',
      },
      32: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/32.png',
        name: 'Genesis Baker #32',
        type: 'ERC721',
      },
      852: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/852.png',
        name: 'Genesis Baker #852',
        type: 'ERC721',
      },
      712: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/712.png',
        name: 'Genesis Baker #712',
        type: 'ERC721',
      },
      722: {
        imgSrc: 'https://cdn.thebakery.gg/mainnet-bakers/722.png',
        name: 'Genesis Baker #722',
        type: 'ERC721',
      },
    },
    ['0x142e03367ede17cd851477a4287d1f35676e6dc2'.toLowerCase()]: {
      6329: {
        imgSrc: 'https://genesis-api.keungz.com/ygpz/image/human.png',
        name: 'YogaPetz #6329',
        type: 'ERC721',
      },
      7749: {
        imgSrc: 'https://genesis-api.keungz.com/ygpz/image/human.png',
        name: 'YogaPetz #7749',
        type: 'ERC721',
      },
      7158: {
        imgSrc: 'https://genesis-api.keungz.com/ygpz/image/human.png',
        name: 'YogaPetz #7158',
        type: 'ERC721',
      },
      9453: {
        imgSrc: 'https://genesis-api.keungz.com/ygpz/image/human.png',
        name: 'YogaPetz #9453',
        type: 'ERC721',
      },
      8582: {
        imgSrc: 'https://genesis-api.keungz.com/ygpz/image/human.png',
        name: 'YogaPetz #8582',
        type: 'ERC721',
      },
    },
    ['0x8a3749936e723325c6b645a0901470cd9e790b94'.toLowerCase()]: {
      2975: {
        imgSrc: Trainer2975,
        name: 'Trainer #2975',
        type: 'ERC721',
      },
      671: {
        imgSrc: Trainer671,
        name: 'Trainer #671',
        type: 'ERC721',
      },
      6866: {
        imgSrc: Trainer6866,
        name: 'Trainer #6866',
        type: 'ERC721',
      },
      46: {
        imgSrc: Trainer46,
        name: 'Trainer #46',
        type: 'ERC721',
      },
      988: {
        imgSrc: Trainer988,
        name: 'Trainer #988',
        type: 'ERC721',
      },
      6462: {
        imgSrc: Trainer988,
        name: 'Trainer #6462',
        type: 'ERC721',
      },
      296: {
        imgSrc: Trainer296,
        name: 'Trainer #296',
        type: 'ERC721',
      },
      1222: {
        imgSrc: Trainer1222,
        name: 'Trainer #1222',
        type: 'ERC721',
      },
      4541: {
        imgSrc: Trainer4541,
        name: 'Trainer #4541',
        type: 'ERC721',
      },
    },
  },
}

const erc20Record: Record<number, Record<string, Erc20Info>> = {
  // Mumbai Polygon
  80001: {
    ['0x0535b25366b043d549E8eb73Dd1eAFA756923AdB'.toLowerCase()]: {
      sym: 'tUSDC',
      decimals: 18,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
    [ZeroAddress]: {
      sym: 'MATIC',
      decimals: 18,
      icon: '',
      imgSrc: '',
    },
  },
  // Polygon
  137: {
    [ZeroAddress]: {
      sym: 'MATIC',
      decimals: 18,
      icon: '',
      imgSrc: '',
    },
    ['0xA910B6d8F431410438586b61A262418601bE59Af'.toLowerCase()]: {
      sym: 'AGA',
      decimals: 18,
      icon: AgoraTokenSmall,
      imgSrc: AgaTilted,
    },
  },
  // Arbitrum
  42161: {
    ['0xcbc18a85003633eba44aecf54c3b3a999c51f58c'.toLowerCase()]: {
      sym: 'AGA',
      decimals: 18,
      icon: AgoraTokenSmall,
      imgSrc: AgaTilted,
    },
    ['0xaf88d065e77c8cc2239327c5edb3a432268e5831'.toLowerCase()]: {
      sym: 'USDC',
      decimals: 6,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
    [ZeroAddress]: {
      sym: 'ETH',
      decimals: 18,
      icon: '',
      imgSrc: '',
    },
  },
  // Binance
  56: {
    ['0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'.toLowerCase()]: {
      sym: 'USDC',
      decimals: 18,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
    ['0x1a076e4633fa139d7b908b88326de603fbe8c199'.toLowerCase()]: {
      sym: 'FAV',
      decimals: 18,
      icon: 'https://bscscan.com/token/images/alphaverse_32.png',
      imgSrc: FavToken,
    },
  },
  // Ethereum
  1: {
    ['0x64d0f55cd8c7133a9d7102b13987235f486f2224'.toLowerCase()]: {
      sym: 'BORG',
      decimals: 18,
      icon: 'https://etherscan.io/token/images/swissborg_32.png',
      imgSrc: SwissborgToken,
    },
    ['0x4d224452801aced8b2f0aebe155379bb5d594381'.toLowerCase()]: {
      sym: 'APE',
      decimals: 18,
      icon: ApeTokenSmall,
      imgSrc: ApeToken,
    },
  },
  // Binance testnet
  97: {
    ['0x0e603bcd1a60b816fbc84036c462704e87b3cfd6'.toLowerCase()]: {
      sym: 'USDC',
      decimals: 18,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
  },
  // Sepolia
  11155111: {
    ['0x04929df1e805620264df41c60d19fa8050e400aa'.toLowerCase()]: {
      sym: 'tUSDC',
      decimals: 18,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
  },
  // Arbitrum Sepolia
  421614: {
    ['0x374ce0cc0c70954707c157c247b8245ea0f96a69'.toLowerCase()]: {
      sym: 'tUSDC',
      decimals: 18,
      icon: UsdcTokenSmall,
      imgSrc: UsdcToken,
    },
    ['0x55068a3ac0f8e5cab37538918c21252c517020e5'.toLowerCase()]: {
      sym: 'AGA',
      decimals: 18,
      icon: AgoraTokenSmall,
      imgSrc: AgaTilted,
    },
    [ZeroAddress]: {
      sym: 'ETH',
      decimals: 18,
      icon: '',
      imgSrc: '',
    },
  },
}

export const getNftMetadata = (
  contractAddress: string,
  tokenId: number,
  chainId: number
) => {
  const zeroId = nftRecord[chainId]?.[contractAddress.toLowerCase()]?.[0]
  const record = zeroId
    ? zeroId
    : nftRecord[chainId]?.[contractAddress.toLowerCase()]?.[tokenId]
  return (
    record || {
      imgSrc: Nft,
      name: '',
      type: 'ERC721',
    }
  )
}

export const getErc20Metadata = (contractAddress: string, chainId: number) => {
  return erc20Record[chainId]?.[contractAddress.toLowerCase()]
}
