import { FC, forwardRef, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Cloudinary } from '@cloudinary/url-gen'
import { AdvancedVideo } from '@cloudinary/react'

import { LootboxRoutes } from 'types/routes'
import {
  AgoraTextIcon,
  CloseIcon,
  HamburgerMenuIcon,
  ThreeDotsIcon,
} from 'assets/icons'

import { NavigationText } from './NavigationText'
import { useDrawerContext } from 'context/Drawer'
import { ConnectWalletWidget } from './ConnectWalletWidget'
import { usePrizesFlowContext } from 'context/PrizesFlow'
import { PROJECTS } from 'utils/config/projects'
import { useWalletContext } from 'context/Wallet'
import {
  WithHideOnClickOutside,
  withHideOnClickOutside,
} from 'hoc/withHideOnClickOutside'
import { HistoryFloating } from './HistoryFloating'

type NavigationItemType = {
  label: string
  to: string
  hasBadge?: boolean
  hasChevron?: boolean
}

const NavigationItem: FC<NavigationItemType> = ({
  to,
  label,
  hasBadge = false,
  hasChevron = false,
}) => {
  return (
    <NavLink to={to} className={({ isActive }) => `cursor-pointer`}>
      <NavigationText
        children={label}
        hasBadge={hasBadge}
        hasChevron={hasChevron}
      />
    </NavLink>
  )
}
const Market = () => {
  const { appLootboxContract } = useWalletContext()
  return (
    <a
      href={`${PROJECTS[appLootboxContract].openseaUrl}/assets/${PROJECTS[appLootboxContract].openseaNetwork}/${PROJECTS[appLootboxContract].lootboxContractAddress}`}
      target="_blank"
      rel="noreferrer"
    >
      <NavigationText children="secondary market" />
    </a>
  )
}

const Tutorial = () => {
  const cld = useMemo(
    () => new Cloudinary({ cloud: { cloudName: 'dgjq39fgn' } }),
    []
  )
  const { showDrawer, hideDrawer } = useDrawerContext()

  const showTutoial = () => {
    showDrawer(
      <div className="flex flex-col w-full h-full bg-black p-5 navigation-md:p-[72px] items-center relative">
        <button
          className="absolute top-5 right-5 -translate-y-1"
          type="button"
          onClick={hideDrawer}
        >
          <CloseIcon />
        </button>
        <p className="uppercase text-[24px] text-pampas p-6">How it works ?</p>
        <div className="flex items-center justify-center md:w-1/2 md:aspect-video h-full">
          <AdvancedVideo
            cldVid={cld.video('tutorial').quality('auto')}
            autoPlay
            muted
            preload="auto"
            controls
          />
        </div>
      </div>,
      {
        direction: 'top',
        className: 'w-full h-full',
        size: 'w-full',
      }
    )
  }
  return (
    <button type="button" onClick={showTutoial}>
      <NavigationText children="How it works?" />
    </button>
  )
}

const MobileMenu = () => {
  const { showDrawer, hideDrawer } = useDrawerContext()
  const { newBadges } = usePrizesFlowContext()
  const { appLootboxContract } = useWalletContext()

  const onShowDrawer = () => {
    showDrawer(
      <div className="flex flex-col w-full h-full bg-black p-5 justify-between">
        <div>
          <div className="flex justify-between">
            <AgoraTextIcon />
            <button
              className="-translate-y-1"
              type="button"
              onClick={hideDrawer}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="h-3" />
          <div className="flex flex-col">
            <NavigationItem
              to={`/${LootboxRoutes.projects}`}
              label={PROJECTS[appLootboxContract].projectName}
              hasChevron
            />
            <div className="my-3.5 w-full h-[1.5px] bg-pampas" />
            <NavigationItem to={`/${LootboxRoutes.box}`} label="Box" />
            <NavigationItem
              to={`/${LootboxRoutes.gems}`}
              label="Gems"
              hasBadge={newBadges[0] || newBadges[1] || newBadges[2]}
            />
            <NavigationItem
              to={`/${LootboxRoutes.vault}`}
              label="Vault"
              hasBadge={newBadges[4]}
            />
            <NavigationItem
              to={`/${LootboxRoutes.jackpot}`}
              label="Jackpot"
              hasBadge={newBadges[3]}
            />
            <HistoryFloating />
            <div className="my-3.5 w-full h-[1px] bg-pampas/50" />
            <Market />
            <Tutorial />
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex mt-6">
            <div className="relative">
              <img
                src={PROJECTS[appLootboxContract].chainLogo}
                alt={PROJECTS[appLootboxContract].projectName}
                className="w-5 h-5"
              />
              {process.env.NODE_ENV === 'development' ? (
                <p className="absolute -bottom-2 -right-3 uppercase text-pampas text-[8px]">
                  test
                </p>
              ) : null}
            </div>
            <ConnectWalletWidget />
          </div>
        </div>
      </div>,
      {
        direction: 'right',
        className: 'navigation-md:hidden w-full',
        size: 'w-full',
      }
    )
  }

  return (
    <>
      <div className="flex navigation-md:hidden">
        <button type="button" onClick={onShowDrawer}>
          <HamburgerMenuIcon />
        </button>
      </div>
    </>
  )
}

const ExtraMenuComponent = forwardRef<HTMLDivElement, WithHideOnClickOutside>(
  ({ isOpen, onToggleOpen }, ref) => {
    return (
      <div ref={ref} className="relative">
        <button type="button" onClick={onToggleOpen}>
          <ThreeDotsIcon />
        </button>
        {isOpen ? (
          <div className="absolute top-10 left-1/2 -translate-x-1/2 w-40 border-[0.5px] border-white/10 flex flex-col items-center bg-black">
            <Market />
            <div className="pl-4 w-full h-[0.5px] bg-white/10" />
            <Tutorial />
          </div>
        ) : null}
      </div>
    )
  }
)

const ExtraMenu = withHideOnClickOutside(ExtraMenuComponent)

export const Navigation = () => {
  const { newBadges } = usePrizesFlowContext()
  const { appLootboxContract } = useWalletContext()

  return (
    <>
      <div className="hidden navigation-md:flex items-center z-10">
        <NavigationItem
          to={`/${LootboxRoutes.projects}`}
          label={PROJECTS[appLootboxContract].projectName}
          hasChevron
        />
        <div className="h-5 w-[1px] bg-white ml-3 mr-4" />
        <NavigationItem to={`/${LootboxRoutes.box}`} label="Box" />
        <div className="pl-4" />
        <NavigationItem
          to={`/${LootboxRoutes.gems}`}
          label="Gems"
          hasBadge={newBadges[0] || newBadges[1] || newBadges[2]}
        />
        <div className="pl-4" />
        <NavigationItem
          to={`/${LootboxRoutes.vault}`}
          label="Vault"
          hasBadge={newBadges[4]}
        />
        <div className="pl-4" />
        <NavigationItem
          to={`/${LootboxRoutes.jackpot}`}
          label="Jackpot"
          hasBadge={newBadges[3]}
        />
        <div className="pl-4" />
        <ExtraMenu />
      </div>
      <MobileMenu />
    </>
  )
}
