import { GemsInfo, LootboxData } from 'context/Data'
import {
  BrowserProvider,
  Contract,
  Eip1193Provider,
  formatEther,
  formatUnits,
  Interface,
  LogDescription,
  parseEther,
  parseUnits,
  toBeHex,
  WebSocketProvider,
  ZeroAddress,
  zeroPadValue,
} from 'ethers'
import { isProviderRpcError, ProviderError, WalletErrors } from 'types/errors'
import { EventHandler, LootboxEvents } from 'types/events'
import { abi } from 'utils/abi'
import { getErc20Metadata } from 'utils/getMetadata'
import { preciseMultiply } from 'utils/preciseMultiply'
import { sleep } from 'utils/sleep'
import { callContractMethod, createContract } from './evmOperations'

const getExponentialBackoffDelay = (retryCount: number) => {
  const delay = Math.pow(2, retryCount) * 1000 // Exponential backoff formula
  return Math.min(delay, 30000) // Max delay of 30 seconds
}

const MAX_RETRIES = 5
export abstract class NetworkClass {
  public address = ''
  provider: BrowserProvider | undefined = undefined
  webSocketProvider: WebSocketProvider | undefined = undefined
  contract: Contract | undefined = undefined
  retries = 0
  payingTokenDecimal = 18

  /*There can be different parameters for test and mainnet networks therefore passing all these poperties to the constructor*/
  constructor(
    public chainId: string,
    public name: string,
    public lootboxContractAddress: string,
    public openseaUrl: string,
    public openseaNetwork: string,
    public rpc: string,
    public payingTokenAddress: string,
    public projectName: string,
    public projectDescription: string,
    public imgSquare: string,
    public chainLogo: string,
    public gemFilterNames: [string, string, string, string],
    public isSoldOut: boolean,
    public soldOutMessage: string,
    public isUpcoming: boolean
  ) {
    this.payingTokenDecimal =
      getErc20Metadata(payingTokenAddress, parseInt(chainId))?.decimals ||
      this.payingTokenDecimal
    // set browser provider
    if (typeof window.ethereum !== 'undefined') {
      if (!this.provider) {
        this.provider = new BrowserProvider(window.ethereum)
      }
    }

    this.connectProvider()
  }

  configureWebSocket(rpc: string) {
    const ws = new WebSocket(rpc)
    ws.onerror = (err: Event) => {
      console.info('WebSocket error: %s', err)
    }

    ws.onclose = (e: CloseEvent) => {
      console.info('WebSocket closed', e)
      this.attemptReconnect()
    }
    return ws
  }

  connectProvider() {
    this.retries = 0
    this.webSocketProvider = new WebSocketProvider(
      () => this.configureWebSocket(this.rpc),
      {
        chainId: parseInt(this.chainId, 16),
        name: this.name,
      }
    )

    // setup the contract
    this.contract = new Contract(
      this.lootboxContractAddress,
      abi[this.lootboxContractAddress],
      this.webSocketProvider
    )
  }

  /**
   * NOTE: this fn is only used for dev purposes;
   * it's used to test "closed" socket connection
   */
  destroyConnection() {
    if (this.webSocketProvider) {
      console.log('closing connection')
      this.webSocketProvider.websocket.close()
    }
  }

  attemptReconnect() {
    if (this.retries < MAX_RETRIES) {
      setTimeout(() => {
        console.log(`Attempting to reconnect... Attempt #${this.retries + 1}`)
        this.retries++
        this.connectProvider() // Attempt to reconnect
      }, getExponentialBackoffDelay(this.retries))
    } else {
      console.error('Max retries reached. Unable to reconnect')
    }
  }

  public async addEventListeners(listeners: {
    [LootboxEvents.revealed]: EventHandler
    [LootboxEvents.unlocked]: EventHandler
    [LootboxEvents.prizeClaimed]: EventHandler
  }) {
    if (this.contract) {
      // v1 doesn't have PrizeClaimed event; adding subscription to PrizeClaimed event only if it exists on the contract

      const abiInterface = new Interface(abi[this.lootboxContractAddress])

      /**
       * Iterate over events, see if the event available on the contract and subscribe it,
       * filtering each event by user address if it's present in the event output signature
       */
      for (const listener in listeners) {
        const eventTopic = abiInterface.getEvent(listener)?.topicHash
        if (eventTopic) {
          this.contract = await this.contract.on(
            listener === LootboxEvents.revealed
              ? [eventTopic, zeroPadValue(this.address, 32)]
              : listener === LootboxEvents.unlocked
              ? [eventTopic]
              : [eventTopic, null, zeroPadValue(this.address, 32)],
            listeners[listener as LootboxEvents]
          )
        }
      }
    } else {
      // TODO: think how to handle it
      console.error(
        'setupEventListeners contract is undefined for',
        this.lootboxContractAddress
      )
    }
  }

  public async removeEventListeners() {
    if (this.contract) {
      this.contract = await this.contract.removeAllListeners()
    } else {
      // TODO: think how to handle it
      console.error('removeEventListeners contract is undefined')
    }
  }

  public queryPrizeClaimedEvent(
    eventName: LootboxEvents.prizeClaimed,
    fromBlock: number,
    toBlock: number,
    topicId: string
  ) {
    if (this.contract) {
      const abiInterface = new Interface(abi[this.lootboxContractAddress])
      const eventTopic = abiInterface.getEvent(eventName)?.topicHash
      if (eventTopic) {
        // filter events by eventName and user address
        return this.contract?.queryFilter(
          [eventTopic, toBeHex(topicId, 32), zeroPadValue(this.address, 32)],
          fromBlock,
          toBlock
        )
      }
    } else {
      // TODO: think how to handle it
      console.error('queryFilter contract is undefined')
    }
  }

  public queryRevealedEvent(
    eventName: LootboxEvents.revealed,
    fromBlock: number,
    toBlock: number
  ) {
    if (this.contract) {
      const abiInterface = new Interface(abi[this.lootboxContractAddress])
      const eventTopic = abiInterface.getEvent(eventName)?.topicHash
      if (eventTopic) {
        // filter events by eventName and user address
        return this.contract?.queryFilter(
          [eventTopic, zeroPadValue(this.address, 32)],
          fromBlock,
          toBlock
        )
      }
    } else {
      // TODO: think how to handle it
      console.error('queryFilter contract is undefined')
    }
  }

  public checkMetamaskExtensionInstalled<T>(
    cb: (extension: Eip1193Provider) => Promise<T>
  ) {
    if (typeof window.ethereum !== 'undefined') {
      return cb(window.ethereum)
    } else {
      throw new Error(WalletErrors.MetamaskNotInstalled)
    }
  }

  abstract addNetwok(extension: Eip1193Provider): Promise<void>

  async getCurrentNetwork() {
    if (typeof window.ethereum !== 'undefined') {
      const selectedChainId = await window.ethereum.request({
        method: 'eth_chainId',
        params: [],
      })
      return selectedChainId
    } else {
      return undefined
    }
  }

  async init(
    updateAccountAddress: (address: string) => void,
    updateChainId: (chainId: string) => void
  ) {
    await this.checkMetamaskExtensionInstalled(
      async (extension: Eip1193Provider) => {
        this.listenToAccountChange(extension, updateAccountAddress)
        await this.getPreviouslyConnectedAccount(
          extension,
          updateAccountAddress
        )
        this.listenToChainChange(extension, updateChainId)
      }
    )
  }

  async getPreviouslyConnectedAccount(
    extension: Eip1193Provider,
    cb: (account: string) => void
  ) {
    // Check what chain is currently selected
    const chainId = await extension.request({
      method: 'eth_chainId',
    })
    // If the chain is not Seppolia, suggest user to switch to Sepolia
    if (chainId !== this.chainId) {
      return
    }
    // If user agreed to switch a chain get his connected account
    const accounts = await extension.request({
      method: 'eth_accounts',
      params: [
        {
          chainId: this.chainId,
        },
      ],
    })
    if (accounts.length > 0) {
      this.address = accounts[0]
      cb(this.address)
    }
    return
  }

  listenToAccountChange(
    extension: Eip1193Provider,
    cb: (address: string) => void
  ) {
    //@ts-expect-error 'on' doesn't exist
    extension.on('accountsChanged', (accounts: string[]) => {
      if (accounts.length > 0) {
        this.address = accounts[0]
        cb(this.address)
      }
    })
  }

  listenToChainChange(extension: Eip1193Provider, cb: (chain: string) => void) {
    //@ts-expect-error 'on' doesn't exist
    extension.on('chainChanged', (id: string) => {
      cb(id)
    })
  }

  async switchToNetwork() {
    await this.checkMetamaskExtensionInstalled(async (extension) => {
      try {
        await this.addNetwok(extension)
        const selectedChainId = await extension.request({
          method: 'eth_chainId',
          params: [],
        })
        if (selectedChainId !== this.chainId) {
          await extension.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: this.chainId,
              },
            ],
          })
        }
      } catch (e) {
        if (isProviderRpcError(e)) {
          if (e.code === 4902) {
            await this.addNetwok(extension)
            await this.switchToNetwork()
          } else {
            throw e
          }
        } else {
          throw e
        }
      }
    })
  }

  async handleConnect() {
    await this.switchToNetwork()
    return this.checkMetamaskExtensionInstalled(async (extension) => {
      const accounts = await extension.request({
        method: 'eth_requestAccounts',
      })
      this.address = accounts[0]
    })
  }

  async handleGetTokenIdorClaimedEvent(txnHash: string): Promise<
    | bigint
    | {
        prize: LogDescription
      }
  > {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error(
        'Cannot burn for jackpot, because the provider is not defined'
      )
    }
    let receipt,
      retryAttempts = 3

    while (!receipt && retryAttempts > 0) {
      try {
        receipt = await this.provider.getTransactionReceipt(txnHash)
      } catch (e) {
        console.error('error getting receipts', e)
        // TODO: make sure that when there is network issue error i throw the error
        retryAttempts--
        await sleep(1000)
      }
    }

    if (!receipt || retryAttempts <= 0) {
      throw new Error(`${ProviderError.UnableToGetReceipt} for txn ${txnHash}`)
    }

    if (receipt !== null && receipt.status && receipt.status === 1) {
      const { logs } = receipt

      const { abi } = createContract(
        this.lootboxContractAddress,
        await this.provider.getSigner()
      )
      const inter = new Interface(abi)

      let canClaim: 'prize' | undefined = undefined
      const relevantEvents = logs.filter((l) => {
        try {
          const eventName = inter.getEventName(l.topics[0])
          if (eventName === 'PrizeClaimed') {
            canClaim = 'prize'
            return true
          } else if (eventName === 'Locked') {
            return true
          } else return false
        } catch (e) {
          return false
        }
      })

      if (!relevantEvents.length) {
        throw new Error(
          `${ProviderError.UnableToGetReceipt} for txn ${txnHash}`
        )
      }
      // @ts-expect-error parseLog accepts obj
      const log = inter.parseLog(relevantEvents[0])
      return canClaim ? { [canClaim]: log } : log?.args[0]
    } else {
      throw new Error(`${ProviderError.UnableToGetReceipt} for txn ${txnHash}`)
    }
  }

  async handleCheckAllowance(
    gwAddress: string,
    tokenAddress: string,
    amount: bigint
  ) {
    if (!this.provider) {
      throw new Error('Cannot get balance because the provider is not defined')
    }
    const signer = await this.provider.getSigner()
    const { contract: tokenContract } = createContract(tokenAddress, signer)
    const allowance = await tokenContract.allowance(this.address, gwAddress)
    if (allowance < amount) {
      await callContractMethod(tokenAddress, signer, {
        method: 'approve',
        params: {
          spender: gwAddress,
          amount,
        },
      })
    }
  }

  async handleBuyLootbox(
    tokenAddress: string,
    lootboxPrice: string,
    lootboxCount: number,
    boost: number
  ) {
    await this.switchToNetwork()
    /**
     * If user buys a ticket for Eth, there is not need in
     * allowance check as it's not part of Erc20 standard, moreover
     * the method to buy is 'buyNative'
     */

    if (tokenAddress === ZeroAddress) {
      await callContractMethod(
        this.lootboxContractAddress,
        // @ts-expect-error
        await this.provider?.getSigner(),
        {
          method: 'buyNative',
          params: {
            count: lootboxCount,
            boost,
            buyNative: {
              value: parseEther(
                preciseMultiply(
                  Number(lootboxPrice),
                  lootboxCount * boost
                ).toString()
              ),
            },
          },
        }
      )
    } else {
      // 1. call approve
      await this.handleCheckAllowance(
        this.lootboxContractAddress,
        tokenAddress,
        parseUnits(
          (lootboxCount * parseInt(lootboxPrice) * boost).toString(),
          this.payingTokenDecimal
        )
      )

      // 2. Buy lootbox
      await callContractMethod(
        this.lootboxContractAddress,
        // @ts-expect-error
        await this.provider.getSigner(),
        {
          method: 'buy',
          params: {
            token: tokenAddress,
            count: lootboxCount,
            boost,
          },
        }
      )
    }
  }

  async handleUseFreeTicket(
    externalId: number,
    expiredAt: number,
    signature: [v: string, r: string, s: string]
  ) {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error(
        'Cannot burn for jackpot, because the provider is not defined'
      )
    }

    await this.switchToNetwork()

    await callContractMethod(
      this.lootboxContractAddress,
      await this.provider.getSigner(),
      {
        method: 'acquireFree',
        params: {
          user: this.address,
          externalId,
          expiredAt,
          signature,
        },
      }
    )
  }

  async handleGetGems() {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error('Cannot buy lootbox, because the provider is not defined')
    }
    await this.switchToNetwork()
    const signer = await this.provider.getSigner()
    const { contract: tokenContract } = createContract(
      this.lootboxContractAddress,
      signer
    )
    const gems: GemsInfo = await tokenContract.getBalanceInfo(this.address)
    return gems
  }

  async handleBurnForJackpot() {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error(
        'Cannot burn for jackpot, because the provider is not defined'
      )
    }

    await this.switchToNetwork()

    await callContractMethod(
      this.lootboxContractAddress,
      await this.provider.getSigner(),
      {
        method: 'burnForJackpot',
        params: {},
      }
    )
  }

  async handleBurnForRandomPrize(rarity: number) {
    if (!this.provider) {
      // TODO: add errors enum
      throw new Error(
        'Cannot burn for NFT, because the provider is not defined'
      )
    }

    await this.switchToNetwork()

    const txnHash = await callContractMethod(
      this.lootboxContractAddress,
      await this.provider.getSigner(),
      {
        method: 'burnForRandomPrize',
        params: {
          rarity,
        },
      }
    )
    return txnHash
  }

  async handleGetLootboxData(): Promise<LootboxData> {
    return this.checkMetamaskExtensionInstalled(async () => {
      const { contract: lootboxContract } = createContract(
        this.lootboxContractAddress,
        // @ts-expect-error
        await this.provider?.getSigner()
      )
      const lootboxData = await lootboxContract.getTokenInfo()
      return {
        payingToken: lootboxData[0][0],
        payingAmount: formatUnits(lootboxData[0][1], this.payingTokenDecimal),
        jackpotAmount: formatUnits(lootboxData[0][2], this.payingTokenDecimal),
      }
    })
  }

  async handleGetBalance(contract: string) {
    if (!this.provider) {
      throw new Error('Cannot get balance because the provider is not defined')
    }
    if (contract === ZeroAddress || contract === '0x0') {
      const ethBalance = await this.provider.getBalance(this.address)
      return formatEther(ethBalance)
    } else {
      const { contract: erc20Contract } = createContract(
        contract,
        await this.provider.getSigner()
      )
      const balance = await erc20Contract.balanceOf(this.address)
      return formatUnits(balance, this.payingTokenDecimal)
    }
  }

  async handleBurnForAga(count: number) {
    if (!this.provider) {
      throw new Error('Cannot get balance because the provider is not defined')
    }
    const { contract: lootboxContract } = createContract(
      this.lootboxContractAddress,
      await this.provider?.getSigner()
    )
    return lootboxContract.burnForAga(count)
  }
}
