import { SVGProps } from 'react'

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#ECE8E1"
      fillRule="evenodd"
      d="M9.298 11.402a.422.422 0 0 1-.596 0L4.4 7.102a.422.422 0 0 1 0-.597l.2-.2a.422.422 0 0 1 .596 0L9 10.11l3.804-3.804a.422.422 0 0 1 .596 0l.2.199a.422.422 0 0 1 0 .596l-4.302 4.301Z"
      clipRule="evenodd"
    />
  </svg>
)
