import { SpinnerIcon } from 'assets/icons'
import { useDataContext } from 'context/Data'
import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'

const TOTAL = 4000

const ProgressBar = () => {
  const {
    nrTicketsSold,
    isLoadingLootboxStatus,
    lootboxStatusError,
    reloadLootboxStatus,
  } = useDataContext()
  // Tailwind CSS classes for styling
  const percentage = (nrTicketsSold / TOTAL) * 100

  const progressBarContainerStyles = `bg-gray-200 overflow-hidden h-3 relative`
  const fillerStyles = `bg-pacificBlue	h-full transition-width duration-500 ease-in-out`
  const labelContainerStyles = `absolute bottom-0 -mb-6 w-full flex justify-between items-center px-1 text-pampas`
  const labelStyles = `font-mont text-[12px] font-bold leading-none`

  if (isLoadingLootboxStatus) {
    return <SpinnerIcon extendedClass="w-5 h-5" />
  } else {
    return (
      <div className="w-full relative">
        {/* The progress bar */}
        <div className={progressBarContainerStyles}>
          <div style={{ width: `${percentage}%` }} className={fillerStyles} />
        </div>
        <p
          className={`absolute -right-11 top-0 ${labelStyles} text-pampas`}
        >{`${TOTAL}`}</p>

        {/* The labels */}
        <div className={labelContainerStyles}>
          {/* Calculate the position for the opened label to move with the filled part */}
          <div
            style={{ width: lootboxStatusError ? '' : `${percentage + 3}%` }}
            className="flex justify-end"
          >
            {lootboxStatusError ? (
              <button onClick={reloadLootboxStatus}>
                <p
                  className={`${labelStyles} text-pacificBlue w-full uppercase`}
                >
                  error - reload
                </p>
              </button>
            ) : (
              <span className={`${labelStyles} text-pacificBlue`}>
                {nrTicketsSold}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export const LootPrizes = () => {
  const { appLootboxContract } = useWalletContext()

  return (
    <div className="flex flex-col w-[264px]">
      <div className="text-white p-4 border border-pampas/20 text-center">
        <p className="uppercase text-pacificBlue text-[32px]">+ $100 000</p>
        <p className="uppercase text-[24px] text-white">PRIZE POOL</p>
      </div>
      <div className="flex flex-col justify-between">
        <p className="font-mont text-[12px] font-light text-pampas border-b border-white mt-3 pb-1.5">
          1st Prize:{' '}
          <span className="font-medium">5x Yogapetz NFT (+$1 800)</span>
        </p>
        <p className="font-mont text-[12px] font-light text-pampas border-b border-white mt-3 pb-1.5">
          2nd Prize:{' '}
          <span className="font-medium">7x Genesis Bakers NFT (+$1 300)</span>
        </p>
        <p className="font-mont text-[12px] font-light text-pampas  mt-3 pb-1.5">
          3rd Prize:{' '}
          <span className="font-medium">
            9x Pixelmon Trainers NFT (+$1 100)
          </span>
        </p>
      </div>
      <p className="font-mont text-pampas text-[12px] mt-5 font-bold">
        Min Prize = $20 (250 AGA)
      </p>
      <div className="p-4 border border-pampas/20 text-center mt-14 mb-2">
        <p className="uppercase text-pacificBlue text-[32px]">4 000</p>
        <p className="uppercase text-[24px] text-white">total lootboxes</p>
      </div>
      {!PROJECTS[appLootboxContract].isSoldOut ? <ProgressBar /> : null}
    </div>
  )
}
