import { ArbitrumSepoliaWithdrawNetworkClass } from 'utils/blockchainNetworks/ArbitrumSepoliaWithdrawNetworkClass'
import { ArbitrumWithdrawNetworkClass } from 'utils/blockchainNetworks/ArbitrumWithdawNetworkClass'
import { EthereumWithdrawNetworkClass } from 'utils/blockchainNetworks/EthereumWithdrawNetworkClass'
import { SepoliaWithdrawNetworkClass } from 'utils/blockchainNetworks/SepoliaWithdrawNetworkClass'
import { WithdrawNetworkClass } from 'utils/blockchainNetworks/WithdrawNetworkClass'

export const WITHDRAW_CONTRACTS: {
  [key: string]: { [key: string]: WithdrawNetworkClass }
} = {
  '1': {
    // oxyorigin lootbox on binance
    '0x7fdFa0b9152c0C0606b77F71fC7EFbDFE731C792':
      new EthereumWithdrawNetworkClass(
        '0x1',
        '0xb55876a7Bb3bC9BdFd03B282FfE9337de29e7175'
      ),
    // swissborg lootbox v2
    '0xE933b6612A585B76E723FF52BecA5622c38a8176':
      new EthereumWithdrawNetworkClass(
        '0x1',
        '0x0907741D97989844B9b2C69ae8fE98B4f4Ab09A5'
      ),
    // swissborg lootbox v1
    '0xaDB5f6Ef85A88cDAbcb2DE3e795F97A0C7d4b000':
      new EthereumWithdrawNetworkClass(
        '0x1',
        '0x7333D5017bA333D390065681996eF46b0927bdA4'
      ),
    // swissborg lootbox v4
    '0x65CA21a83aF05776D42F34d9d518e161E65dd293':
      new EthereumWithdrawNetworkClass(
        '0x1',
        '0xc0F4a4CC1Da6F3A1b64957069D3e65CC5Bad4C10'
      ),
  },
  '11155111': {
    '0x329cF0853470294b64d9FAC27b55c2901666140F':
      new SepoliaWithdrawNetworkClass(
        '0xaa36a7',
        '0xD1988477a196BcaAce93861DD89F143923347929'
      ),
    '0xa69837bf9596dB254b91C80615dcf174083f91B1':
      new SepoliaWithdrawNetworkClass(
        '0xaa36a7',
        '0x6704d2dd5f3EF689C7DF719A2f16d36849B4CCdF'
      ),
    '0x587202899C17505c4b2C189d0F17Dd17a09d8AdB':
      new SepoliaWithdrawNetworkClass(
        '0xaa36a7',
        '0x3aDE358C18F23a7f9EF9d1bF2cf968b70dc0ABc7'
      ),
  },
  '421614': {
    '0x329cF0853470294b64d9FAC27b55c2901666140F':
      new ArbitrumSepoliaWithdrawNetworkClass(
        '0x66eee',
        '0x2d85D15ccA69ED1EfFAcCD76dB2409262Dfa7D80'
      ),
  },
  '42161': {
    '0x7fdFa0b9152c0C0606b77F71fC7EFbDFE731C792':
      new ArbitrumWithdrawNetworkClass(
        '0xa4b1',
        '0x592e83EB31091A816E46d3b04e9AaceEC8E3677c'
      ),
  },
}
