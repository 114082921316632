import { ChevronIcon } from 'assets/icons'
import { FC } from 'react'
type NavigationTextType = {
  children: string
  hasChevron?: boolean
  hasBadge?: boolean
  extendedTextClass?: string
}

export const NavigationText: FC<NavigationTextType> = ({
  children,
  hasChevron = false,
  hasBadge = false,
  extendedTextClass = 'text-[18px]',
}) => {
  return (
    <div className="flex items-center justify-between py-[8px] ">
      <p
        className={`font-anton uppercase text-pampas mr-3 md:mr-1 ${extendedTextClass}`}
      >
        {children}
      </p>
      {hasBadge ? (
        <p className="border border-pacificBlue px-1 uppercase text-[12px] text-pacificBlue">
          New
        </p>
      ) : null}
      {hasChevron ? (
        <>
          <div className="w-0.5" />
          <ChevronIcon />
        </>
      ) : null}
    </div>
  )
}
