import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { FC } from 'react'

type ResellBtnProps = {
  gemRarity: number
}

export const ResellBtn: FC<ResellBtnProps> = ({ gemRarity }) => {
  const { appLootboxContract } = useWalletContext()

  const handleResellClick = async () => {
    const gems = await PROJECTS[appLootboxContract].handleGetGems()
    let gemId: number | undefined = undefined
    if (gemRarity === 3) {
      gemId = gems[2]
    } else {
      gemId = gems[5][gemRarity]
    }

    // Construct the URL to navigate to
    const url = `${PROJECTS[appLootboxContract].openseaUrl}/assets/${PROJECTS[appLootboxContract].openseaNetwork}/${PROJECTS[appLootboxContract].lootboxContractAddress}/${gemId}`

    // Use window.open for external links
    window.open(url, '_blank')
  }

  return (
    <button
      type="button"
      onClick={handleResellClick}
      className="w-full px-6 h-[35px] border border-pampas/20 flex items-center justify-center"
    >
      <p className="text-white uppercase">Resell</p>
    </button>
  )
}
