import { FC, useMemo, MouseEvent } from 'react'

type CommonProps = {
  type: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  size?: 'big' | 'small' | 'xs'
  onPress: (e: MouseEvent<HTMLElement>) => void
  disabled?: boolean
}
type WithLabel = {
  label: string
  labelComponent?: never
}
type WithLabelComponent = {
  label?: never
  labelComponent: () => JSX.Element
}

export type BtnProps = CommonProps & (WithLabel | WithLabelComponent)

const BTN_CLASS = 'disabled:opacity-40 disabled:pointer-events-none'
const TEXT_CLASS = 'uppercase text-pampas leading-none text-[16px]'

export const Btn: FC<BtnProps> = ({
  label,
  labelComponent,
  onPress,
  type,
  disabled,
  size = 'big',
}) => {
  const classConfig = useMemo(() => {
    switch (type) {
      case 'primary': {
        return {
          btn: `${BTN_CLASS} ${
            size === 'xs' ? 'h-8' : size === 'small' ? 'h-10' : 'h-12'
          } bg-pacificBlue ${size === 'xs' ? 'px-2' : 'px-[17px] w-full'}`,
          text: `${TEXT_CLASS} text-pampas`,
        }
      }
      case 'secondary': {
        return {
          btn: `${BTN_CLASS} ${
            size === 'small' ? 'h-10' : 'h-12'
          } border-[1px] border-pampas/30 px-[17px] w-full`,
          text: `${TEXT_CLASS} text-pampas`,
        }
      }
      case 'tertiary': {
        return {
          btn: `${BTN_CLASS} px-6 w-full`,
          text: `${TEXT_CLASS} text-pacificBlue`,
        }
      }
      case 'quaternary': {
        return {
          btn: `${BTN_CLASS} px-6 w-full`,
          text: `${TEXT_CLASS} pampas`,
        }
      }
    }
  }, [type, size])

  return (
    <button
      disabled={disabled}
      onClick={onPress}
      className={`${classConfig.btn}`}
    >
      {label ? (
        <p className={`${classConfig.text}`}>{label}</p>
      ) : labelComponent ? (
        labelComponent()
      ) : null}
    </button>
  )
}
