import { SoundOffIcon, SoundOnIcon } from 'assets/icons'
import { useOverlayContext } from 'context/Overlay'

export const SoundBtn = () => {
  const { isSoundOn, toggleSoundOn } = useOverlayContext()
  return (
    <button
      type="button"
      className="flex items-center justify-center w-auto h-8 border border-pampas/20 p-2 z-[70]"
      onClick={toggleSoundOn}
    >
      <p className="uppercase text-[16px] text-pampas mr-2">
        {isSoundOn ? 'on' : 'off'}
      </p>
      {isSoundOn ? <SoundOnIcon /> : <SoundOffIcon />}
    </button>
  )
}
