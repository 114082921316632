import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { OverlayContextScheme } from './types'

export const OverlayContext = createContext<OverlayContextScheme | undefined>(
  undefined
)

OverlayContext.displayName = 'OverlayContext'

export const useOverlayContext = createCustomContext(OverlayContext)
