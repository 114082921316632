import { useCallback, useEffect, useState } from 'react'
import { EventLog } from 'ethers'

import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { LootboxEvents } from 'types/events'
import { isEventLog } from 'types/guard'

export const usePrizeClaimedEventBackup = () => {
  const [startBlock, setStartBlock] = useState<number | undefined>(undefined)
  const [pendingEvent, setPendingEvent] = useState<EventLog | null>(null)
  const [tokenId, setTokenId] = useState<string | undefined>(undefined)

  const { appLootboxContract } = useWalletContext()

  // Setting tokenId will spin up the whole process of polling for PrizeClaimed event
  const startWaitingForPrizeClaimedEvent = useCallback((tokenId: string) => {
    setTokenId(tokenId)
  }, [])

  // Resetting tokenId will reset the whole process of polling for PrizeClaimed event
  const stopWaitingForPrizeClaimedEvent = useCallback(() => {
    setTokenId(undefined)
  }, [])

  // once tokenId is known get the latest block;
  // PrizeClaimed event lookup will be started from this block
  useEffect(() => {
    const getLatestBlock = async () => {
      const provider = PROJECTS[appLootboxContract].provider
      if (provider) {
        const lastBlock = await provider.getBlockNumber()
        setStartBlock(lastBlock)
      } else {
        // TODO: come up with solution if the povider is null
      }
    }

    if (tokenId) {
      getLatestBlock()
    } else {
      setStartBlock(undefined)
    }
  }, [tokenId, appLootboxContract])

  // getMissedEvents is called every 5 sec to get PrizeClaimedEvent for currently connected user,
  // and known tokenId (received after burnForRandomPrize fn call)
  const getMissedEvents = useCallback(async () => {
    console.log('getMissedEvents', startBlock)
    if (startBlock && tokenId) {
      try {
        const logs = await PROJECTS[appLootboxContract].queryPrizeClaimedEvent(
          LootboxEvents.prizeClaimed,
          startBlock,
          startBlock + 1024,
          tokenId
        )
        return logs
      } catch (e) {
        // TODO: what to show to the user
        console.error('Error getting missed events', e)
      }
    }
  }, [appLootboxContract, startBlock, tokenId])

  // Once a user clamied a gem, tokenId is known, at this moment
  // starting polling PrizeClaimed event
  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined = undefined
    if (tokenId) {
      intervalId = setInterval(() => {
        getMissedEvents().then((events) => {
          if (events) {
            console.log('missed events', events)
            if (events.length && isEventLog(events[0])) {
              setPendingEvent(events[0])
            }
          }
        })
      }, 5000)
    }
    return () => clearInterval(intervalId)
  }, [tokenId, getMissedEvents])

  useEffect(() => {
    if (!tokenId) {
      setStartBlock(undefined)
      setPendingEvent(null)
    }
  }, [tokenId])

  return {
    startWaitingForPrizeClaimedEvent, // will be called once tokenId is known, it will spinned up: getting Latest block, and start polling for PrizeClaimedEvent once startBlock is known
    stopWaitingForPrizeClaimedEvent, // this will reset the above
    pendingEvent, // this is PrizeClaimed event from logs
  }
}
