import { FC } from 'react'
import { Box, BoxSmall } from 'assets/images'
import { JackpotMini } from 'components/JackpotMini'
import { LootboxWidget } from 'components/LootboxWidget'
import { useWalletContext } from 'context/Wallet'
import { Polygon, PROJECTS } from 'utils/config/projects'
import { LootPrizes } from 'components/LootPrizes'

const SoldOut: FC<{ message: string }> = ({ message }) => {
  return (
    <p className="uppercase py-[18px] px-[17px] text-[16px] text-pampas border-[1px] border-[1px] border-white/20 text-center bg-black w-fit -translate-y-44 self-center">
      {message}
    </p>
  )
}

export const Lootbox = () => {
  const { appLootboxContract } = useWalletContext()

  return (
    <>
      <div className="hidden flex-grow lootbox-md:flex flex-col items-center relative">
        <div className="w-4/5 -translate-y-32 aspect-[15/9] relative z-0">
          <img
            src={Box}
            alt="lootbox"
            loading="lazy"
            className="absolute top-0 left-0 w-full h-full z-[-1]"
          />
          <div className="absolute w-full inset-0 bg-radial-gradient left-1/2 -translate-x-1/2" />
        </div>
        {PROJECTS[appLootboxContract].isSoldOut ? (
          <SoldOut message={PROJECTS[appLootboxContract].soldOutMessage} />
        ) : (
          <div className="-translate-y-48 w-[429px]">
            <LootboxWidget />
          </div>
        )}
        <div className="absolute top-0 right-0 w-[211px] z-0">
          <JackpotMini />
        </div>
        {PROJECTS[appLootboxContract].lootboxContractAddress ===
        Polygon.lootboxContractAddress ? (
          <div className="absolute top-0 left-0 w-[211px] z-0">
            <LootPrizes />
          </div>
        ) : null}
      </div>
      <div className="lootbox-md:hidden flex-grow flex flex-col justify-between">
        <JackpotMini />
        <div className="relative">
          <img src={BoxSmall} className="aspect-square" alt="lootbox" />
          <div className="absolute w-full inset-0 bg-radial-gradient-top left-1/2 -translate-x-1/2" />
        </div>
        {PROJECTS[appLootboxContract].isSoldOut ? (
          <SoldOut message={PROJECTS[appLootboxContract].soldOutMessage} />
        ) : (
          <LootboxWidget />
        )}
      </div>
    </>
  )
}
