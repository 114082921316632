import { SVGProps } from 'react'

export const HistoryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#ECE8E1" d="m1.333 10 2.5 2.5 2.5-2.5" />
      <path
        stroke="#ECE8E1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.667}
        d="m1.333 10 2.5 2.5 2.5-2.5"
      />
      <path
        stroke="#ECE8E1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.667}
        d="M11.333 17.5a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5 7.5 7.5 0 0 0-7.5 7.5v2.5"
      />
      <path
        stroke="#ECE8E1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.667}
        d="M11.333 6.667V10h3.333"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h20v20H.5z" />
      </clipPath>
    </defs>
  </svg>
)
