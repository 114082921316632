import { useState, useRef, useEffect, ComponentType } from "react";

export type WithHideOnClickOutside = {
  isOpen: boolean;
  onToggleOpen: () => void;
};

export function withHideOnClickOutside<
  T extends WithHideOnClickOutside = WithHideOnClickOutside
>(WrappedComponent: ComponentType<T>) {
  const Component = (props: Omit<T, keyof WithHideOnClickOutside>) => {
    const [isOpen, setOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const handleToggleOpen = () => {
      setOpen((prevState) => !prevState);
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        {...(props as T)}
        isOpen={isOpen}
        onToggleOpen={handleToggleOpen}
        ref={ref}
      />
    );
  };

  return Component;
}
