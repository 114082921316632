import { EmptySelectIcon, SelectIcon } from 'assets/icons'
import { FC } from 'react'

type FilterSectionProps = {
  title: string
  options: string[]
  optionNames: string[]
  selected: string
  onSelect: (option: string) => void
}

export const FiltersSection: FC<FilterSectionProps> = ({
  title,
  options,
  optionNames,
  selected,
  onSelect,
}) => {
  return (
    <div className="mb-5 uppercase text-pampas">
      <p className="my-5 text-[24px]">{title}</p>
      <div className="flex flex-col">
        {options.map((o, idx) => (
          <div className="flex items-center text-[16px] my-1.5" key={o}>
            <button type="button" onClick={() => onSelect(o)}>
              {o === selected ? <SelectIcon /> : <EmptySelectIcon />}
            </button>
            <span className="ml-3">{optionNames[idx]}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
