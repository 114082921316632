import { Token } from 'assets/images'
import { ToastType, useToastContext } from 'context/Toast'
import { formatEther, formatUnits } from 'ethers'
import { FC, useEffect, useMemo, useState } from 'react'
import { isProviderRpcError } from 'types/errors'
import { Erc20Info, getErc20Metadata } from 'utils/getMetadata'
import { ImportToMetamaskBtn } from './ImportToMetamaskBtn'
import { Lootcard, LootcardProps } from './Lootcard'

type TokenPrizeProps = Pick<
  LootcardProps,
  'mainBtnLabel' | 'mainBtnComponent' | 'mainBtnType'
> & {
  contractAddress: string
  amount: number
  chainId: number
  onClick: () => void
}

export const TokenPrize: FC<TokenPrizeProps> = ({
  contractAddress,
  amount,
  chainId,
  onClick,
  mainBtnLabel,
  mainBtnComponent,
  mainBtnType,
}) => {
  const [tokenInfo, setTokenInfo] = useState<Erc20Info | undefined>(undefined)
  const { addToast } = useToastContext()

  useEffect(() => {
    const meta = getErc20Metadata(contractAddress, chainId)
    setTokenInfo(meta)
  }, [contractAddress, chainId])

  const cardTitle = useMemo(() => {
    return tokenInfo
      ? formatUnits(BigInt(amount), tokenInfo.decimals)
      : formatEther(BigInt(amount))
  }, [tokenInfo, amount])

  const addTokenToMetamask = async () => {
    if (!window.ethereum) {
      addToast(
        ToastType.failure,
        'To continue using the app you should have the Metamask browser extension installed'
      )
    } else {
      if (tokenInfo) {
        try {
          await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: contractAddress, // The address of the token.
                symbol: tokenInfo.sym, // A ticker symbol or shorthand, up to 5 characters.
                decimals: tokenInfo.decimals, // The number of decimals in the token.
                image: tokenInfo.icon,
              },
            },
          })
        } catch (e) {
          console.error('Error importing erc20', e)
          if (isProviderRpcError(e) && e.code === 4001) {
            // ignore user rejected
          } else {
            addToast(ToastType.failure, 'Unable to add token to your wallet')
          }
        }
      }
    }
  }

  if (mainBtnComponent) {
    return (
      <Lootcard
        mainBtnType={mainBtnType}
        imgSrc={tokenInfo?.imgSrc || Token}
        cardTitle={`${cardTitle} ${tokenInfo?.sym || ''}`}
        mainBtnComponent={mainBtnComponent}
        onMainBtnClick={onClick}
        renderImportBtn={() =>
          tokenInfo ? (
            <ImportToMetamaskBtn
              addTokenToMetamask={addTokenToMetamask}
              chainId={chainId}
            />
          ) : null
        }
      />
    )
  } else {
    return (
      <Lootcard
        mainBtnType={mainBtnType}
        imgSrc={tokenInfo?.imgSrc || Token}
        cardTitle={`${cardTitle} ${tokenInfo?.sym || ''}`}
        mainBtnLabel={mainBtnLabel || 'Proceed'}
        onMainBtnClick={onClick}
        renderImportBtn={() =>
          tokenInfo ? (
            <ImportToMetamaskBtn
              addTokenToMetamask={addTokenToMetamask}
              chainId={chainId}
            />
          ) : null
        }
      />
    )
  }
}
