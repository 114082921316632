export const getRarityName = (rarity: number | undefined) => {
  switch (rarity) {
    case 0: {
      return 'Legendary'
    }
    case 1: {
      return 'Rare'
    }
    case 2: {
      return 'Magic'
    }
    default: {
      return 'Golden ticket'
    }
  }
}
