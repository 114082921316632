import { createContext } from 'react'
import { createCustomContext } from 'utils/createCustomContext'

import { PrizesFlowContextScheme } from './types'

export const PrizesFlowContext = createContext<
  PrizesFlowContextScheme | undefined
>(undefined)

PrizesFlowContext.displayName = 'PrizesFlowContext'

export const usePrizesFlowContext = createCustomContext(PrizesFlowContext)
