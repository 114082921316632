import { SVGProps } from 'react'

export const ReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g
      stroke="#ECE8E1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M4.5 10.685v3.375H1.125" />
      <path d="M4.288 14.063A7.315 7.315 0 0 1 12.375 2.25" />
      <path d="M4.284 14.06a7.29 7.29 0 1 1 2.466 1.69" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
