import { FC, Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ShareIcon } from 'assets/icons'
import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'
import { Btn } from 'components/Btn'
import { LootboxRoutes } from 'types/routes'

type ProjectProps = {
  projectName: string
  projectDescription: string
  onBtnClick: () => void
  btnLabel: string
  bgImg: string
  progress: string
}

const Project: FC<ProjectProps> = ({
  projectName,
  projectDescription,
  onBtnClick,
  btnLabel,
  bgImg,
  progress,
}) => {
  return (
    <div className="flex flex-col-reverse md:flex-row bg-codGrey relative">
      <div className="flex flex-1 flex-col p-6 md:p-20 pr-1/2 z-20">
        <p className="uppercase text-[14px] text-pacificBlue">{progress}</p>
        <div className="h-8" />
        <p className="uppercase text-[48px] text-pampas leading-none">
          {projectName}
        </p>
        <div className="h-8" />
        <p className="font-mont text-[16px] text-white/70 ">
          {projectDescription}
        </p>
        <div className="h-12" />
        <div className="w-fit">
          <Btn
            type="primary"
            onPress={onBtnClick}
            labelComponent={() => (
              <div className="flex items-center">
                <p className="text-[16px] text-pampas uppercase mr-3 translate-x-1.5">
                  {btnLabel}
                </p>
                <ShareIcon />
              </div>
            )}
          />
        </div>
      </div>
      {/* Picture */}
      <div
        className={`flex flex-1 ${bgImg} bg-contain bg-right bg-no-repeat relative aspect-square md:aspect-auto`}
      >
        <div className="hidden md:block absolute left-0 h-full w-14 bg-linear-gradient-right" />
        <div className="absolute inset-0 bg-radial-gradient-copy" />
      </div>
    </div>
  )
}

export const Projects = () => {
  const { switchNetwork, appLootboxContract } = useWalletContext()
  const navigate = useNavigate()

  const otherProjects = useMemo(() => {
    return Object.entries(PROJECTS)
      .filter(([key, _]) => key !== appLootboxContract)
      .map(([_, value]) => value)
  }, [appLootboxContract])

  const selectProject = (chainId: string) => {
    switchNetwork(chainId)
    navigate(`/${LootboxRoutes.box}`)
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col">
        <Project
          projectName={PROJECTS[appLootboxContract].projectName}
          projectDescription={PROJECTS[appLootboxContract].projectDescription}
          onBtnClick={() => navigate(`/${LootboxRoutes.box}`)}
          btnLabel="go to project"
          bgImg={PROJECTS[appLootboxContract].imgSquare}
          progress={
            PROJECTS[appLootboxContract].isUpcoming
              ? 'in progress'
              : 'completed'
          }
        />

        {otherProjects.map((oP) => (
          <Fragment key={oP.lootboxContractAddress}>
            <div className="h-16" />
            <Project
              projectName={oP.projectName}
              projectDescription={oP.projectDescription}
              onBtnClick={() => selectProject(oP.lootboxContractAddress)}
              btnLabel="select this project"
              bgImg={oP.imgSquare}
              progress={
                PROJECTS[oP.lootboxContractAddress].isUpcoming
                  ? 'in progress'
                  : 'completed'
              }
            />
          </Fragment>
        ))}
        <div className="h-16" />
      </div>
    </div>
  )
}
