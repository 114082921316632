import { SVGProps } from 'react'

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#ECE8E1"
      d="M17 6h-3a1 1 0 0 0-1 1v3h4a.35.35 0 0 1 .34.46l-.74 2.2a.5.5 0 0 1-.47.34H13v7.5a.5.5 0 0 1-.5.5H10a.5.5 0 0 1-.5-.5V13H8a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 8 10h1.5V7a4 4 0 0 1 4-4H17a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Z"
    />
  </svg>
)
