import { FC, useCallback, useMemo } from 'react'

import { HistoryItem } from 'context/Data'
import { PROJECTS } from 'utils/config/projects'
import { useWalletContext } from 'context/Wallet'
import { TimeAgo } from './TimeAgo'
import { getNftMetadata, getErc20Metadata } from 'utils/getMetadata'
import { Token } from 'assets/images'
import { formatUnits } from 'ethers'
import { trimAddress } from 'utils/trimAddress'
import { StorageKeys } from 'types/storage'

export const HistoryElement: FC<HistoryItem & { isReduced?: boolean }> = ({
  createdAt,
  rewardType,
  reward,
  type,
  isReduced = false,
}) => {
  const { appLootboxContract, userAddress } = useWalletContext()

  const tokenInfo = useMemo(() => {
    const copy =
      type === 'CLAIM'
        ? reward.vaultNetwork === Number(PROJECTS[appLootboxContract].chainId)
          ? 'is in your wallet'
          : 'has been added to your Vault'
        : 'has been withdrawn from your Vault'
    if (rewardType === 'NFT') {
      const meta = getNftMetadata(
        reward.contractAddress,
        reward.tokenId,
        reward.vaultNetwork
      )
      return {
        imgSrc: meta.imgSrc,
        message: `${
          meta.name ||
          ('Nft ' + isReduced
            ? trimAddress(reward.contractAddress)
            : reward.contractAddress)
        } ${copy}`,
      }
    } else {
      const meta = getErc20Metadata(reward.contractAddress, reward.vaultNetwork)
      return {
        imgSrc: meta.imgSrc || Token,
        message: `${
          meta?.decimals
            ? formatUnits('' + reward.amount, meta.decimals) + ' ' + meta?.sym
            : ''
        } ${copy}`,
      }
    }
  }, [
    appLootboxContract,
    reward.contractAddress,
    reward.tokenId,
    reward.vaultNetwork,
    reward.amount,
    rewardType,
    type,
    isReduced,
  ])

  const storedTimestamp = useCallback(() => {
    const historyLastViewed = localStorage.getItem(
      StorageKeys.historyLastViewed
    )
    if (historyLastViewed) {
      const parsed = JSON.parse(historyLastViewed)
      return parsed.userAddress.toLowerCase() === userAddress.toLowerCase()
        ? Number(parsed.timestamp)
        : 0
    } else {
      return 0
    }
  }, [userAddress])

  return (
    <div
      className={`w-full flex px-[18px] py-3.5 border-[0.5px] items-center ${
        Date.parse(createdAt) > storedTimestamp()
          ? 'border-dodgerBlue'
          : 'border-pampas/20'
      }`}
    >
      <div className="w-[72px] h-[72px]">
        <img
          src={tokenInfo.imgSrc}
          alt="loot card"
          className="w-full object-cover aspect-square"
        />
      </div>
      <div className="ml-3 flex-1">
        <p className="font-mont text-[14px] text-pampas">{tokenInfo.message}</p>
        <TimeAgo createdAt={createdAt} />
      </div>
      {Date.parse(createdAt) > storedTimestamp() ? (
        <p className="uppercase text-dodgerBlue text-[12px] border border-dodgerBlue px-1 h-min self-end">
          new
        </p>
      ) : null}
    </div>
  )
}
