import { GoldenTicket, Rarity0, Rarity1, Rarity2 } from 'assets/images'

export const getGemImg = (rarity: number) => {
  switch (rarity) {
    case 0: {
      return Rarity0
    }
    case 1: {
      return Rarity1
    }
    case 2: {
      return Rarity2
    }
    default: {
      return GoldenTicket
    }
  }
}
