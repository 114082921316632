import { FC, useEffect, useState } from 'react'

import { Lootcard, LootcardProps } from './Lootcard'
import { Nft } from 'assets/images'
import { ToastType, useToastContext } from 'context/Toast'
import { isProviderRpcError } from 'types/errors'
import { ImportToMetamaskBtn } from './ImportToMetamaskBtn'
import { getNftMetadata, NftInfo } from 'utils/getMetadata'

type NftPrizeProps = Pick<
  LootcardProps,
  'mainBtnLabel' | 'mainBtnComponent' | 'mainBtnType'
> & {
  contractAddress: string
  tokenId: number
  chainId: number
  onClick: () => void
}

export const NftPrize: FC<NftPrizeProps> = ({
  contractAddress,
  tokenId,
  chainId,
  onClick,
  mainBtnLabel,
  mainBtnComponent,
  mainBtnType,
}) => {
  const [tokenInfo, setTokenInfo] = useState<NftInfo | undefined>(undefined)
  const { addToast } = useToastContext()

  useEffect(() => {
    setTokenInfo(getNftMetadata(contractAddress, tokenId, chainId))
  }, [chainId, tokenId, contractAddress])

  const addNftToMetamask = async () => {
    if (!window.ethereum) {
      addToast(
        ToastType.failure,
        'To continue using the app you should have the Metamask browser extension installed'
      )
    } else {
      if (tokenInfo) {
        try {
          await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: tokenInfo.type,
              options: {
                address: contractAddress,
                tokenId: tokenId.toString(),
              },
            },
          })
        } catch (e) {
          console.error('Error importing nft', e)
          if (isProviderRpcError(e) && e.code === 4001) {
            // ignore user rejected
          } else {
            addToast(ToastType.failure, 'Unable to add nft to your wallet')
          }
        }
      }
    }
  }

  if (mainBtnComponent) {
    return (
      <Lootcard
        mainBtnType={mainBtnType}
        imgSrc={tokenInfo?.imgSrc || Nft}
        cardTitle={tokenInfo?.name || tokenId.toString()}
        mainBtnComponent={mainBtnComponent}
        onMainBtnClick={onClick}
        renderImportBtn={() =>
          tokenInfo ? (
            <ImportToMetamaskBtn
              addTokenToMetamask={addNftToMetamask}
              chainId={chainId}
            />
          ) : null
        }
      />
    )
  } else {
    return (
      <Lootcard
        mainBtnType={mainBtnType}
        imgSrc={tokenInfo?.imgSrc || Nft}
        cardTitle={tokenInfo?.name || tokenId.toString()}
        mainBtnLabel={mainBtnLabel || 'Proceed'}
        onMainBtnClick={onClick}
        renderImportBtn={() =>
          tokenInfo ? (
            <ImportToMetamaskBtn
              addTokenToMetamask={addNftToMetamask}
              chainId={chainId}
            />
          ) : null
        }
      />
    )
  }
}
