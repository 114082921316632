import { AgoraTextIcon } from 'assets/icons'
import { useWalletContext } from 'context/Wallet'
import { PROJECTS } from 'utils/config/projects'

import { ConnectWalletWidget } from './ConnectWalletWidget'
import { HistoryFloating } from './HistoryFloating'
import { Navigation } from './Navigation'

export const Header = () => {
  const { appLootboxContract } = useWalletContext()

  return (
    <div
      className={`flex justify-end navigation-md:justify-center items-center z-10 my-5`}
    >
      <div className="absolute left-5 navigation-md:left-[72px] z-10">
        <AgoraTextIcon />
      </div>
      <Navigation />
      <div className="hidden navigation-md:flex absolute right-5 md:right-[72px] z-10">
        <HistoryFloating />
        <div className="flex items-center translate-x-5">
          <div className="relative translate-x-1">
            <img
              src={PROJECTS[appLootboxContract].chainLogo}
              alt={PROJECTS[appLootboxContract].projectName}
              className="w-5 h-5"
            />
            {process.env.NODE_ENV === 'development' ? (
              <p className="absolute -bottom-2 -right-3 uppercase text-pampas text-[8px]">
                test
              </p>
            ) : null}
          </div>
          <ConnectWalletWidget />
        </div>
      </div>
    </div>
  )
}
