import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { SpinnerIcon } from 'assets/icons'
import { Confetti } from 'components/Confetti'
import { ChildrenProp } from 'types/common'
import { OverlayContext } from './context'
import { AnimationType } from './types'
import { useNetworkSpeedContext } from 'context/NetworkSpeed'
import { Cloudinary, CloudinaryVideo } from '@cloudinary/url-gen'
import { AdvancedVideo } from '@cloudinary/react'
import { SoundBtn } from 'components/SoundBtn'

type ProceedWithLoading = (
  fn: (...args: unknown[]) => Promise<void>,
  title: string,
  description: string,
  subdescription: string
) => Generator<Promise<void>>

export const OverlayProvider: FC<ChildrenProp> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('Loading')
  const [description, setDescription] = useState('')
  const [subdescription, setSubdescription] = useState('')
  const [isSoundOn, setIsSoundOn] = useState(false)

  const [isConfetti, setIsConfetti] = useState(false)
  const [animationSrc, setAnimationSrc] = useState<undefined | CloudinaryVideo>(
    undefined
  )

  const { networkSpeed } = useNetworkSpeedContext()

  const cld = useMemo(
    () => new Cloudinary({ cloud: { cloudName: 'dgjq39fgn' } }),
    []
  )

  const toggleSoundOn = useCallback(() => {
    setIsSoundOn((prevState) => !prevState)
  }, [])

  // a wrapper fn to be used to bring up and down loading overlay
  function* proceedWithLoading(
    fn: Parameters<ProceedWithLoading>[0],
    title: Parameters<ProceedWithLoading>[1],
    description: Parameters<ProceedWithLoading>[2],
    subdescription: Parameters<ProceedWithLoading>[3]
  ) {
    setTitle(title)
    setDescription(description)
    setSubdescription(subdescription)
    setLoading(true)

    // Await the function and pause the generator
    yield fn()

    // This line will be executed after resuming the generator
    setLoading(false)
  }

  const handleLoadingProcess = useCallback(
    async (
      fn: Parameters<ProceedWithLoading>[0],
      title: Parameters<ProceedWithLoading>[1],
      description: Parameters<ProceedWithLoading>[2],
      subdescription: Parameters<ProceedWithLoading>[3]
    ) => {
      try {
        const loadingProcess = proceedWithLoading(
          fn,
          title,
          description,
          subdescription
        )

        // Start the generator and await the yielded promise
        await loadingProcess.next().value

        loadingProcess.next()
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    []
  )

  const handleLoadingProcessWithPause = useCallback(
    async (
      fn: Parameters<ProceedWithLoading>[0],
      title: Parameters<ProceedWithLoading>[1],
      description: Parameters<ProceedWithLoading>[2],
      subdescription: Parameters<ProceedWithLoading>[3]
    ) => {
      try {
        const loadingProcess = proceedWithLoading(
          fn,
          title,
          description,
          subdescription
        )

        // Start the generator and await the yielded promise
        await loadingProcess.next().value
        //loadingProcessRef.current[USER_SESSION_ID] = loadingProcess
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    []
  )

  const finishLoading = useCallback(() => {
    setLoading(false)
  }, [])

  const showConfetti = useCallback(() => {
    setIsConfetti(true)
  }, [])

  const hideConfetti = () => {
    setIsConfetti(false)
  }

  useEffect(() => {
    if (animationSrc) {
      setTimeout(() => {
        setAnimationSrc(undefined)
        showConfetti()
      }, 6500)
    }
  }, [animationSrc, showConfetti])

  const startAnimation = useCallback(
    (type: AnimationType) => {
      if (networkSpeed === '4g') {
        setAnimationSrc(() => {
          switch (type) {
            case 'lootbox': {
              return cld.video('lootbox').quality('auto')
            }
            case 'rare': {
              return cld.video('rare').quality('auto')
            }
            case 'legendary': {
              return cld.video('legendary').quality('auto')
            }
            case 'magic': {
              return cld.video('magic').quality('auto')
            }
            case 'golden_ticket': {
              return cld.video('golden_ticket').quality('auto')
            }
          }
        })
      }
    },
    [networkSpeed, cld]
  )

  const context = useMemo(
    () => ({
      loading,
      handleLoadingProcess,
      handleLoadingProcessWithPause,
      finishLoading,
      hideConfetti,
      startAnimation,
      showConfetti,
      isSoundOn,
      toggleSoundOn,
    }),
    [
      loading,
      startAnimation,
      handleLoadingProcess,
      handleLoadingProcessWithPause,
      finishLoading,
      showConfetti,
      isSoundOn,
      toggleSoundOn,
    ]
  )

  return (
    <OverlayContext.Provider value={context}>
      {children}
      {loading ? (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black/80 z-50 overflow-x-hidden overflow-y-scroll">
          <div className="py-8 px-10 flex flex-col items-center justify-center h-full">
            <SpinnerIcon extendedClass="w-24 h-24" />
            <div className="h-6" />
            <p className="uppercase text-white text-[20px] leading-none">
              {title}
            </p>
            <div className="h-6" />
            <p className="text-white/80 text-[18px] font-mont max-w-[524px] text-center">
              {description}
            </p>
            <p className="text-white/80 text-[14px] font-mont max-w-[524px] text-center">
              {subdescription}
            </p>
          </div>
        </div>
      ) : null}
      {isConfetti ? <Confetti hideConfetti={hideConfetti} /> : null}
      {animationSrc ? (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black z-[60] overflow-x-hidden overflow-y-scroll">
          <div className="flex flex-col items-center justify-center w-full aspect-video h-full">
            <AdvancedVideo
              cldVid={animationSrc}
              autoPlay
              muted={!isSoundOn}
              preload="auto"
            />
            <div className="fixed left-5 bottom-5 z-[70]">
              <SoundBtn />
            </div>
          </div>
        </div>
      ) : null}
    </OverlayContext.Provider>
  )
}
