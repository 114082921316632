import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import Drawer from 'react-modern-drawer'
import { useLocation } from 'react-router-dom'
import { DrawerContext } from './context'
import { DrawerProps } from './types'

type DrawerProviderProps = {
  children: JSX.Element | JSX.Element[]
}

export const DrawerProvider: FC<DrawerProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<DrawerProps | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [drawerChild, setDrawerChild] = useState<ReactNode>(null)
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  const showDrawer = useCallback(
    (drawerChild: ReactNode, config: DrawerProps) => {
      setConfig(config)
      setDrawerChild(drawerChild)
    },
    []
  )

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 500)
  }, [config])

  const hideDrawer = useCallback(() => {
    setIsOpen(false)
    setTimeout(() => {
      setConfig(undefined)
    }, 500)
  }, [])

  const context = useMemo(
    () => ({
      showDrawer,
      hideDrawer,
    }),
    [showDrawer, hideDrawer]
  )

  return (
    <DrawerContext.Provider value={context}>
      {config ? (
        <Drawer
          open={isOpen}
          onClose={hideDrawer}
          {...config}
          lockBackgroundScroll
        >
          {drawerChild}
        </Drawer>
      ) : null}
      {children}
    </DrawerContext.Provider>
  )
}
