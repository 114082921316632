import { FC, ReactElement } from 'react'

import { Btn } from './Btn'
import { useWalletContext } from 'context/Wallet'

type ConnectWalletBtnProps = {
  altBtn: ReactElement
  disabled?: boolean
}

/**
 * A button that displays "Connect wallet" option
 * if a wallet (for a selected network) wasn't connected
 *
 * i.e. Exchange tokens panel will display "Connect wallet" button,
 * once the wallet was connected it will display "Swap" button
 */
export const ConnectWalletBtn: FC<ConnectWalletBtnProps> = ({
  altBtn,
  disabled = false,
}) => {
  const { userAddress, handleConnectWallet } = useWalletContext()

  if (!userAddress) {
    return (
      <Btn
        size="small"
        disabled={disabled}
        label="Connect wallet"
        type="tertiary"
        onPress={handleConnectWallet}
      />
    )
  }
  return altBtn
}
