export const abi: Record<string, any> = {
  '0xaDB5f6Ef85A88cDAbcb2DE3e795F97A0C7d4b000': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'specified',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'ArrayLengthOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'NftNotAccessible',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarirty',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeNotAvailable',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarirty',
          type: 'uint256',
        },
      ],
      name: 'NoErc20PrizeAvailable',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'NoNftPrizeAvailable',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NotEnoughErc20',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'chance',
          type: 'uint256',
        },
      ],
      name: 'RarityByProbabilityNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'actual',
          type: 'uint256',
        },
      ],
      name: 'TooSmallBalance',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'expected',
          type: 'uint16',
        },
      ],
      name: 'WrongTotalRaritiesProbability',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_NFT',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForErc20Prize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'reserved',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint64',
          name: 'maxSupply',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      name: 'prizes',
      outputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'flags',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'left',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'right',
          type: 'uint32',
        },
        {
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
        {
          internalType: 'uint96',
          name: 'reserved',
          type: 'uint96',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      name: 'rarities',
      outputs: [
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'uint32',
          name: 'lbCounter',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'head',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'tail',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'count',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'reserved1',
          type: 'uint16',
        },
        {
          internalType: 'uint32',
          name: 'reserved2',
          type: 'uint32',
        },
        {
          internalType: 'uint96',
          name: 'reserved3',
          type: 'uint96',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'address',
              name: 'collection',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint32',
              name: 'chainId',
              type: 'uint32',
            },
          ],
          internalType: 'struct IPrizeStorage.NftInfo[]',
          name: 'infos',
          type: 'tuple[]',
        },
      ],
      name: 'addNftPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'offset',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'limit',
          type: 'uint32',
        },
      ],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'erc20Token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'erc20Amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'erc20Total',
              type: 'uint256',
            },
            {
              internalType: 'uint32',
              name: 'erc20ChainId',
              type: 'uint32',
            },
            {
              internalType: 'uint256',
              name: 'total',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'offset',
              type: 'uint256',
            },
            {
              components: [
                {
                  internalType: 'address',
                  name: 'collection',
                  type: 'address',
                },
                {
                  internalType: 'uint256',
                  name: 'tokenId',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  internalType: 'uint32',
                  name: 'chainId',
                  type: 'uint32',
                },
              ],
              internalType: 'struct IPrizeStorage.NftInfo[]',
              name: 'nfts',
              type: 'tuple[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.PrizeInfo',
          name: 'result',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'maxSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'removeNftPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'setErc20Prize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831': [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'authorizer',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'nonce',
          type: 'bytes32',
        },
      ],
      name: 'AuthorizationCanceled',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'authorizer',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'nonce',
          type: 'bytes32',
        },
      ],
      name: 'AuthorizationUsed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: '_account',
          type: 'address',
        },
      ],
      name: 'Blacklisted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'newBlacklister',
          type: 'address',
        },
      ],
      name: 'BlacklisterChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'burner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'Burn',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'newMasterMinter',
          type: 'address',
        },
      ],
      name: 'MasterMinterChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'minter',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'Mint',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'minter',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'minterAllowedAmount',
          type: 'uint256',
        },
      ],
      name: 'MinterConfigured',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'oldMinter',
          type: 'address',
        },
      ],
      name: 'MinterRemoved',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    { anonymous: false, inputs: [], name: 'Pause', type: 'event' },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'newAddress',
          type: 'address',
        },
      ],
      name: 'PauserChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'newRescuer',
          type: 'address',
        },
      ],
      name: 'RescuerChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: '_account',
          type: 'address',
        },
      ],
      name: 'UnBlacklisted',
      type: 'event',
    },
    { anonymous: false, inputs: [], name: 'Unpause', type: 'event' },
    {
      inputs: [],
      name: 'CANCEL_AUTHORIZATION_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DOMAIN_SEPARATOR',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PERMIT_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'RECEIVE_WITH_AUTHORIZATION_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'TRANSFER_WITH_AUTHORIZATION_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'authorizer', type: 'address' },
        { internalType: 'bytes32', name: 'nonce', type: 'bytes32' },
      ],
      name: 'authorizationState',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_account', type: 'address' }],
      name: 'blacklist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'blacklister',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'authorizer', type: 'address' },
        { internalType: 'bytes32', name: 'nonce', type: 'bytes32' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'cancelAuthorization',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'minter', type: 'address' },
        {
          internalType: 'uint256',
          name: 'minterAllowedAmount',
          type: 'uint256',
        },
      ],
      name: 'configureMinter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'currency',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'decrement', type: 'uint256' },
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'increment', type: 'uint256' },
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'string', name: 'tokenName', type: 'string' },
        { internalType: 'string', name: 'tokenSymbol', type: 'string' },
        { internalType: 'string', name: 'tokenCurrency', type: 'string' },
        { internalType: 'uint8', name: 'tokenDecimals', type: 'uint8' },
        { internalType: 'address', name: 'newMasterMinter', type: 'address' },
        { internalType: 'address', name: 'newPauser', type: 'address' },
        { internalType: 'address', name: 'newBlacklister', type: 'address' },
        { internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'string', name: 'newName', type: 'string' }],
      name: 'initializeV2',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'lostAndFound', type: 'address' },
      ],
      name: 'initializeV2_1',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_account', type: 'address' }],
      name: 'isBlacklisted',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'isMinter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'masterMinter',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_to', type: 'address' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'minter', type: 'address' }],
      name: 'minterAllowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
      name: 'nonces',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pauser',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'permit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'validAfter', type: 'uint256' },
        { internalType: 'uint256', name: 'validBefore', type: 'uint256' },
        { internalType: 'bytes32', name: 'nonce', type: 'bytes32' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'receiveWithAuthorization',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'minter', type: 'address' }],
      name: 'removeMinter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract IERC20',
          name: 'tokenContract',
          type: 'address',
        },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'rescueERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'rescuer',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'validAfter', type: 'uint256' },
        { internalType: 'uint256', name: 'validBefore', type: 'uint256' },
        { internalType: 'bytes32', name: 'nonce', type: 'bytes32' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'transferWithAuthorization',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_account', type: 'address' }],
      name: 'unBlacklist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'unpause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_newBlacklister', type: 'address' },
      ],
      name: 'updateBlacklister',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_newMasterMinter', type: 'address' },
      ],
      name: 'updateMasterMinter',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_newPauser', type: 'address' },
      ],
      name: 'updatePauser',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'newRescuer', type: 'address' },
      ],
      name: 'updateRescuer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'version',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],
  '0xCbc18a85003633EBa44aECF54C3b3a999c51F58C': [
    {
      inputs: [{ internalType: 'uint8', name: 'version', type: 'uint8' }],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addWhitelist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'cap',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
        { internalType: 'uint256', name: 'cap_', type: 'uint256' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
        { internalType: 'uint256', name: 'cap_', type: 'uint256' },
      ],
      name: 'initializeSignature',
      outputs: [{ internalType: 'bytes', name: '', type: 'bytes' }],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeWhitelist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x7333D5017bA333D390065681996eF46b0927bdA4': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x329cF0853470294b64d9FAC27b55c2901666140F': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'reserved',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x0e603bCD1A60b816fBc84036c462704e87B3cfD6': [
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'burnFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x69fa527c22a881024d0b0D5eAaf9dCB66Ea954Ac': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x2d85D15ccA69ED1EfFAcCD76dB2409262Dfa7D80': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xD1988477a196BcaAce93861DD89F143923347929': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x7fdFa0b9152c0C0606b77F71fC7EFbDFE731C792': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'addPrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'reserved',
          type: 'uint32',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'burn',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getOwner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'string', name: 'name', type: 'string' },
        { internalType: 'string', name: 'symbol', type: 'string' },
        { internalType: 'uint8', name: 'decimals', type: 'uint8' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'bool', name: 'mintable', type: 'bool' },
        { internalType: 'address', name: 'owner', type: 'address' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'mint',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'mintable',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xb55876a7Bb3bC9BdFd03B282FfE9337de29e7175': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x592e83EB31091A816E46d3b04e9AaceEC8E3677c': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getLootBoxInfo',
      outputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x374CE0cC0c70954707C157C247B8245ea0F96a69': [
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'burnFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xE933b6612A585B76E723FF52BecA5622c38a8176': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'addPrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'reserved',
          type: 'uint32',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'removePrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc1055',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'FreeTicketUsed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x0907741D97989844B9b2C69ae8fE98B4f4Ab09A5': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xa69837bf9596dB254b91C80615dcf174083f91B1': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
      ],
      name: 'WrongVaultTransferred',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NATIVE_TOKEN_ADDRESS',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'addPrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'reserved',
          type: 'uint32',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'removePrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc1055',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'FreeTicketUsed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buyNative',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x6704d2dd5f3EF689C7DF719A2f16d36849B4CCdF': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    { inputs: [], name: 'EmptyRelease', type: 'error' },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getLootBoxInfo',
      outputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: 'info',
          type: 'tuple',
        },
      ],
      name: 'setLootBoxInfo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x587202899C17505c4b2C189d0F17Dd17a09d8AdB': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
      ],
      name: 'WrongVaultTransferred',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NATIVE_TOKEN_ADDRESS',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'addPrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'reserved',
          type: 'uint32',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'removePrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc1055',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'FreeTicketUsed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buyNative',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x3aDE358C18F23a7f9EF9d1bF2cf968b70dc0ABc7': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    { inputs: [], name: 'EmptyRelease', type: 'error' },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getLootBoxInfo',
      outputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: 'info',
          type: 'tuple',
        },
      ],
      name: 'setLootBoxInfo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0x65CA21a83aF05776D42F34d9d518e161E65dd293': [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'AccessDenied',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'pool',
          type: 'address',
        },
      ],
      name: 'NoFundsAvailable',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotImplemented',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address',
        },
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'min',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'max',
          type: 'uint256',
        },
      ],
      name: 'OutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'probability',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'chainId',
          type: 'uint32',
        },
      ],
      name: 'PrizeNotFound',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'probability',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxProbability',
          type: 'uint256',
        },
      ],
      name: 'ProbabilityTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'maxLevel',
          type: 'uint256',
        },
      ],
      name: 'RarityLevelTooBig',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'UnknownRequestId',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
      ],
      name: 'UnsupportedPrizeType',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'given',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
      ],
      name: 'WrongRaritiesLength',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'level',
          type: 'uint256',
        },
        {
          internalType: 'Probability',
          name: 'current',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'prev',
          type: 'uint16',
        },
      ],
      name: 'WrongRarityOrder',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'expected',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'got',
          type: 'uint256',
        },
      ],
      name: 'WrongVaultTransferred',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoGroups',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizes',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'groupId',
          type: 'uint256',
        },
      ],
      name: 'ErrorNoPrizesInGroup',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32',
        },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address',
        },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BUY_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CLAIM_REQUEST',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'LB_RARITIES',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_BUY_COUNT',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_NFT_BOOST',
      outputs: [
        {
          internalType: 'uint16',
          name: '',
          type: 'uint16',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NATIVE_TOKEN_ADDRESS',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'NFT_FLAG_LOCKED',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC1155',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC20',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_ERC721',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PRIZE_MANAGER_ROLE',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'addPrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo[]',
          name: 'prizes',
          type: 'tuple[]',
        },
      ],
      name: 'addPrizes',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'claimFunds',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'config',
      outputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'reserved',
          type: 'uint32',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'debugNft',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'left',
              type: 'uint64',
            },
            {
              internalType: 'StateType',
              name: 'state',
              type: 'uint8',
            },
            {
              internalType: 'uint8',
              name: 'flags',
              type: 'uint8',
            },
            {
              internalType: 'uint16',
              name: 'boost',
              type: 'uint16',
            },
            {
              internalType: 'address',
              name: 'approval',
              type: 'address',
            },
            {
              internalType: 'IdType',
              name: 'right',
              type: 'uint64',
            },
            {
              internalType: 'uint32',
              name: 'entropy',
              type: 'uint32',
            },
          ],
          internalType: 'struct INftStorage.NFTDef',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'getBalanceInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'firstEmptyTokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'mysteryCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'rarityCounters',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256[]',
              name: 'firstTokenIds',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.BalanceInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getPrice',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getPrizes',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'rarity',
              type: 'uint256',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'index',
                  type: 'uint256',
                },
                {
                  internalType: 'Probability',
                  name: 'probability',
                  type: 'uint16',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'prizeType',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bool',
                      name: 'randomlySelected',
                      type: 'bool',
                    },
                    {
                      internalType: 'Probability',
                      name: 'probability',
                      type: 'uint16',
                    },
                    {
                      internalType: 'uint256',
                      name: 'availablePrizes',
                      type: 'uint256',
                    },
                    {
                      internalType: 'address',
                      name: 'contractAddress',
                      type: 'address',
                    },
                    {
                      internalType: 'uint256',
                      name: 'tokenId',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'amount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'chainId',
                      type: 'uint256',
                    },
                  ],
                  internalType: 'struct Prize.PrizeInfo[]',
                  name: 'prizes',
                  type: 'tuple[]',
                },
              ],
              internalType: 'struct Prize.GroupInfo[]',
              name: 'groups',
              type: 'tuple[]',
            },
            {
              internalType: 'uint256',
              name: 'totalPrizes',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.RarityInfo[]',
          name: '',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
      ],
      name: 'getRoleAdmin',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getState',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'expectedIncome',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'startTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'endTimestamp',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalSupply',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'prizeCounts',
              type: 'uint256[]',
            },
            {
              internalType: 'uint256',
              name: 'emptyCounter',
              type: 'uint256',
            },
            {
              internalType: 'uint256[]',
              name: 'lbCounters',
              type: 'uint256[]',
            },
          ],
          internalType: 'struct LootBoxAdmin.State',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getTokenInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'address',
              name: 'token',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'price',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'jackpot',
              type: 'uint256',
            },
          ],
          internalType: 'struct LootBoxAdmin.TokenInfo[]',
          name: 'result',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'hasRole',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'name_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'symbol_',
          type: 'string',
        },
        {
          internalType: 'string',
          name: 'baseUri_',
          type: 'string',
        },
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'begin',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'end',
          type: 'uint32',
        },
        {
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'init',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'probabilities',
      outputs: [
        {
          internalType: 'uint16[]',
          name: '',
          type: 'uint16[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]',
        },
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
      ],
      name: 'removePrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          components: [
            {
              internalType: 'uint256',
              name: 'prizeType',
              type: 'uint256',
            },
            {
              internalType: 'bool',
              name: 'randomlySelected',
              type: 'bool',
            },
            {
              internalType: 'Probability',
              name: 'probability',
              type: 'uint16',
            },
            {
              internalType: 'uint256',
              name: 'availablePrizes',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'chainId',
              type: 'uint256',
            },
          ],
          internalType: 'struct Prize.PrizeInfo',
          name: 'prize',
          type: 'tuple',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]',
        },
      ],
      name: 'removePrizeSameNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256',
        },
      ],
      name: 'repeatRandomRequest',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: 'buy',
          type: 'bool',
        },
      ],
      name: 'requestRandomManually',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 'role',
          type: 'bytes32',
        },
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16[]',
          name: 'probabilities_',
          type: 'uint16[]',
        },
      ],
      name: 'setAllRarities',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'alwaysBurn',
          type: 'bool',
        },
      ],
      name: 'setAlwaysBurn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'baseUri',
          type: 'string',
        },
      ],
      name: 'setBaseUrl',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'Probability',
          name: 'jackpotShare',
          type: 'uint16',
        },
        {
          internalType: 'Probability',
          name: 'jackpotPriceShare',
          type: 'uint16',
        },
      ],
      name: 'setJackpotParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint16',
          name: 'maxBoost',
          type: 'uint16',
        },
      ],
      name: 'setMaxBoost',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'maxIncome',
          type: 'uint256',
        },
      ],
      name: 'setMaxIncome',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'price',
          type: 'uint256',
        },
      ],
      name: 'setPrice',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'signerAddress',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'begin',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'end',
          type: 'uint256',
        },
      ],
      name: 'setStartEnd',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'subscriptionId',
          type: 'uint64',
        },
        {
          internalType: 'uint32',
          name: 'callbackGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'uint16',
          name: 'requestConfirmations',
          type: 'uint16',
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32',
        },
      ],
      name: 'setVRFParams',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes4',
          name: 'interfaceId',
          type: 'bytes4',
        },
      ],
      name: 'supportsInterface',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc1055',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawErc20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'got',
          type: 'uint8',
        },
        {
          internalType: 'uint8',
          name: 'max',
          type: 'uint8',
        },
      ],
      name: 'DeltaIsOutOfRange',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'currentTimestamp',
          type: 'uint64',
        },
      ],
      name: 'ExpiredSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'ExternalIdAlreadyUsed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftIsLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'NftMustBeLocked',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'NotEnoughEmptyNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughMysteryNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: 'expected',
          type: 'uint32',
        },
        {
          internalType: 'uint32',
          name: 'butGot',
          type: 'uint32',
        },
      ],
      name: 'NotEnoughRareNfts',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'NotInitialized',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'tooEarly',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'tooLate',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'maxSupplyReached',
          type: 'bool',
        },
      ],
      name: 'OutOfScope',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokenNotSupported',
      type: 'error',
    },
    {
      inputs: [],
      name: 'UnconfirmedSignature',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'StateType',
          name: 'nftState',
          type: 'uint8',
        },
      ],
      name: 'UnsupportedState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'StateType',
          name: 'expected',
          type: 'uint8',
        },
        {
          internalType: 'StateType',
          name: 'got',
          type: 'uint8',
        },
      ],
      name: 'WrongNftState',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'got',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'WrongOwner',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'approved',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Erc20PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
      ],
      name: 'FreeTicketUsed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'winner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address[]',
          name: 'tokens',
          type: 'address[]',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]',
        },
      ],
      name: 'JackpotClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Locked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'emptyCount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'rarityCounts',
          type: 'uint256[]',
        },
      ],
      name: 'LootBoxRevealed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'MetadataUpdate',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collection',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'NftPrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'lootBoxId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'prizeType',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'PrizeClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'Unlocked',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'acquireFree',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'approve',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint32',
          name: 'count',
          type: 'uint32',
        },
      ],
      name: 'burnForAga',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'burnForJackpot',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rarity',
          type: 'uint256',
        },
      ],
      name: 'burnForRandomPrize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'boost',
          type: 'uint256',
        },
      ],
      name: 'buyFor',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'count',
          type: 'uint256',
        },
        {
          internalType: 'uint16',
          name: 'boost',
          type: 'uint16',
        },
      ],
      name: 'buyNative',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          internalType: 'uint64',
          name: 'externalId',
          type: 'uint64',
        },
        {
          internalType: 'uint64',
          name: 'expiredAt',
          type: 'uint64',
        },
        {
          components: [
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          internalType: 'struct Sign.Signature',
          name: 'signature',
          type: 'tuple',
        },
      ],
      name: 'checkSignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'getApproved',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
      ],
      name: 'isApprovedForAll',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC1155Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      name: 'onERC721Received',
      outputs: [
        {
          internalType: 'bytes4',
          name: '',
          type: 'bytes4',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'ownerOf',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'operator',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'approved',
          type: 'bool',
        },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'tokenURI',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      name: 'transferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
  '0xc0F4a4CC1Da6F3A1b64957069D3e65CC5Bad4C10': [
    {
      inputs: [{ internalType: 'uint64', name: 'lootBoxId', type: 'uint64' }],
      name: 'AlreadyUsed',
      type: 'error',
    },
    { inputs: [], name: 'EmptyRelease', type: 'error' },
    {
      inputs: [{ internalType: 'uint64', name: 'minAmount', type: 'uint64' }],
      name: 'NotEnoughSignatures',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint64', name: 'validAmount', type: 'uint64' },
        { internalType: 'uint64', name: 'minAmount', type: 'uint64' },
      ],
      name: 'NotEnoughValidSignatures',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'maxValue', type: 'uint32' }],
      name: 'TooBigThreshold',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'maxValidators', type: 'uint256' },
      ],
      name: 'TooManyValidators',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'minValue', type: 'uint32' }],
      name: 'TooSmallThreshold',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorAlreadyExists',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'uint16', name: 'id', type: 'uint16' }],
      name: 'ValidatorIdOccupied',
      type: 'error',
    },
    { inputs: [], name: 'ValidatorIdTooSmall', type: 'error' },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'ValidatorNotExists',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint8',
          name: 'version',
          type: 'uint8',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorAdd',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'ValidatorRemove',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'userAccount',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint64[]',
          name: 'lootBoxIds',
          type: 'uint64[]',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lootBox',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256',
        },
      ],
      name: 'Withdrawn',
      type: 'event',
    },
    {
      inputs: [],
      name: 'STORAGE_VERSION',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'THRESHOLD_DIVIDER',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint16', name: 'id', type: 'uint16' },
      ],
      name: 'addValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getLootBoxInfo',
      outputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getRequiredSignatures',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint32', name: 'threshold_', type: 'uint32' },
        { internalType: 'address', name: 'lootBox', type: 'address' },
        { internalType: 'uint32', name: 'chainId', type: 'uint32' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC721Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'userAccount', type: 'address' },
        { internalType: 'uint64[]', name: 'lootBoxIds', type: 'uint64[]' },
        {
          components: [
            { internalType: 'address', name: 'collection', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          ],
          internalType: 'struct Vault.NftPrize[]',
          name: 'nftPrizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'address', name: 'tokenAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          internalType: 'struct Vault.Erc20Prize[]',
          name: 'erc20Prizes',
          type: 'tuple[]',
        },
        {
          components: [
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          internalType: 'struct Vault.Signature[]',
          name: 'signatures',
          type: 'tuple[]',
        },
      ],
      name: 'release',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'removeValidator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'lootBox', type: 'address' },
            { internalType: 'uint32', name: 'chainId', type: 'uint32' },
            { internalType: 'uint64', name: 'reserved', type: 'uint64' },
          ],
          internalType: 'struct VaultStorage.LootBoxInfo',
          name: 'info',
          type: 'tuple',
        },
      ],
      name: 'setLootBoxInfo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint32', name: 'threshold', type: 'uint32' }],
      name: 'setThreshold',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'threshold',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalValidators',
      outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'withdrawERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'collection', type: 'address' },
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'withdrawNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
}
