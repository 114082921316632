import { CloseIcon } from 'assets/icons'
import { useDrawerContext } from 'context/Drawer'

export const WhatIsVault = () => {
  const { hideDrawer } = useDrawerContext()
  return (
    <div className="bg-pacificBlue h-full p-20 overflow-y-scroll">
      <button
        className="absolute top-5 right-5 -translate-y-1"
        type="button"
        onClick={hideDrawer}
      >
        <CloseIcon />
      </button>
      <p className="uppercase text-[32px] text-pampas leading-9">
        Consolidated Prize Withdrawals: Saving on Transaction Costs with Batch
        Withdrawals
      </p>
      <div className="h-6" />
      <p className="font-mont text-[16px] text-pampas">
        The Vault page is a unique feature designed for efficiency and
        cost-effectiveness, especially for users who win prizes located on
        blockchains with higher transaction fees, like Ethereum. Here, all your
        prizes are collected in one place. To save on transaction costs, we've
        implemented a batch withdrawal tool. This tool allows you to select
        multiple prizes at once and withdraw them in a single transaction. By
        doing this, you significantly reduce the transaction fees that would
        accrue if each prize were withdrawn individually. This is particularly
        beneficial on networks like Ethereum, where transaction costs can be
        high. It's a simple yet powerful way to manage your winnings and ensure
        more of your rewards go into your pocket, rather than being spent on
        fees
      </p>
    </div>
  )
}
