export function truncateNumber(n: string | number, decimals = 2) {
  let num
  if (typeof n === 'string') {
    num = parseFloat(n)
    if (isNaN(num)) {
      return 'Invalid number string'
    }
  } else {
    num = n
  }

  let multiplier = Math.pow(10, decimals)
  return Math.floor(num * multiplier) / multiplier
}
