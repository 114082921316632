import { SVGProps } from 'react'

export const SelectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={19} height={19} x={0.5} y={0.5} fill="#0092CD" rx={9.5} />
    <rect width={19} height={19} x={0.5} y={0.5} stroke="#0092CD" rx={9.5} />
    <path
      fill="#ECE8E1"
      stroke="#ECE8E1"
      strokeWidth={0.25}
      d="M14.74 6.36v.002L8.19 12.35 5.26 9.672a.474.474 0 0 0-.32-.122.474.474 0 0 0-.321.122l-.485.444a.396.396 0 0 0-.134.293c0 .11.048.216.134.293l3.738 3.418c.085.078.2.122.321.122.12 0 .236-.044.32-.122h.001l7.352-6.722A.396.396 0 0 0 16 7.105a.396.396 0 0 0-.134-.293l-.484-.45-.002-.001a.49.49 0 0 0-.64 0Z"
    />
    <path
      fill="#ECE8E1"
      stroke="#ECE8E1"
      strokeWidth={0.194}
      d="M14.302 6.346H14.3l-5.944 5.24L5.7 9.243a.44.44 0 0 0-.292-.107.44.44 0 0 0-.29.107l-.441.388a.342.342 0 0 0-.121.257.342.342 0 0 0 .121.257l3.392 2.99a.44.44 0 0 0 .291.107.44.44 0 0 0 .291-.107l6.672-5.881a.342.342 0 0 0 .122-.257.342.342 0 0 0-.121-.256l-.44-.394-.002-.001a.458.458 0 0 0-.58 0Z"
    />
  </svg>
)
