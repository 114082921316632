import { Erc20Reward } from 'types/prizes'

export function areErc20RewardsIdentical(
  arr1: Erc20Reward[],
  arr2: Erc20Reward[]
): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort arrays based on tokenAddress and contractAddress
  const sortedArr1 = arr1.sort((a, b) =>
    `${a.tokenAddress}-${a.contractAddress}`.localeCompare(
      `${b.tokenAddress}-${b.contractAddress}`
    )
  )
  const sortedArr2 = arr2.sort((a, b) =>
    `${a.tokenAddress}-${a.contractAddress}`.localeCompare(
      `${b.tokenAddress}-${b.contractAddress}`
    )
  )

  // Compare each object in the sorted arrays
  return sortedArr1.every((item, index) => {
    const otherItem = sortedArr2[index]
    return (
      item.value === otherItem.value &&
      item.tokenAddress === otherItem.tokenAddress &&
      item.contractAddress === otherItem.contractAddress &&
      item.amount === otherItem.amount &&
      item.vaultAddress === otherItem.vaultAddress &&
      item.vaultNetwork === otherItem.vaultNetwork &&
      item.withdrawalTx === otherItem.withdrawalTx
    )
  })
}
