import { FC } from 'react'

import { Btn, BtnProps } from 'components/Btn'
import { SpinnerIcon } from 'assets/icons'

type CommonProps = {
  imgSrc: string
  counter?: number
  cardTitle: string
  mainBtnType: BtnProps['type']
  secondaryBtns?: () => JSX.Element
  onMainBtnClick: () => void
  isMainBtnDisabled?: boolean
  loading?: boolean
  renderImportBtn?: () => JSX.Element | null
}

type WithLabel = {
  mainBtnLabel: string
  mainBtnComponent?: never
}

type WithComponent = {
  mainBtnLabel?: never
  mainBtnComponent: () => JSX.Element
}

export type LootcardProps = CommonProps & (WithLabel | WithComponent)

export const Lootcard: FC<LootcardProps> = ({
  imgSrc,
  counter = 0,
  cardTitle,
  secondaryBtns,
  mainBtnLabel,
  mainBtnComponent,
  onMainBtnClick,
  isMainBtnDisabled = false,
  mainBtnType,
  loading = false,
  renderImportBtn = () => null,
}) => {
  return (
    <div className="max-w-[306px] flex-1 relative bg-black">
      {counter ? (
        <div className="absolute top-[18px] right-[18px] flex items-end z-[100]">
          <p className="uppercase text-[14px] text-white mr-1">x</p>
          <p className="uppercase text-[14px] text-white">{counter}</p>
        </div>
      ) : null}
      <div className="border-[1px] border-pampas/20 shadow-custom relative max-h-[306px] flex">
        <img
          src={imgSrc}
          alt="loot card"
          className="w-full object-cover aspect-square"
        />
        {renderImportBtn()}
        {loading ? (
          <SpinnerIcon extendedClass="w-10 h-10 absolute inset-0 mx-auto my-auto" />
        ) : null}
        <p className="absolute bottom-4 left-1/2 -translate-x-1/2 uppercase text-pampas text-[18px] text-center">
          {loading ? '' : cardTitle}
        </p>
      </div>
      <>
        <div className="h-5" />
        {mainBtnLabel ? (
          <Btn
            type={mainBtnType}
            label={mainBtnLabel}
            onPress={onMainBtnClick}
            disabled={isMainBtnDisabled}
          />
        ) : null}
        {mainBtnComponent ? (
          <Btn
            type={mainBtnType}
            labelComponent={mainBtnComponent}
            onPress={onMainBtnClick}
            disabled={isMainBtnDisabled}
          />
        ) : null}
        <div className="h-2.5" />
        {secondaryBtns ? secondaryBtns() : null}
      </>
    </div>
  )
}
