import { useWalletContext } from 'context/Wallet'
import { FC } from 'react'

type ImportToMetamaskProps = {
  addTokenToMetamask: () => void
  chainId: number
}

export const ImportToMetamaskBtn: FC<ImportToMetamaskProps> = ({
  addTokenToMetamask,
  chainId,
}) => {
  const { activeNetworkId } = useWalletContext()
  if (chainId === Number(activeNetworkId)) {
    return (
      <button
        type="button"
        onClick={addTokenToMetamask}
        className="absolute top-2 right-3 flex items-center"
      >
        <p className="uppercase text-pampas text-[12px] underline underline-offset-4 mr-1">
          import
        </p>
        <img
          src="https://bscscan.com/images/svg/brands/metamask.svg"
          alt="metamask"
          className="w-5 h-5"
        />
      </button>
    )
  } else {
    return null
  }
}
