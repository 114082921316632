export abstract class WithdrawNetworkClass {
  constructor(public chainId: string, public withdrawContractAddress: string) {}
  abstract switchToNetwork(): Promise<void>
  abstract release(
    userAddress: string,
    lootBoxIds: number[],
    nftPrizes: Array<[collectionAddress: string, tokenId: number]>,
    erc20Prizes: Array<[tokenAddress: string, amount: bigint]>,
    signatures: Array<[v: string, r: string, s: string]>
  ): Promise<void>
}
