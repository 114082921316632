export function isBuyPeriod(scope: {
  maxSupply: number
  totalSupply: number
  startAt: string
  endAt: string
}) {
  const currentDate = new Date()
  const startDate = new Date(scope.startAt)
  const endDate = new Date(scope.endAt)

  const isTotalSupplyLessThanMaxSupply = scope.totalSupply < scope.maxSupply
  const isCurrentDateInRange =
    currentDate >= startDate && currentDate <= endDate

  return isTotalSupplyLessThanMaxSupply && isCurrentDateInRange
}
